import { useContext, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { getAuth, signInWithPopup } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { useAmplitude } from 'react-amplitude-hooks';

import {
  AuthDispatchContext,
  AuthStateContext,
} from '~/routes/App/context/auth_context';
import { getThemeData } from '~/utils/theme';
import { Body2, Caption } from '~/components/Typography';
import {
  FactorDispatchContext,
  FactorStateContext,
} from '~/routes/App/context/factor_context';
import { getFromLS } from '~/utils/localStorage';
import { getYYYYMMDDHHmm } from '~/utils/datetime';
import RequiredLoginPopupContent from './RequiredLoginPopupContent';
import { provider } from '~/utils/firebase';
import { AppStateContext } from '~/routes/App/context';

type Props = {
  onComplete?: () => void;
};
const ConditionSotrageSaveButton = ({ onComplete }: Props) => {
  const appState = useContext(AppStateContext);
  const authState = useContext(AuthStateContext);
  const authDispatch = useContext(AuthDispatchContext);
  const { logEvent } = useAmplitude();

  const factorState = useContext(FactorStateContext);
  const factorDispatch = useContext(FactorDispatchContext);
  if (!authState || !factorState) return <div />;
  const [t] = useTranslation();
  const themeData = getThemeData();
  const [alias, setAlias] = useState('');
  const [isDuplicated, setIsDuplicated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { user } = authState;
  const isMobile = useBreakpointValue<boolean>([true, false]);
  const checkDuplicatedAlias = (alias: string): Promise<boolean> => {
    return getDoc(
      doc(getFirestore(), `storage/${user?.uid}/conditions/${alias}`),
    ).then((v) => {
      if (v.data()) {
        return true;
      }
      return false;
    });
  };
  const addCondition = () => {
    factorDispatch?.({
      type: 'UPDATE_SELECTED_CONDITIONS',
      selectedConditions: alias,
    });
    setDoc(
      doc(
        getFirestore(),
        `storage/${user?.uid}/conditions/${alias.replaceAll(
          /(^\s*)|(\s*$)/g,
          '',
        )}`,
      ),
      {
        /// COMMON
        createDateTime: getYYYYMMDDHHmm({ date: new Date() }),
        selectedFactors: JSON.stringify(
          Array.from(factorState.selectedFactors),
        ),
        selectedNation: factorState.selectedNation,
        sortData: factorState.sortData,
        /// BACKTEST
        startDate: parseInt(
          getFromLS('BACKTEST_START_DATETIME', '1107244432000'),
          10,
        ),
        endDate: parseInt(
          getFromLS('BACKTEST_END_DATETIME', new Date().getTime().toString()),
          10,
        ),
        tradeCost: parseFloat(getFromLS('BACKTEST_TRADE_COST', '0.6')),
        top: parseInt(getFromLS('BACKTEST_TOP', '30'), 10),
        rebalancingPeriod: getFromLS('BACKTEST_REBALANCINGPERIOD', 'ANNUALLY'),
        unselectedGroups: getFromLS('UNSELECTED_GROUPS', '[]'),

        /// TOAST MESSAGE
      },
    );
    if (isMobile) {
      appState?.showToast('success', t('strategy.mobileSaveToast'), {
        position: 'bottom-center',
      });
    }
    logEvent('strategy save completed', {
      'strategy name': alias,
    });
    onComplete?.();
  };
  const onKeySubmit = () => {
    if (!user) return;

    /// SAVE
    checkDuplicatedAlias(alias).then((v) => {
      if (v) {
        setIsDuplicated(true);
      } else {
        addCondition();
        onClose();
      }
    });
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const disabled =
    factorState.selectedFactors.size === 0 ||
    !factorState.isEditCurrentConditions;
  return (
    <Box
      minWidth={['fit-content', 'auto']}
      w={['auto', '100%']}
      marginRight="12px"
    >
      <Button
        borderColor={
          disabled ? themeData.colors.gray[200] : themeData.colors.primary[400]
        }
        p={['4px 0px', '8px']}
        // @ts-ignore
        variant={isMobile ? null : 'outline'}
        borderWidth="2px"
        _hover={{
          background: disabled
            ? themeData.colors.gray[200]
            : themeData.colors.primary[200],
        }}
        colorScheme={disabled ? 'blackAlpha' : 'primary'}
        size="xl"
        color="primary"
        borderRadius={['base', 'full']}
        onClick={
          disabled
            ? undefined
            : () => {
                setAlias(factorState.selectedConditions);
                logEvent('strategy save clicked');
                onOpen();
              }
        }
      >
        <Center h="40px" flexDirection="row" display="flex" margin="0px 16px">
          <Body2
            whiteSpace="break-spaces"
            bold
            color={
              /* eslint-disable-next-line no-nested-ternary */
              disabled
                ? themeData.colors.gray[300]
                : isMobile
                ? 'white'
                : themeData.colors.primary[500]
            }
          >
            {t('strategy.saveButton')}
          </Body2>
        </Center>
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius="16px">
          {!user ? (
            <>
              <ModalBody p={6} align="center">
                <RequiredLoginPopupContent />
                <Button
                  marginTop="24px"
                  background={themeData.colors.primary[800]}
                  color="white"
                  onClick={() => {
                    if (!authDispatch) return;
                    signInWithPopup(getAuth(), provider).then((credential) => {
                      authDispatch({ type: 'LOGIN', user: credential.user });
                    });
                  }}
                >
                  {t('text.startWithGoogle')}
                </Button>
              </ModalBody>
            </>
          ) : (
            <>
              <ModalHeader fontWeight="bold">
                {t('strategy.saveModalTitle')}
              </ModalHeader>
              <ModalCloseButton color={themeData.colors.gray[300]} />
              <ModalBody pb={2}>
                {isDuplicated ? (
                  <>
                    <Body2>
                      {t('strategy.overwriteDescription', { value: alias })}
                    </Body2>
                    <ModalFooter justifyContent="center">
                      <Button
                        p="0px 24px"
                        colorScheme="gray"
                        mr={3}
                        onClick={() => {
                          setIsDuplicated(false);
                        }}
                      >
                        {t('text.cancel')}
                      </Button>
                      <Button
                        p="0px 24px"
                        colorScheme="primary"
                        onClick={() => {
                          addCondition();
                          setIsDuplicated(false);
                          onClose();
                        }}
                      >
                        {t('strategy.overwrite')}
                      </Button>
                    </ModalFooter>
                  </>
                ) : (
                  <>
                    <FormControl>
                      <Input
                        value={alias}
                        onChange={(event) => {
                          if (event.target.value.length > 15) {
                            setErrorMessage(t('strategy.error.tooLong'));
                          } else if (errorMessage) {
                            setErrorMessage(undefined);
                          }
                          setAlias(event.target.value);
                        }}
                        placeholder={t('strategy.alias')}
                      />
                      <Flex marginTop="8px">
                        {errorMessage ? (
                          <Box marginTop="8px">
                            <Caption color={themeData.colors.error}>
                              {errorMessage}
                            </Caption>
                          </Box>
                        ) : (
                          <div />
                        )}
                        <Spacer />
                        <Caption
                          color={themeData.colors.text3}
                        >{`${alias.length} / 15`}</Caption>
                      </Flex>
                    </FormControl>
                    <ModalFooter justifyContent="center">
                      <Button
                        p="0px 24px"
                        colorScheme="gray"
                        mr={3}
                        onClick={onClose}
                      >
                        {t('text.cancel')}
                      </Button>
                      <Button
                        p="0px 24px"
                        disabled={
                          alias.replaceAll(' ', '').length === 0 ||
                          errorMessage !== undefined
                        }
                        _disabled={{ backgroundColor: 'gray' }}
                        colorScheme="primary"
                        onClick={onKeySubmit}
                      >
                        {t('text.save')}
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </Box>
  );
};
ConditionSotrageSaveButton.defaultProps = {
  onComplete: undefined,
};
export default ConditionSotrageSaveButton;
