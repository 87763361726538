import { Flex, Spacer, Button, Center } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { H1, H5 } from '~/components/Typography';
import { getThemeData } from '~/utils/theme';
import SearchContainer from '~/container/SearchContainer';
import { Repository } from '~/repository';

const CompanyDetailHeader = ({ repository }: { repository: Repository }) => {
  const history = useHistory();
  const themeData = getThemeData();
  const [t] = useTranslation();
  return (
    <Flex
      style={{
        display: 'sticky',
        width: '100%',
        borderBottom: 'solid 1px #e5e5e5',
        backgroundColor: 'white',
        alignContent: 'center',
      }}
      p={['12px 12px', '24px 24px']}
    >
      <Flex flexDirection="column">
        <H1 bold>{t('companyDetail.stocks')}</H1>
      </Flex>
      <Flex marginLeft="24px" display={['none', 'inherit']} alignSelf="center">
        <SearchContainer repository={repository} />
      </Flex>
      <Spacer />
      <Button
        colorScheme="primary"
        borderRadius="100px"
        h="max-content"
        variant="outline"
        onClick={() => {
          if (
            history.action === 'POP' &&
            window.name.startsWith('company-detail')
          ) {
            window.close();
          } else if (history.action === 'POP') {
            history.replace('/screener');
          } else if (
            history.action === 'PUSH' ||
            history.action === 'REPLACE'
          ) {
            history.goBack();
          }
        }}
      >
        <Center flexDirection="row" display="flex" p="16px 16px">
          <H5 bold color={themeData.colors.primary[500]}>
            {t('text.backButtonTitle')}
          </H5>
        </Center>
      </Button>
    </Flex>
  );
};

export default CompanyDetailHeader;
