import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  VStack,
  Modal,
  ModalBody,
  FormLabel,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  FormControl,
  Input,
  Center,
} from '@chakra-ui/react';

import {
  getFirestore,
  onSnapshot,
  Unsubscribe,
  collection,
} from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Body2, Caption } from '~/components/Typography';
import { iconMore, iconRemove, iconEdit } from '~/constants/assets';
import { AuthStateContext } from '~/routes/App/context/auth_context';
import { FactorStateContext } from '~/routes/App/context/factor_context';
import { getThemeData } from '~/utils/theme';

type Props = {
  onSelectAlias: (alias: string) => void;
  onRemoveAlias: (alias: string) => void;
  onEditAlias: (prev: string, next: string) => void;
  checkDuplicatedAlias: (alias: string) => Promise<boolean>;
};

const ConditionsList = ({
  onSelectAlias,
  onRemoveAlias,
  onEditAlias,
  checkDuplicatedAlias,
}: Props) => {
  let unsub: Unsubscribe | null;
  const authState = useContext(AuthStateContext);
  const factorState = useContext(FactorStateContext);
  const [aliasList, setAliasList] = useState<Array<string>>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isRemoveOpen, setIsRemoveOpen] = useState<boolean>(false);
  const [t] = useTranslation();
  const [alias, setAlias] = useState<string>('');
  const [prevAlias, setPrevAlias] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>();
  if (!authState || !factorState) return <div />;
  const themeData = getThemeData();

  const { user } = authState;

  if (!user) return <div />;
  const ConditionItem = ({ alias }: { alias: string }) => {
    return (
      <Flex
        w="100%"
        _hover={{
          backgroundColor: themeData.colors.primary[100],
        }}
        onClick={() => {
          onSelectAlias(alias);
        }}
        align="center"
        p="8px 0px"
      >
        <Box zIndex={0}>
          <Body2 color="black">{alias}</Body2>
        </Box>
        <Spacer />

        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={IconButton}
                aria-label="Options"
                zIndex={0}
                icon={<Image src={iconMore} />}
                variant="unstyled"
                size="xs"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
              <MenuList>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();

                    setPrevAlias(alias);
                    setAlias(alias);
                    onOpen();
                  }} // show edit modal
                  icon={<Image src={iconEdit} />}
                >
                  <Body2>{t('strategy.edit')}</Body2>
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();

                    setAlias(alias);
                    setIsRemoveOpen(true);
                  }}
                  icon={<Image src={iconRemove} />}
                >
                  <Body2>{t('strategy.delete')}</Body2>
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </Flex>
    );
  };

  useEffect(() => {
    if (user && !unsub) {
      unsub = onSnapshot(
        collection(getFirestore(), `storage/${user.uid}/conditions`),
        (conditions) => {
          setAliasList(conditions.docs.map((e) => e.id));
        },
      );
    } else if (!user && unsub) {
      unsub();
    }
  }, [user]);

  return (
    <VStack w="100%" h="100%">
      {aliasList.length === 0 ? (
        <Center h="100%">
          <Body2
            whiteSpace="break-spaces"
            noOfLines={10}
            align="center"
            color={themeData.colors.text3}
          >
            {t('strategy.empty')}
          </Body2>
        </Center>
      ) : (
        aliasList.map((alias) => {
          return <ConditionItem key={alias} alias={alias} />;
        })
      )}
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius="16px">
          <ModalHeader fontWeight="bold">{t('strategy.edit')}</ModalHeader>
          <ModalCloseButton color={themeData.colors.gray[300]} />
          <ModalBody pb={2}>
            <FormControl>
              <Input
                value={alias}
                onChange={(event) => {
                  if (event.target.value.length > 15) {
                    setErrorMessage(t('strategy.error.tooLong'));
                  } else if (errorMessage) {
                    setErrorMessage(undefined);
                  }
                  setAlias(event.target.value);
                }}
                placeholder={t('strategy.alias')}
              />
              <Flex marginTop="8px">
                {errorMessage ? (
                  <Box marginTop="8px">
                    <Caption color={themeData.colors.error}>
                      {errorMessage}
                    </Caption>
                  </Box>
                ) : (
                  <div />
                )}
                <Spacer />
                <Caption
                  color={themeData.colors.text3}
                >{`${alias.length} / 15`}</Caption>
              </Flex>
            </FormControl>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button p="0px 24px" colorScheme="gray" mr={3} onClick={onClose}>
              {t('text.cancel')}
            </Button>
            <Button
              p="0px 24px"
              disabled={
                alias.replaceAll(' ', '').length === 0 ||
                errorMessage !== undefined
              }
              _disabled={{ backgroundColor: 'gray' }}
              colorScheme="primary"
              onClick={async () => {
                if (prevAlias === alias) {
                  onClose();
                  return;
                }

                if (await checkDuplicatedAlias(alias)) {
                  setErrorMessage(
                    t('strategy.error.duplicated', { value: alias }),
                  );
                  return;
                }
                onEditAlias(prevAlias, alias);

                onClose();
              }}
            >
              {t('text.save')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isCentered
        isOpen={isRemoveOpen}
        onClose={() => {
          setIsRemoveOpen(false);
        }}
      >
        <ModalOverlay />
        <ModalContent borderRadius="16px">
          <ModalHeader fontWeight="bold"> {t('strategy.delete')}</ModalHeader>
          <ModalCloseButton color={themeData.colors.gray[300]} />
          <ModalBody pb={2}>
            <FormLabel
              marginBottom="12px"
              whiteSpace="break-spaces"
              textAlign="center"
            >
              {t('strategy.deleteDescription', { value: alias })}
            </FormLabel>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button
              p="0px 24px"
              colorScheme="gray"
              mr={3}
              onClick={() => {
                setIsRemoveOpen(false);
              }}
            >
              {t('text.cancel')}
            </Button>
            <Button
              p="0px 24px"
              colorScheme="red"
              onClick={() => {
                onRemoveAlias(alias);
                setIsRemoveOpen(false);
              }}
            >
              {t('strategy.deleteButton')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};
export default ConditionsList;
