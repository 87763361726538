import CSS from 'csstype';
// import { ColorThemeInterface } from './colorThemes';

const getTypographyTheme = (): // colorTheme: ColorThemeInterface,
TypographyThemeInterface => {
  return {
    h1: { fontSize: '32px', fontFamily: 'Pretendard' },
    h2: { fontSize: '21px', fontFamily: 'Pretendard' },
    h3: { fontSize: '18px', fontFamily: 'Pretendard' },
    h4: { fontSize: '16px', fontFamily: 'Pretendard' },
    h5: { fontSize: '14px', fontFamily: 'Pretendard' },
    body1: { fontSize: '16px', fontFamily: 'Pretendard' },
    body2: { fontSize: '14px', fontFamily: 'Pretendard' },
    sub: { fontSize: '14px', fontFamily: 'Pretendard' },
    caption: {
      fontSize: '12px',
      fontFamily: 'Pretendard',
    },
  };
};
export interface TypographyThemeInterface {
  h1: CSS.Properties;
  h2: CSS.Properties;
  h3: CSS.Properties;
  h4: CSS.Properties;
  h5: CSS.Properties;
  body1: CSS.Properties;
  body2: CSS.Properties;
  sub: CSS.Properties;
  caption: CSS.Properties;
}
export { getTypographyTheme };
