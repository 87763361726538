import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { kr, en } from 'cosmos-i18n';

export const defaultLNG = 'kr';

declare module 'react-i18next' {
  interface Resources {
    kr: typeof kr;
    en: typeof en;
  }
}

const resources = {
  en: { translation: { ...en } },
  kr: { translation: { ...kr } },
};

export const initI18n = () => {
  if (i18next.isInitialized) {
    return;
  }
  i18next.use(initReactI18next).init({
    fallbackLng: ['kr', 'en'],
    resources,
    lng: defaultLNG,
    fallbackNS: defaultLNG,
  });
};
