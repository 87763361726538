import { ReactElement, useContext, useEffect, useReducer } from 'react';
import { Grid, GridItem, Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import 'react-toastify/dist/ReactToastify.css';

import { AppStateContext } from '../App/context';
import FactorListContainer from '~/container/shared/FactorList';
import {
  BackTestStateContext,
  BackTestDispatchContext,
  BackTestReducer,
} from './context';
import { Repository } from '~/repository';
import FactorHistogramContainer from '~/container/shared/FactorHistogramContainer';
import BackTestOptionContainer from '~/container/backtest/BacktestOptionContainer';
import IndexedTab from '~/components/IndexedTab';
import BacktestingExecButton from './BacktestingExecButton';
import BacktestingHeader from './BacktestingHeader';
import { getFromLS } from '~/utils/localStorage';
import ConditionsStorageContainer from '~/container/ConditionsStorageContainer';

type WithRepository = {
  repository: Repository;
};

type BackTestProviderProps = {
  children: ReactElement;
};
const BackTestProvider = ({ children }: BackTestProviderProps) => {
  const [backtestState, backtestDispatch] = useReducer(BackTestReducer, {
    startDate: new Date(
      parseInt(getFromLS('BACKTEST_START_DATETIME', '1107244432000'), 10),
    ),

    endDate: new Date(
      parseInt(
        getFromLS('BACKTEST_END_DATETIME', new Date().getTime().toString()),
        10,
      ),
    ),
    tradeCost: parseFloat(getFromLS('BACKTEST_TRADE_COST', '0.6')),
    top: parseInt(getFromLS('BACKTEST_TOP', '30'), 10),
    rebalancingPeriod: getFromLS('BACKTEST_REBALANCINGPERIOD', 'ANNUALLY'),
  });

  return (
    <BackTestStateContext.Provider value={backtestState}>
      <BackTestDispatchContext.Provider value={backtestDispatch}>
        {children}
      </BackTestDispatchContext.Provider>
    </BackTestStateContext.Provider>
  );
};

const BackTest = () => {
  const Monitoring = ({ repository }: WithRepository) => {
    const [t] = useTranslation();
    const backtestDispatch = useContext(BackTestDispatchContext);

    return (
      <>
        <Grid
          display={['none', 'grid']}
          width="100%"
          gap={4}
          templateColumns="repeat(8, 1fr)"
        >
          <GridItem colSpan={2} overflowY="scroll">
            <BackTestOptionContainer repository={repository} />
            <Box position="sticky" bottom="12px" w="100%">
              <ConditionsStorageContainer
                isFullScreen={false}
                onUpdate={() => {
                  if (!backtestDispatch) return;
                  backtestDispatch({
                    type: 'UPDATE_BACKTEST_VALUES',

                    startDate: new Date(
                      parseInt(
                        getFromLS('BACKTEST_START_DATETIME', '1107244432000'),
                        10,
                      ),
                    ),

                    endDate: new Date(
                      parseInt(
                        getFromLS(
                          'BACKTEST_END_DATETIME',
                          new Date().getTime().toString(),
                        ),
                        10,
                      ),
                    ),
                    tradeCost: parseFloat(
                      getFromLS('BACKTEST_TRADE_COST', '0.6'),
                    ),
                    top: parseInt(getFromLS('BACKTEST_TOP', '30'), 10),
                    rebalancingPeriod: getFromLS(
                      'BACKTEST_REBALANCINGPERIOD',
                      'ANNUALLY',
                    ),
                  });
                }}
              />
            </Box>
          </GridItem>
          <GridItem colSpan={6} overflow="hidden">
            <Grid
              width="100%"
              h="100%"
              overflow="hidden"
              templateColumns="repeat(6, 1fr)"
            >
              <GridItem colSpan={2} overflowY="scroll" bg="white">
                <FactorListContainer />
              </GridItem>
              <GridItem colSpan={4} overflowY="scroll" bg="white">
                <FactorHistogramContainer repository={repository} />
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
        <Box display={['inherit', 'none']} w="100%">
          <IndexedTab
            hasStorageButton
            lastActionButton={<BacktestingExecButton repository={repository} />}
            items={[
              {
                title: `1. ${t('text.defaultSettings')}`,
                child: <BackTestOptionContainer repository={repository} />,
              },
              {
                title: `2. ${t('text.factorSettings')}`,
                child: <FactorListContainer />,
              },
              {
                title: `3. ${t('text.factorDetailSettings')}`,
                child: <FactorHistogramContainer repository={repository} />,
              },
            ]}
          />
        </Box>
      </>
    );
  };

  const appState = useContext(AppStateContext);
  const location = useLocation<{ error?: string }>();
  const history = useHistory();
  useEffect(() => {
    if (location?.state?.error) {
      appState?.showToast('error', location.state.error, {
        position: 'bottom-left',
      });
      history.replace('backtest', { ...location.state, error: undefined });
    }
  }, [location?.state?.error]);
  return (
    <BackTestProvider>
      <Flex
        h={['-webkit-fill-available', '100%']}
        w="100%"
        flexDirection="column"
      >
        {appState != null ? (
          <BacktestingHeader repository={appState?.repository} />
        ) : (
          <div />
        )}

        <Flex h={['calc(100vh - 150px)', 'calc(100vh - 122px)']}>
          {appState != null ? (
            <Monitoring repository={appState?.repository} />
          ) : (
            <div />
          )}
        </Flex>
      </Flex>
    </BackTestProvider>
  );
};

export default BackTest;
