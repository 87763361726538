import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  VStack,
  useDisclosure,
  Divider,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Body1, H1 } from '~/components/Typography';
import { getThemeData } from '~/utils/theme';

export const getCsLinkButtons = () => {
  const [t] = useTranslation();

  return [
    <Button
      variant="link"
      p="4px"
      onClick={() => {
        window.open(
          'https://doomoolmori.notion.site/PLAN-0c8792de6b0c464c827cb1c1b13b4caa',
        );
      }}
    >
      <Body1 color="white">이용요금 안내</Body1>
    </Button>,
    <Button
      variant="link"
      p="4px"
      onClick={() => {
        window.open(
          'https://doomoolmori.notion.site/b8f0e6990c5e428d9233c097b9b5f192?v=dda6da7385554bf490762f975b816511',
        );
      }}
    >
      <Body1 color="white">공지사항</Body1>
    </Button>,
    <Button
      variant="link"
      p="4px"
      onClick={() => {
        window.open(
          'https://doomoolmori.notion.site/3796dc9e1cdc4f80a08d2d52b65c4779',
        );
      }}
    >
      <Body1 color="white">개인정보처리방침</Body1>
    </Button>,
    <Button
      variant="link"
      p="4px"
      onClick={() => {
        window.open(
          'https://doomoolmori.notion.site/d084b70930234f0d9943891d1f762013',
        );
      }}
    >
      <Body1 color="white">이용약관</Body1>
    </Button>,
    <Button
      variant="link"
      p="4px"
      onClick={() => {
        window.open('https://youtu.be/3LFCjIryap8');
      }}
    >
      <Body1 color="white">가이드 영상</Body1>
    </Button>,
    <Button
      variant="link"
      p="4px"
      onClick={() => {
        window.open('https://open.kakao.com/o/g0F0kcce');
      }}
    >
      <Body1 color="white">{t('text.community')}</Body1>
    </Button>,
    <Button
      variant="link"
      p="4px"
      onClick={() => {
        window.open('https://boolioadv.typeform.com/allatte');
      }}
    >
      <Body1 color="white">{t('text.feedback')}</Body1>
    </Button>,
  ];
};
export const CSContainer = () => {
  const themeData = getThemeData();
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <Popover isOpen={isOpen} placement="top">
      <PopoverTrigger>
        <Button
          w="48px"
          h="48px"
          onClick={isOpen ? onClose : onOpen}
          borderRadius="full"
          backgroundColor={themeData.colors.text1}
        >
          <H1 color="white">?</H1>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        marginRight="32px"
        style={{ animation: 'smoothAppear .5s', boxShadow: 'none' }}
        bg={themeData.colors.text1}
        w="fit-content"
      >
        <PopoverArrow bg={themeData.colors.navy} />
        <PopoverBody w="fit-content" display="flex">
          <VStack align="start">
            {getCsLinkButtons().map((button, index) => (
              <>
                {button}

                {index === getCsLinkButtons().length - 1 ? null : <Divider />}
              </>
            ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
