import { createContext, Dispatch } from 'react';

export type CompanyDetailState = {
  // ticker: string
};
export type CompanyDetailAction = {
  type: 'COMPANY_DETAIL_LOAD';
  // ticker: string;
}; // TODO REMOVE COUNT

function CompanyDetailReducer(
  state: CompanyDetailState,
  action: CompanyDetailAction,
): CompanyDetailState {
  //   return { ...state, ticker: action.ticker };

  switch (action.type) {
    case 'COMPANY_DETAIL_LOAD':
    default:
      return { ...state };
  }
}

type CompanyDetailDispatch = Dispatch<CompanyDetailAction>;
const CompanyDetailDispatchContext =
  createContext<CompanyDetailDispatch | null>(null);
const CompanyDetailStateContext = createContext<CompanyDetailState | null>(
  null,
);

export {
  CompanyDetailReducer,
  CompanyDetailStateContext,
  CompanyDetailDispatchContext,
};
