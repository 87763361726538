import { SearchIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';

import { useState } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import LoadingComponent from '~/components/LoadingComponent';
import { Body2, Caption, H4 } from '~/components/Typography';
import { iconArrowPageRight, iconSearch } from '~/constants/assets';
import { useDidUpdateEffect } from '~/hooks/useDidUpdateEffect';
import useFetch from '~/hooks/useFetch';
import { SearchCompanyResponse } from '~/models/companyDetail';
import { ResponseBody } from '~/models/responseBody';
import { Repository } from '~/repository';
import { getThemeData } from '~/utils/theme';
import { addCriticalActionsCountAmplitude } from '~/utils/logger';

type Props = {
  repository: Repository;
};

const SearchContainer = ({ repository }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const themeData = getThemeData();
  const [t] = useTranslation();
  const { logEvent } = useAmplitude();
  const [keyword, setKeyword] = useState<string>();
  const [loading, setIsLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const SearchButton = () => {
    return (
      <>
        <HStack
          display={['none', 'inherit']}
          border={`solid 1px ${themeData.colors.gray[300]}`}
          borderRadius="8px"
          p="8px 16px"
          w="300px"
          _hover={{
            cursor: 'pointer',
          }}
          onClick={() => {
            onOpen();
            logEvent('searchbox initialized');
          }}
        >
          <Body2 color={themeData.colors.gray[300]}>
            {t('text.searchPlaceholer')}
          </Body2>
          <Spacer />
          <Image src={iconSearch} color="gray" />
        </HStack>
        <Box display={['inherit', 'none']}>
          <HStack
            marginRight="8px"
            border={`solid 1px ${themeData.colors.gray[300]}`}
            borderRadius="8px"
            p="8px 16px"
            _hover={{
              cursor: 'pointer',
            }}
            onClick={() => {
              onOpen();
              logEvent('searchbox initialized');
            }}
          >
            <Body2 color={themeData.colors.gray[300]}>종목 입력</Body2>
            <Image src={iconSearch} color="gray" />
          </HStack>
        </Box>
      </>
    );
  };

  useDidUpdateEffect(() => {
    setIsLoading(true);
    const update = setTimeout(() => {
      logEvent('company searched', { 'searchbar input value': value });
      setKeyword(value.replaceAll(/(^\s*)|(\s*$)/g, '').replaceAll('/', '.'));
      setIsLoading(false);
    }, 300);
    return () => clearTimeout(update);
  }, [value]);

  return (
    <>
      <SearchButton />
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={undefined}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <InputGroup size="md" bg="white">
                <Input
                  border="solid 1px #e5e5e5"
                  borderRadius="8px"
                  value={value?.toString()}
                  textColor={themeData.colors.text2}
                  onChange={(evt) => {
                    // setValue(
                    //   evt.target.value.replaceAll(
                    //     /([^(0-9)|(a-z)|(A-Z)])*/g,
                    //     '',
                    //   ),
                    // );
                    setValue(evt.target.value);
                  }}
                  placeholder={t('text.searchPlaceholer')}
                />
                <InputRightElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputRightElement>
              </InputGroup>
            </AlertDialogHeader>

            <AlertDialogBody>
              {/* eslint-disable-next-line no-nested-ternary */}
              {loading ? (
                <LoadingComponent />
              ) : !keyword || keyword === '' ? (
                <div />
              ) : (
                <SearchResult
                  repository={repository}
                  keyword={keyword}
                  onClose={onClose}
                />
              )}
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const SearchResult = ({
  keyword,
  repository,
  onClose,
}: {
  keyword: string;
  repository: Repository;
  onClose: () => void;
}) => {
  const [getSearchCompaniesResult] = useFetch<
    ResponseBody<SearchCompanyResponse>
  >(() => repository.getSearchCompanies({ keyword }), [keyword]);

  if (getSearchCompaniesResult.loading) return <LoadingComponent />;
  if (!getSearchCompaniesResult?.data?.result) return <div />;

  return (
    <Box marginBottom="8px" w="100%">
      {getSearchCompaniesResult.data.result.map((v) => (
        <SearchResultItem
          companyName={
            v.companyLocalName && v.companyLocalName !== ''
              ? v.companyLocalName
              : v.companyName
          }
          ticker={v.ticker}
          cosmosCode={v.cosmosCode}
          onClose={onClose}
          highlighted={keyword}
        />
      ))}
    </Box>
  );
};

const SearchResultItem = ({
  companyName,
  ticker,
  cosmosCode,
  onClose,
  highlighted,
}: {
  companyName: string;
  ticker: string;
  cosmosCode: number;
  highlighted: string;
  onClose: () => void;
}) => {
  const themeData = getThemeData();

  const highlightedText = ({
    text,
    query,
  }: {
    text: string;
    query: string;
  }) => {
    const split = query.split(' ').map((e) => e.toLowerCase());
    const parts = (text ?? '').split(new RegExp(`(${split.join('|')})`, 'gi'));

    return (
      <>
        {parts.map((part) =>
          split.includes(part.toLowerCase()) ? (
            <mark
              style={{
                backgroundColor: themeData.colors.primary[300],
              }}
            >
              {part}
            </mark>
          ) : (
            part
          ),
        )}
      </>
    );
  };
  const history = useHistory();
  const { amplitudeInstance, logEvent } = useAmplitude();

  return (
    <Box
      p="12px"
      marginBottom="8px"
      borderRadius="12px"
      border={`solid 1px ${themeData.colors.gray[200]}`}
      backgroundColor={themeData.colors.gray[100]}
      _hover={{
        backgroundColor: themeData.colors.primary[500],
        cursor: 'pointer',
      }}
      onClick={() => {
        logEvent('searchbox results clicked', {
          'clicked company': ticker,
        });
        addCriticalActionsCountAmplitude({ amplitudeInstance });
        onClose();
        history.push(`/company-detail/${cosmosCode}`);
      }}
    >
      <HStack>
        <VStack align="start">
          <Caption color={themeData.colors.text2}>
            {highlightedText({ text: ticker, query: highlighted })}
          </Caption>
          <H4>{highlightedText({ text: companyName, query: highlighted })}</H4>
        </VStack>
        <Spacer />
        <Image src={iconArrowPageRight} />
      </HStack>
    </Box>
  );
};
export default SearchContainer;
