import axios from 'axios';

type HttpMethod = 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';

/* eslint no-restricted-syntax: [2, "FunctionExpression", "WithStatement"] */
axios.defaults.paramsSerializer = (paramObj) => {
  const params = new URLSearchParams();
  /* eslint guard-for-in: 0 */
  for (const key in paramObj) {
    if (paramObj[key] instanceof Array) {
      const obj = paramObj[key];
      for (const key2 in obj) {
        for (const key3 in obj[key2]) {
          params.append(`${key}[${key2}].${key3}`, obj[key2][key3]);
        }
      }
    } else {
      params.append(key, paramObj[key]);
    }
  }
  return params.toString();
};

export type FetchPropsType = {
  method: HttpMethod;
  uri: string;
  headers?: Record<string, any>;
  params?: Record<string, any>;
  body?: Record<string, any>;
  hasDmmHeader?: boolean;
};

export default ({
  method,
  uri,
  headers,
  params,
  body,
  hasDmmHeader = true,
}: FetchPropsType) => {
  const key = sessionStorage.getItem('Authorization');
  if (key === 'undefined') {
    sessionStorage.removeItem('Authorization');
  }

  const updatedHeaders: Record<string, any> = {
    ...headers,
  };
  if (key && hasDmmHeader) {
    updatedHeaders['x-dmm-api'] = key;
  }
  switch (method) {
    case 'GET':
      return axios.get(uri, { params, headers: updatedHeaders });
    case 'POST':
      return axios.post(uri, body, {
        params,
        headers: updatedHeaders,
      });
    case 'DELETE':
      return axios.delete(uri, { params, headers: updatedHeaders });
    case 'PUT':
      return axios.put(uri, { params, headers: updatedHeaders });
    case 'PATCH':
      return axios.patch(uri, { params, headers: updatedHeaders });
    default:
      throw Error('METHOD TYPE ERROR');
  }
};
