import { ReactElement, useContext } from 'react';
import {
  Box,
  IconButton,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useDisclosure,
  DrawerBody,
  Image,
  Spacer,
  VStack,
  Divider,
} from '@chakra-ui/react';
import ReactSelect, {
  components,
  ControlProps,
  OptionProps,
} from 'react-select';
import { Amplitude } from 'react-amplitude-hooks';

import { useTheme } from '~/hooks/useTheme';
import { ThemeContext } from '~/theme';
import {
  iconArrowDownWhite,
  iconGlobal,
  iconLogo,
  iconMenu,
  iconOvalEng,
  iconOvalKor,
} from '~/constants/assets';
import { AppDispatchContext, AppStateContext } from '~/routes/App/context';
import UserContainer from '~/container/shared/UserContainer';
import { getCsLinkButtons } from '~/container/CSContainer';

type DrawerMenuProps = {
  children: Array<ReactElement>;
};
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
const getIcon = (value: string) => {
  if (value === 'kr') {
    return iconOvalKor;
  }
  return iconOvalEng;
};
const getText = (value: string) => {
  if (value === 'kr') {
    return '한국어';
  }
  return 'ENG';
};

const LanguageSelector = () => {
  const themeData = useTheme().getThemeData(useContext(ThemeContext).theme);
  const appContext = useContext(AppStateContext);
  const appDispatch = useContext(AppDispatchContext);

  return (
    <Box m="0px 16px">
      <ReactSelect
        defaultValue={appContext?.language === 'kr' ? '한글' : 'ENG'}
        menuPlacement="top"
        value={{
          // @ts-ignore
          label: getText(appContext?.language),
          value: appContext?.language,
          icon: getIcon(appContext?.language ?? ''),
        }}
        // @ts-ignore
        onChange={({ value }) => {
          if (appDispatch && appContext && value !== appContext?.language) {
            appDispatch({ type: 'CHANGE_LANGUAGE', language: value });
          }
        }}
        styles={{
          indicatorsContainer: (base) => ({
            ...base,
            background: themeData.colors.primary[500],
          }),

          control: (base) => ({
            ...base,
            background: themeData.colors.primary[500],
            borderColor: themeData.colors.gray[200],
            padding: '0px',
            fontSize: '12px',
            textOverflow: 'initial',
          }),
          container: (base) => ({
            ...base,
          }),

          singleValue: (base) => ({
            ...base,
            color: 'white',
          }),
        }}
        isSearchable={false}
        components={{
          Option: (props: OptionProps<string, false, any>) => {
            return (
              <components.Option {...props}>
                <Flex>
                  {/* <Center marginRight="8px"> */}
                  {/* @ts-ignore */}
                  {/* <Image w="16px" h="16px" src={props.data.icon} /> */}
                  {/* </Center> */}
                  {props.children}
                </Flex>
              </components.Option>
            );
          },
          DropdownIndicator: () => <Image src={iconArrowDownWhite} w="12px" />,
          IndicatorSeparator: () => <div />,
          Control: ({ children, ...props }: ControlProps<any, false>) => {
            return (
              <components.Control {...props}>
                <Box paddingLeft="4px">
                  <Image src={iconGlobal} />
                </Box>

                {children}
              </components.Control>
            );
          },
        }}
        options={
          [
            { label: '한국어', value: 'kr', icon: getIcon('kr') },
            { label: 'ENG', value: 'en', icon: getIcon('en') },
          ].map((e) => {
            return { value: e.value, label: e.label, icon: e.icon };
          }) as Array<any>
        }
      />
    </Box>
  );
};
const DrawerMenuHeader = () => {
  return (
    <Flex
      padding={['18px 18px', '40px 0px']}
      justifyContent={['start', 'center']}
    >
      <Image src={iconLogo} />
    </Flex>
  );
};
const DrawerMenu = ({ children }: DrawerMenuProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const themeData = useTheme().getThemeData(useContext(ThemeContext).theme);
  const DrawerMenuChild = () => {
    return (
      <>
        {children}
        <Spacer display={['none', 'inherit']} />

        <Box marginBottom="16px">
          <Amplitude>
            <UserContainer />
          </Amplitude>
        </Box>
        <Spacer display={['inherit', 'none']} />

        <VStack display={['inherit', 'none']} align="start" p="16px">
          <Divider
            borderColor={themeData.colors.primary[900]}
            marginBottom="12px"
          />
          {getCsLinkButtons()}
        </VStack>

        <LanguageSelector />
      </>
    );
  };

  return (
    <Flex
      bg={[null, themeData.colors.primary[500]]}
      borderRight={['none', 'solid 1px #e5e5e5']}
    >
      <Flex
        paddingBottom="16px"
        flexDirection="column"
        width="118px"
        display={['none', 'inherit']}
        h="100vh"
      >
        <DrawerMenuHeader />
        <DrawerMenuChild />
      </Flex>
      <Box display={['inherit', 'none']}>
        <IconButton
          aria-label=""
          onClick={onOpen}
          variant="ghost"
          icon={<Image src={iconMenu} />}
        />

        <Drawer onClose={onClose} placement="right" isOpen={isOpen}>
          <DrawerOverlay />

          <DrawerContent
            paddingBottom="16px"
            placement="left"
            bg={themeData.colors.primary[500]}
          >
            <DrawerMenuHeader />
            <DrawerBody p="0px">
              <Flex h="100%" flexDirection="column">
                <DrawerMenuChild />
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </Flex>
  );
};
export default DrawerMenu;
