import icon1Logo from '~/assets/icons/logo.svg';
import icon3Logo from '~/assets/icons/logo_3.svg';

import iconArrowDown from '~/assets/icons/icon_arrow_down.svg';
import iconArrowRight from '~/assets/icons/icon_arrow_right.svg';
import iconArrowRight2 from '~/assets/icons/icon_arrow_right2.svg';
import iconArrowUp from '~/assets/icons/icon_arrow_up.svg';
import iconArrowLineUp from '~/assets/icons/icon_arrow_line_up.svg';
import iconArrowLineDown from '~/assets/icons/icon_arrow_line_down.svg';
import iconBack from '~/assets/icons/icon_arrow.svg';
import iconCalendar from '~/assets/icons/icon_calendar.svg';
import iconCheckOff from '~/assets/icons/icon_check_off.svg';
import iconCheckOn from '~/assets/icons/icon_check_on.svg';
import iconDelete from '~/assets/icons/icon_delete.svg';
import iconInfo from '~/assets/icons/icon_info.svg';

import iconBacktestOff from '~/assets/icons/icon_backtest_off.svg';
import iconBacktestOn from '~/assets/icons/icon_backtest_on.svg';
import iconChatOff from '~/assets/icons/icon_chat_off.svg';
import iconChatOn from '~/assets/icons/icon_chat_on.svg';
import iconMyOff from '~/assets/icons/icon_my_off.svg';
import iconMyOn from '~/assets/icons/icon_my_on.svg';
import iconSearchOff from '~/assets/icons/icon_search_off.svg';
import iconSearchOn from '~/assets/icons/icon_search_on.svg';
import iconArrowNext from '~/assets/icons/icon_arrow_next.svg';
import iconArrowPageRight from '~/assets/icons/icon_arrow_page_right.svg';
import iconArrowPageLeft from '~/assets/icons/icon_arrow_page_left.svg';
import iconMenu from '~/assets/icons/icon_menu.svg';
import iconLogin from '~/assets/icons/icon_login.svg';
import iconDI from '~/assets/icons/icon_di.svg';
import iconDIOff from '~/assets/icons/icon_di_off.svg';
import iconUS from '~/assets/icons/icon_us.svg';
import iconIND from '~/assets/icons/icon_ind.svg';

import iconHK from '~/assets/icons/icon_hk.svg';
import iconJP from '~/assets/icons/icon_jp.svg';
import iconEU from '~/assets/icons/icon_eu.svg';

import iconDown from '~/assets/icons/icon_down.svg';
import iconUp from '~/assets/icons/icon_up.svg';
import iconGlobal from '~/assets/icons/icon_global.svg';
import iconArrowDownWhite from '~/assets/icons/icon_arrow_down_w.svg';
import iconOvalKor from '~/assets/icons/icon_oval_kor.svg';
import iconOvalEng from '~/assets/icons/icon_oval_eng.svg';
import iconCirclePlus from '~/assets/icons/icon_circle_plus.svg';
import iconSageCircle01 from '~/assets/icons/icon_sage_circle_01.png';
import iconSageCircle02 from '~/assets/icons/icon_sage_circle_02.png';
import iconSageCircle03 from '~/assets/icons/icon_sage_circle_03.png';
import iconSageCircle04 from '~/assets/icons/icon_sage_circle_04.png';
import iconSageCircle05 from '~/assets/icons/icon_sage_circle_05.png';
import iconWeightDown from '~/assets/icons/icon_weight_down.svg';
import iconWeightUp from '~/assets/icons/icon_weight_up.svg';
import iconSortingAsc from '~/assets/icons/icon_sorting_asc.svg';
import iconSortingDesc from '~/assets/icons/icon_sorting_desc.svg';
import iconSortingNone from '~/assets/icons/icon_sorting_none.svg';
import iconDocument from '~/assets/icons/icon_document.svg';
import iconClose from '~/assets/icons/icon_close.svg';
import iconRefresh from '~/assets/icons/icon_refresh.svg';
import iconHistory from '~/assets/icons/icon_history.svg';
import iconGoogle from '~/assets/icons/icon_google.svg';
import iconFolder from '~/assets/icons/icon_folder.svg';
import iconMore from '~/assets/icons/icon_more.svg';

import imgSage01 from '~/assets/img/img_sage_01.png';
import imgSage02 from '~/assets/img/img_sage_02.png';
import imgSage03 from '~/assets/img/img_sage_03.png';
import imgSage04 from '~/assets/img/img_sage_04.png';
import imgSage05 from '~/assets/img/img_sage_05.png';

import iconSearch from '~/assets/icons/icon_search.svg';
import iconPrimarySearch from '~/assets/icons/icon_primary_search.svg';
import iconDownload from '~/assets/icons/icon_download.svg';
import iconLogoImage from '~/assets/icons/icon_logo.svg';
import iconRemove from '~/assets/icons/icon_remove.svg';
import iconEdit from '~/assets/icons/icon_edit.svg';
import iconHGDILogo from '~/assets/icons/icon_hg_di_logo.svg';
import iconRelativeTooltipDesc from '~/assets/icons/relative_tooltip_desc.svg';

import iconBannerImage from '~/assets/icons/icon_banner.svg';

import lottieLoading from '~/assets/icons/lottie_loading.json';
import { getIsDiDomain, getIsHkDomain } from '~/utils/domain';

/* eslint-disable-next-line no-nested-ternary */
const iconLogo = getIsHkDomain()
  ? iconHGDILogo
  : getIsDiDomain()
  ? icon3Logo
  : icon1Logo;

export {
  iconRemove,
  iconEdit,
  iconSearch,
  iconArrowDownWhite,
  iconOvalEng,
  iconOvalKor,
  iconGlobal,
  iconLogo,
  iconLogin,
  iconArrowDown,
  iconArrowRight,
  iconArrowUp,
  iconArrowLineUp,
  iconArrowLineDown,
  iconBack,
  iconCalendar,
  iconCheckOff,
  iconCheckOn,
  iconDelete,
  iconInfo,
  iconBacktestOff,
  iconBacktestOn,
  iconChatOff,
  iconChatOn,
  iconMyOff,
  iconMyOn,
  iconSearchOff,
  iconSearchOn,
  iconArrowNext,
  iconArrowPageRight,
  iconArrowPageLeft,
  iconMenu,
  lottieLoading,
  iconDI,
  iconDIOff,
  iconUS,
  iconUp,
  iconDown,
  iconIND,
  iconCirclePlus,
  iconSageCircle01,
  iconSageCircle02,
  iconSageCircle03,
  iconSageCircle04,
  iconSageCircle05,
  iconWeightDown,
  iconWeightUp,
  iconSortingAsc,
  iconSortingDesc,
  iconSortingNone,
  iconDocument,
  iconClose,
  iconRefresh,
  iconHistory,
  imgSage01,
  imgSage02,
  imgSage03,
  imgSage04,
  imgSage05,
  iconArrowRight2,
  iconGoogle,
  iconHK,
  iconJP,
  iconEU,
  iconPrimarySearch,
  iconDownload,
  iconLogoImage,
  iconFolder,
  iconMore,
  iconRelativeTooltipDesc,
  iconBannerImage,
};
