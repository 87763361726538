import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { useAmplitude } from 'react-amplitude-hooks';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useBreakpointValue,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';

import { FactorStateContext } from '../App/context/factor_context';
import { BackTestStateContext } from './context';
import { ExecButton } from '~/components/CustomButton';
import { Repository } from '~/repository';
import useFetch from '~/hooks/useFetch';
import { ResponseBody } from '~/models/responseBody';
import {
  ScreenerCountResult,
  ScreenerExecResult,
} from '~/models/screenerResult';
import { getThemeData } from '~/utils/theme';
import { H5 } from '~/components/Typography';
import { getExecConditions } from '~/utils/factor';
import { getFormatedDate, getYYYYMMDD } from '~/utils/datetime';
import { AppStateContext } from '../App/context';
import ToastLimitComponent from '~/container/shared/ToastLimitComponent';

type WithRepository = {
  repository: Repository;
};
/* eslint-disable no-nested-ternary */
const BacktestingExecButton = ({ repository }: WithRepository) => {
  const history = useHistory();
  const factorState = useContext(FactorStateContext);
  const { logEvent } = useAmplitude();

  const appState = useContext(AppStateContext);

  const backtestState = useContext(BackTestStateContext);
  const [t] = useTranslation();
  const themeData = getThemeData();
  const [isLoading, setIsLoading] = useState(false);
  const selectGroups = Array.from(factorState?.companyGroupList.entries() ?? [])
    .map(([key, value]) => {
      if (value.length === 0) {
        return [];
      }
      if (value.length === 1) {
        return [key];
      }
      return value;
    })
    .flatMap((v) => v)
    .filter((v) => !factorState?.unselectedGroups.has(v.cosmosGroupId));
  const [screenerCountState] = useFetch<
    ResponseBody<ScreenerCountResult>
  >(() => {
    const conditions = factorState?.selectedFactors
      ? getExecConditions(factorState.selectedFactors)
      : [];

    return repository.postScreenerCountResultWithoutKey({
      filterFactors: conditions,
      nationCode: factorState?.selectedNation.code ?? 840,
      cosmosGroupIds: selectGroups.map((v) => v.cosmosGroupId),
    });
  }, [
    factorState?.selectedFactors,
    factorState?.selectedNation,
    factorState?.sortData,
    factorState?.unselectedGroups,
  ]);
  const disabled =
    (factorState?.selectedFactors.size ?? 0) > 30 ||
    factorState?.selectedFactors.size === 0 ||
    screenerCountState.error ||
    screenerCountState.loading ||
    screenerCountState.data?.result?.totalCount === 0 ||
    selectGroups.length === 0;

  const popOverState =
    (factorState?.selectedFactors.size ?? 0) > 30
      ? 'disabled'
      : screenerCountState.data?.result?.totalCount === 0 ||
        selectGroups.length === 0
      ? 'disabled'
      : screenerCountState.data?.result?.totalCount <= 10
      ? 'warning'
      : 'enable';
  const placement = useBreakpointValue(['top', 'bottom']);

  return (
    // @ts-ignore
    <Popover isOpen placement={placement}>
      <PopoverTrigger>
        <Box w="100%">
          <ExecButton
            text={t('text.backtestButton')}
            disabled={disabled}
            isLoading={isLoading}
            onClick={() => {
              if (!factorState || !backtestState) return;
              setIsLoading(true);

              const conditions = factorState?.selectedFactors
                ? getExecConditions(factorState.selectedFactors)
                : [];

              repository
                .postExecBacktesting({
                  cosmosGroupIds: selectGroups.map((v) => v.cosmosGroupId),
                  count: backtestState?.top,
                  filterFactors: conditions,
                  nationCode: factorState.selectedNation.code,
                  sortFactors: [factorState.sortData.data],
                  tradeCost: backtestState?.tradeCost,
                  rebalancingPeriod: backtestState?.rebalancingPeriod,
                  startDate: getFormatedDate({ date: backtestState.startDate }),
                  endDate: getFormatedDate({ date: backtestState.endDate }),
                  portWeightPolicy: 'EQUAL',
                })
                .then((v) => {
                  logEvent('backtesting execute', {
                    'selected country to invest':
                      factorState.selectedNation.name,
                    'backtesting start date': getYYYYMMDD({
                      date: backtestState.startDate,
                    }),
                    'backtesting end date': getYYYYMMDD({
                      date: backtestState.endDate,
                    }),
                    'backtesting num total stocks': backtestState.top,
                    'backtesting transaction cost': backtestState.tradeCost,
                    'backtesting rebalancing period':
                      backtestState.rebalancingPeriod,
                  });

                  history.push(
                    `backtest-result/${
                      (v as ResponseBody<ScreenerExecResult>).result.key
                    }`,
                  );
                })
                .catch((e: AxiosError) => {
                  setIsLoading(false);
                  appState?.showToast(
                    'error',
                    e.response?.status === 429 ? (
                      <ToastLimitComponent />
                    ) : (
                      <H5 noOfLines={10} whiteSpace="pre-wrap">
                        {`예상하지 못한 오류가 발생했습니다(${e.response?.status})\n해당 현상이 반복된다면 문의 바랍니다`}
                      </H5>
                    ),

                    {
                      position: 'bottom-left',
                      toastId: 'backtest-exec-error-toast',
                    },
                  );
                });
            }}
          />
        </Box>
      </PopoverTrigger>
      {screenerCountState.error ||
      screenerCountState.loading ||
      popOverState === 'enable' ? (
        <div />
      ) : (
        <PopoverContent
          style={{ animation: 'smoothAppear .5s', boxShadow: 'none' }}
          bg={
            popOverState === 'disabled'
              ? themeData.colors.error
              : themeData.colors.atomicTangerine
          }
          w="fit-content"
        >
          <PopoverArrow
            bg={
              popOverState === 'disabled'
                ? themeData.colors.error
                : themeData.colors.atomicTangerine
            }
          />
          <PopoverBody w="fit-content" maxW="300px">
            <H5 whiteSpace="normal" noOfLines={3} bold color="white">
              {popOverState === 'disabled'
                ? (factorState?.selectedFactors.size ?? 0) > 30
                  ? '팩터 조건이 30개를 넘을 수 없습니다'
                  : selectGroups.length === 0
                  ? t('text.sectorErrorPopover')
                  : t('text.zeroBacktestingSelected')
                : t('text.tenLessBacktestingSelected')}
            </H5>
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  );
};

export default BacktestingExecButton;
