import { useTranslation } from 'react-i18next';
import { HStack, Spacer, VStack } from '@chakra-ui/react';
import PriceChart from '~/components/PriceChart';
import LoadingComponent from '~/components/LoadingComponent';
import useFetch from '~/hooks/useFetch';
import { CompanyMarketPricesResponse } from '~/models/companyDetail';
import { ResponseBody } from '~/models/responseBody';
import { Repository } from '~/repository';
import { getFormatedDate } from '~/utils/datetime';
import { Body2, H3 } from '~/components/Typography';
import { getThemeData } from '~/utils/theme';

type Props = {
  repository: Repository;
  cosmosCode: number;
  startDate: Date;
  endDate: Date;
};

const PriceHistoryChart = ({
  repository,
  cosmosCode,
  startDate,
  endDate,
}: Props) => {
  const [t] = useTranslation();
  const themeData = getThemeData();
  const [companyMarketPricesResponse] = useFetch<
    ResponseBody<CompanyMarketPricesResponse>
  >(
    () =>
      repository.getCompanyMarketPrices({
        cosmosCode,
        startDate: getFormatedDate({ date: startDate }),
        endDate: getFormatedDate({ date: endDate }),
      }),
    [],
  );

  if (companyMarketPricesResponse.loading) return <LoadingComponent />;

  if (
    companyMarketPricesResponse.error ||
    !companyMarketPricesResponse?.data.result
  )
    return <div />;

  const { priceList } = companyMarketPricesResponse.data.result;
  const chartValues = priceList.filter(
    (v) => v.open != null && v.close != null,
  );
  if (chartValues.length === 0) return <div />;

  return (
    <VStack align="start">
      <HStack w="100%">
        <H3 bold noOfLines={1}>
          {t('companyDetail.priceHistory')}
        </H3>

        <Spacer />
        <Body2 color={themeData.colors.gray[300]}>
          {companyMarketPricesResponse.data.result.currency}
        </Body2>
      </HStack>
      <PriceChart
        values={chartValues}
        // currency={currency}
      />
      ;
    </VStack>
  );
};

export default PriceHistoryChart;
