import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import amplitude from 'amplitude-js';
import { getAuth, signInWithPopup, signOut } from 'firebase/auth';
import {
  getFirestore,
  doc,
  onSnapshot,
  Unsubscribe,
  updateDoc,
  query,
  collection,
  getDocs,
  where,
  getDoc,
} from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';

import fetch from '~/utils/fetch';
import { Body2, Caption } from '~/components/Typography';
import { iconGoogle } from '~/constants/assets';
import { AppStateContext } from '~/routes/App/context';
import {
  AuthDispatchContext,
  AuthStateContext,
} from '~/routes/App/context/auth_context';
import { FactorDispatchContext } from '~/routes/App/context/factor_context';
import { encrpytAes128 } from '~/utils/crpyto';
import { getformatedYYYYMMDD } from '~/utils/datetime';
import { auth, provider } from '~/utils/firebase';
import { getThemeData } from '~/utils/theme';

const generateRandomKey = () => {
  return (Math.random() + 1).toString(36).substring(2);
};

const isValidKeyRequest = async (
  key: string,
  plan?: string,
): Promise<boolean> => {
  if (!key && plan === 'FREE') return false;
  if (!key && plan !== 'FREE') return true;
  try {
    const result = await fetch({
      method: 'GET',
      uri: 'https://us-central1-stmos-w.cloudfunctions.net/isValidKey',
      params: { key },
    })
      .then((response) => {
        return (response.data as { result: boolean }).result;
      })
      .catch((e) => {
        // console.log(`ERROR ${e}`);
        throw Error(e);
      });
    return result;
  } catch (e) {
    // console.log(e);
  }
  return true;
};
const UserContainer = () => {
  const appState = useContext(AppStateContext);
  const authState = useContext(AuthStateContext);
  const authDispatch = useContext(AuthDispatchContext);
  const factorDispatch = useContext(FactorDispatchContext);

  const themeData = getThemeData();
  if (!authState || !authDispatch || !factorDispatch) return <div />;
  const [t] = useTranslation();
  const { amplitudeInstance, logEvent } = useAmplitude();
  const [key, setKey] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  let unsub: Unsubscribe | null;
  const { plan, user } = authState;

  const promotionKeyList = Array<string>();
  const onKeySubmit = () => {
    if (!user) return;

    if (promotionKeyList.includes(key)) {
      // promotion key 등록 플로우

      getDoc(doc(getFirestore(), 'users', user.uid)).then((userDoc) => {
        const usedPromotionKeyList = userDoc.get('usedPromotionKeyList') ?? [];

        // 1. 유저가 이미 사용한 키인지 체크한다.
        if (usedPromotionKeyList.includes(key)) {
          // 사용한 키라면 에러
          setErrorMessage(t('text.usedKeyMessage'));
        } else {
          // 2. 키를 등록하고 사용한 키 리스트에 해당 키를 추가한다. expiredDate는 now+ 7days
          // key => originKey+userid

          const defaultExpiredDate = new Date();
          defaultExpiredDate.setDate(defaultExpiredDate.getDate() + 7);

          const expiredDate = getformatedYYYYMMDD({ date: defaultExpiredDate });

          updateDoc(doc(getFirestore(), 'users', user.uid), {
            plan: 'BASIC',
            expiredDate,
            originKey: key,
            key: encrpytAes128(`BASIC-${expiredDate}-${key}${user.uid}`),
            usedPromotionKeyList: [...usedPromotionKeyList, key],
          }).then(() => {
            appState?.showToast('info', t('text.registerKeySuccessMessage'), {
              position: 'bottom-left',
            });
            onClose();
          });
        }
      });
    } else {
      getDocs(
        query(collection(getFirestore(), 'keys'), where('value', '==', key)),
      ).then((querySnapshot) => {
        if (querySnapshot.empty) {
          setErrorMessage(t('text.invalidKeyMessage'));
        } else {
          querySnapshot.forEach((keyData) => {
            if (keyData.get('isUsed') === false) {
              const defaultExpiredDate = new Date();
              // defaultExpiredDate.setMonth(defaultExpiredDate.getMonth() + 1);

              const getDays = keyData.get('days');
              const days = getDays ? parseInt(getDays, 10) : 30;
              defaultExpiredDate.setDate(defaultExpiredDate.getDate() + days);

              const expiredDate =
                keyData.get('expiredDate') ??
                getformatedYYYYMMDD({ date: defaultExpiredDate });

              updateDoc(doc(getFirestore(), 'users', user.uid), {
                plan: keyData.get('plan'),
                expiredDate,
                originKey: keyData.get('value'),
                key: encrpytAes128(
                  `${keyData.get('plan')}-${expiredDate}-${keyData.get(
                    'value',
                  )}`,
                ),
              }).then(() => {
                updateDoc(doc(getFirestore(), 'keys', keyData.id), {
                  isUsed: true,
                });
                appState?.showToast(
                  'info',
                  t('text.registerKeySuccessMessage'),
                  {
                    position: 'bottom-left',
                  },
                );
                onClose();
              });
            } else {
              setErrorMessage(t('text.usedKeyMessage'));
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    auth.onAuthStateChanged(() => {
      authDispatch({ type: 'LOGIN', user: auth.currentUser });
    });
  }, []);

  useEffect(() => {
    const userType = !user ? 'null' : plan;
    if (userType) {
      amplitudeInstance?.setUserProperties({
        'user type': userType,
      });
    }
  }, [user, plan]);

  useEffect(() => {
    const userType = !user ? 'null' : plan;
    if (userType) {
      amplitudeInstance?.setUserProperties({
        'user type': userType,
      });
    }

    if (user && !unsub) {
      amplitude.getInstance().setUserId(user.uid);

      unsub = onSnapshot(doc(getFirestore(), 'users', user.uid), (userData) => {
        if (plan !== userData.get('plan')) {
          authDispatch({ type: 'SET_PLAN', plan: userData.get('plan') });
        }

        const key = userData.get('key');
        isValidKeyRequest(key, userData.get('plan')).then((v) => {
          if (v) {
            if (key) {
              sessionStorage.setItem('Authorization', key);
            }
          } else {
            // TODO : 이 Free key 업데이트를 firebase functions에서 실행하도록 하자
            const randomKey = generateRandomKey();
            const expiredDate = getformatedYYYYMMDD({ date: new Date() });

            logEvent('key expires', {
              prev: userData.get('originKey'),
              next: randomKey,
            });

            const key = encrpytAes128(`FREE-${expiredDate}-${randomKey}`);
            updateDoc(doc(getFirestore(), 'users', user.uid), {
              plan: 'FREE',
              expiredDate,
              originKey: null,
              key,
            }).then(() => {
              sessionStorage.setItem('Authorization', key);
            });
          }
        });
      });
    }
  }, [user]);

  const hasNoPlan = !plan || plan === 'FREE';
  if (user) {
    return (
      <VStack p="0px 16px" overflow="hidden">
        <Image
          w="50px"
          h="50px"
          src={user?.photoURL?.toString()}
          borderRadius="full"
        />
        <Button
          size={hasNoPlan ? 'md' : 'xs'}
          backgroundColor={
            hasNoPlan ? themeData.colors.atomicTangerine : themeData.colors.pink
          }
          padding={hasNoPlan ? '8px' : '4px'}
          onClick={() => {
            onOpen();
          }}
        >
          <Body2
            bold
            whiteSpace="nowrap"
            color={hasNoPlan ? 'white' : themeData.colors.atomicTangerine}
          >
            {hasNoPlan ? t('text.registerKey') : plan}
          </Body2>
        </Button>

        <Divider
          style={{
            marginBottom: '0px',
            marginTop: '16px',
            borderColor: themeData.colors.primary[900],
            opacity: 1,
          }}
        />

        <Button
          variant="ghost"
          onClick={() => {
            signOut(auth);
            authDispatch({ type: 'LOGOUT' });
            factorDispatch({
              type: 'UPDATE_SELECTED_CONDITIONS',
              selectedConditions: '',
            });

            if (unsub) {
              unsub();
              unsub = null;
            }
            sessionStorage.removeItem('Authorization');
          }}
        >
          <Caption color="white">{t('text.logout')}</Caption>
        </Button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent borderRadius="16px">
            <ModalHeader fontWeight="bold">{t('text.registerKey')}</ModalHeader>
            <ModalCloseButton color={themeData.colors.gray[300]} />
            <ModalBody pb={2}>
              <FormControl>
                <FormLabel marginBottom="12px">
                  <Body2 color={themeData.colors.gray[500]}>
                    {t('text.inputKey')}
                  </Body2>
                </FormLabel>
                <Input
                  onChange={(event) => {
                    if (errorMessage) {
                      setErrorMessage(undefined);
                    }
                    setKey(event.target.value);
                  }}
                  placeholder={t('text.keyPlaceHolder')}
                />
                {errorMessage ? (
                  <Box marginTop="8px">
                    <Caption color={themeData.colors.error}>
                      {errorMessage}
                    </Caption>
                  </Box>
                ) : (
                  <div />
                )}
              </FormControl>
              <Flex marginTop="8px">
                <Spacer />
                <Link
                  isExternal
                  href="https://doomoolmori.notion.site/PLAN-0c8792de6b0c464c827cb1c1b13b4caa"
                >
                  <Body2
                    color={themeData.colors.primary[500]}
                    decoration="underline"
                  >
                    코드를 받는 방법은?
                  </Body2>
                </Link>
              </Flex>
            </ModalBody>

            <ModalFooter justifyContent="center">
              <Button p="0px 24px" colorScheme="gray" mr={3} onClick={onClose}>
                {t('text.cancel')}
              </Button>
              <Button
                p="0px 24px"
                colorScheme="primary"
                disabled={errorMessage !== undefined || key === ''}
                onClick={onKeySubmit}
              >
                {t('text.save')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </VStack>
    );
  }

  return (
    <Center marginTop={['24px', '0px']}>
      <Button
        h={['48px', '86px']}
        w={['80%', '86px']}
        onClick={() => {
          signInWithPopup(getAuth(), provider).then((credential) => {
            authDispatch({ type: 'LOGIN', user: credential.user });
          });
        }}
      >
        <Flex flexDirection={['row', 'column']} alignItems="center">
          <Image src={iconGoogle} />
          <Box
            marginLeft={['4px', '0px']}
            whiteSpace={['inherit', 'break-spaces']}
          >
            <Body2
              whiteSpace="inherit"
              noOfLines={2}
              color={themeData.colors.gray[500]}
              align="center"
            >
              {t('text.startWithGoogle')}
            </Body2>
          </Box>
        </Flex>
      </Button>
    </Center>
  );
};
export default UserContainer;
