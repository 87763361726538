import { useContext } from 'react';
import { Button, Center, CircularProgress, Image } from '@chakra-ui/react';
import { useTheme } from '~/hooks/useTheme';
import { ThemeContext } from '~/theme';
import { Body2 } from '../Typography';
import { iconArrowRight2 } from '~/constants/assets';

type CustomButtonProps = {
  text: string | undefined;
  onClick: () => void;
  color?: string;
  disabled?: boolean;
  isLoading?: boolean;
  variant?: 'solid' | 'outline' | 'ghost' | 'link';
};

export const BorderButton = ({
  text,
  onClick,
  color,
  variant,
  disabled,
  isLoading,
}: CustomButtonProps) => {
  return (
    <Button
      variant={variant}
      size="sm"
      isLoading={isLoading}
      disabled={disabled}
      borderRadius="full"
      borderColor={color}
      color={color}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

BorderButton.defaultProps = {
  variant: 'outline',
  disabled: false,
  isLoading: false,
  color: 'primary',
};

export const ExecButton = ({
  text,
  onClick,
  variant,
  disabled,
  isLoading,
  color,
}: CustomButtonProps) => {
  const themeData = useTheme().getThemeData(useContext(ThemeContext).theme);
  return (
    <Button
      p={['4px 0px', '8px']}
      variant={variant}
      _hover={{
        background: disabled
          ? themeData.colors.gray[100]
          : themeData.colors.primary[400],
      }}
      colorScheme={disabled ? 'blackAlpha' : 'primary'}
      bg={disabled ? themeData.colors.gray[200] : themeData.colors.primary[500]}
      w={['100%', null]}
      h="100%"
      size="xl"
      color={color}
      borderRadius={['base', 'full']}
      onClick={disabled ? () => null : onClick}
    >
      {isLoading ? (
        <CircularProgress isIndeterminate color="primary.500" />
      ) : (
        <>
          <Center flexDirection="row" display="flex" margin="0px 16px">
            <Body2
              whiteSpace="break-spaces"
              bold
              color={disabled ? themeData.colors.gray[300] : 'white'}
            >
              {text}
            </Body2>
          </Center>
          <Center
            display={['none', 'inherit']}
            borderRadius="full"
            w="40px"
            h="40px"
            bg={
              disabled
                ? themeData.colors.gray[300]
                : themeData.colors.primary[900]
            }
          >
            <Image src={iconArrowRight2} />
          </Center>
        </>
      )}
    </Button>
  );
};
ExecButton.defaultProps = {
  variant: 'solid',
  disabled: false,
  isLoading: false,
  color: 'primary',
};
