import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { H5 } from '~/components/Typography';
import { Repository } from '~/repository';
import FinancialValues from './FinancialValues';
import PriceValues from './PriceValues';

type Props = {
  repository: Repository;
  cosmosCode: number;
};

const TabBody = ({ repository, cosmosCode }: Props) => {
  const [t] = useTranslation();
  return (
    <Box
      overflowX="hidden"
      w={['100%', 'initial']}
      m={['4px', '36px 4px']}
      marginLeft={['0px', '24px']}
      p={['0px', '24px']}
      bg="white"
      borderRadius="8px"
      shadow={['0px', '0px 0px 8px #e0e0e0']}
    >
      <Tabs colorScheme="primary">
        <TabList>
          {/* '애널리스트', '투자자', '내부거래', '관련기업' */}
          {[t('companyDetail.tab.performance')].map((v) => (
            <Tab>
              <H5 color="inherit">{v}</H5>
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          <TabPanel>
            <PriceValues repository={repository} cosmosCode={cosmosCode} />
            <FinancialValues repository={repository} cosmosCode={cosmosCode} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default TabBody;
