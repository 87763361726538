import { Box, Spacer } from '@chakra-ui/react';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';
import { Caption } from '~/components/Typography';
import { getThemeData } from '~/utils/theme';

import { SortData } from '~/models/exeScreenerBody';

export type ScreenerSortType = {
  name: string;
  data: SortData;
};
export const screenerSortData: Array<ScreenerSortType> = [
  {
    name: 'MarketCapitalizationDesc',
    data: {
      factorId: 1133,
      order: 'DESC',
    },
  },
  {
    name: 'MarketCapitalizationAsc',
    data: {
      factorId: 1133,
      order: 'ASC',
    },
  },
  {
    name: 'OperatingProfitMarginDesc',
    data: {
      factorId: 1087,
      order: 'DESC',
    },
  },
  {
    name: 'ReturnOnEquityDesc',
    data: {
      factorId: 1112,
      order: 'DESC',
    },
  },
];
//

type Props = {
  onSelect: (data: ScreenerSortType) => void;
  selectedData: ScreenerSortType;
};

const ScreenerSortSelector = ({ onSelect, selectedData }: Props) => {
  const themeData = getThemeData();

  const { t } = useTranslation();

  return (
    <Box>
      <Caption color={themeData.colors.text3}>{t('text.sort')}</Caption>
      <Spacer h="8px" />
      <ReactSelect
        value={{
          // @ts-ignore
          value: selectedData,
          // @ts-ignore
          label: t(`screenerSort.${selectedData.name}`),
        }}
        isSearchable={false}
        onChange={(v) => {
          // @ts-ignore
          onSelect(v.value);
        }}
        options={[
          ...(screenerSortData.map((e) => {
            // @ts-ignore
            return { value: e, label: t(`screenerSort.${e.name}`) };
          }) as Array<any>),
        ]}
        styles={{
          option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? '#F5FCF8' : 'white',
            color: themeData.colors.text1,
            fontSize: themeData.typography.h5.fontSize,
          }),
          control: (provided) => ({
            ...provided,
            border: `solid 1px ${themeData.colors.primary[900]}`,
            borderRadius: 8,
            color: themeData.colors.text2,
            fontSize: themeData.typography.h5.fontSize,
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
        }}
      />
    </Box>
  );
};

export default ScreenerSortSelector;
