export const getFactorList = 'v1/factor-list';
export const getFactorHistogram = 'v1/factor-histogram';
export const getNationList = 'v1/nation-list';
export const getFactorCategoryList = 'v1/factor-category-list';

// 3.1 screener

export const getScreenerCountResult = 'v3.1/screener/total-count-by-key';
export const postScreenerCountResultWithoutKey =
  'v3.1/screener/total-count-by-request';
// TODO
export const postExecScreener = 'v3.1/screener/register';
// TODO
export const getScreenerResult = 'v3.1/screener/execute-by-key';

// 3.1 backtesting

export const postExecBacktesting = 'v3.1/backtesting/register';
export const getBacktestingYieldIndexResult = 'v3.1/backtesting/execute-by-key';
export const getBacktestingRequestByKey = 'v3.1/backtesting/request-by-key';

export const getCompaniesByTickers = 'v1/companies-by-tickers';

export const postDirectIndexBacktestingExecute =
  'v3/direct-index/backtesting/execute';
export const getBacktestingRequestStatus = 'v2/request-status';

export const getDIBacktestingYieldIndexResult =
  'v2/backtesting-result/yield-index';
export const getBacktestingPortfolioResult = 'v2/backtesting-result/portfolio';

export const getDirectIndexCompanyListFromIndex =
  'v1/direct-index/company-list-from-index';
export const getDirectIndexIndexList = 'v1/direct-index/index-list';
export const getDirectIndexIndexPriceList = 'v1/direct-index/index-price-list';
export const getDirectIndexIndexSummary = 'v1/direct-index/index-summary';
export const getDirectIndexCompanyFactorByIndex =
  'v1/direct-index/company-factor-values';
export const getDirectIndexFactorDistribution =
  'v1/direct-index/index-factor-value-distributions';
export const getFinancialStatementValues = 'v1/financial-statement-values';
export const getCompanySummaryInfo = 'v1/company-summary-info';

export const getFinancialRatioValues = 'v1/financial-ratio-values';
export const getCompanyMarketPrices = 'v1/company-market-prices';
export const getSearchCompanies = 'v1/search-companies';
export const getCompanyGroupList = 'v1/company-group-list';

export const postStrategyFactorUnitPerformance =
  '/strategy/factor/:id/unit-performance';
