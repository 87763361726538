import React from 'react';
import ReactDOM from 'react-dom';
import { IconContext } from 'react-icons';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import '~/i18n';
import App from './routes/App';
import { Repository, ProdRepository } from './repository';
import './theme/font.css';
import './theme/animation.css';
// import { ErrorFallbackComponent } from './components/ErrorFallbackComponent';

Sentry.init({
  debug: process.env.NODE_ENV !== 'production',
  environment: process.env.NODE_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const repository: Repository =
  // !process.env.NODE_ENV || process.env.NODE_ENV !== 'production'
  // ? new MockRepository():
  new ProdRepository();

ReactDOM.render(
  <React.StrictMode>
    <IconContext.Provider value={{ color: 'black' }}>
      {/* <Sentry.ErrorBoundary
        fallback={({ error, componentStack, eventId, resetError }) => (
          <ErrorFallbackComponent
            error={error}
            componentStack={componentStack}
            eventId={eventId}
            resetError={resetError}
          />
        )}
      > */}
      <App repository={repository} />
      {/* </Sentry.ErrorBoundary> */}
    </IconContext.Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// reportWebVitals(console.log);
