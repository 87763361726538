import { Button } from '@chakra-ui/button';
import { Flex, Center } from '@chakra-ui/layout';
import { Image, Tooltip } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { iconCheckOff, iconCheckOn, iconInfo } from '~/constants/assets';
import { Factor } from '~/models/factor';
import { getThemeData } from '~/utils/theme';
import { Body2 } from '../Typography';

type FactorListItemProps = {
  factor: Factor;
  isSelected: boolean;
  onChange: (isSelected: boolean) => void;
};

const FactorListItem = ({
  factor,
  isSelected,
  onChange,
}: FactorListItemProps) => {
  const themeData = getThemeData();
  const [t] = useTranslation();
  const { i18n } = useTranslation();
  return useMemo(
    () => (
      <Flex>
        <Button
          style={{ boxShadow: 'none' }}
          onClick={() => {
            onChange(!isSelected);
          }}
          variant="unstyled"
        >
          <Flex m="12px 24px" align="center" flexWrap="nowrap">
            <Image
              src={isSelected ? iconCheckOn : iconCheckOff}
              marginRight="10px"
            />
            <Body2 whiteSpace="nowrap" noOfLines={1}>
              {/* @ts-ignore */}
              {t(`factor.${factor.id}.name`)}
            </Body2>
            <Tooltip
              color={themeData.colors.text2}
              bg="white"
              box-shadow="0px 3px 3px #ccc"
              whiteSpace="pre-line"
              // @ts-ignore
              label={t(`factor.${factor.id}.description`)}
            >
              <Center tabIndex={0}>
                <Image
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  _focus={{ boxShadow: 'none', borderWidth: '0px' }}
                  marginLeft="5px"
                  src={iconInfo}
                />
              </Center>
            </Tooltip>
          </Flex>
        </Button>
      </Flex>
    ),
    [isSelected, i18n.language],
  );
};

export default FactorListItem;
