import lottie from 'lottie-web';
import { useEffect, useRef } from 'react';
import { lottieLoading } from '~/constants/assets';

type Props = {
  height?: string;
};
const LoadingComponent = ({ height }: Props) => {
  const element = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (element.current)
      // add this
      lottie.loadAnimation({
        animationData: lottieLoading,
        container: element.current,
        loop: true,
      });
  }, [element]); // add this, it triggers this effect everytime element changes

  return <div style={{ height }} ref={element} />;
};
LoadingComponent.defaultProps = {
  height: null,
};
export default LoadingComponent;
