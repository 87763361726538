import { Spacer, Box } from '@chakra-ui/layout';
import { useContext } from 'react';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';
import { useAmplitude } from 'react-amplitude-hooks';

import { Flex, Link } from '@chakra-ui/react';
import CustomDatePicker from '~/components/CustomDatePicker';
import { H3, Caption, Body2 } from '~/components/Typography';
import { Repository } from '~/repository';
import {
  FactorDispatchContext,
  FactorStateContext,
} from '~/routes/App/context/factor_context';
import {
  BackTestDispatchContext,
  BackTestStateContext,
} from '~/routes/backtest/context';
import { getFromLS } from '~/utils/localStorage';
import { getThemeData } from '~/utils/theme';
import NationSelectorContainer from '~/container/shared/NationSelector';
import NumberEditableInput from '~/components/NumberEditableInput';
import ScreenerSortSelector, {
  screenerSortData,
  ScreenerSortType,
} from '~/container/screener/ScreenerSortSelector';
import { getYYYYMMDD } from '~/utils/datetime';
import { AppStateContext } from '~/routes/App/context';
import SectorSelect from '~/container/SectorSelector';
import BannerContent from '~/container/shared/BannerContent';

type Props = {
  repository: Repository;
};

const BacktestOptionContainer = ({ repository }: Props) => {
  const appState = useContext(AppStateContext);
  const factorState = useContext(FactorStateContext);
  const factorDispatch = useContext(FactorDispatchContext);
  const backTestState = useContext(BackTestStateContext);
  const backtestDispatch = useContext(BackTestDispatchContext);
  const themeData = getThemeData();
  const [t] = useTranslation();
  const { logEvent } = useAmplitude();

  if (!backTestState) return <div>ERROR</div>;

  const startEndDate = new Date();
  startEndDate.setFullYear(startEndDate.getFullYear() - 1);

  const endStartDate = new Date(backTestState.startDate);
  endStartDate.setFullYear(endStartDate.getFullYear() + 1);

  return (
    <Box bg={themeData.colors.background} h="100%" p="24px 32px" w="100%">
      <Box marginBottom="24px">
        <H3 bold>{t('text.defaultSettings')}</H3>
      </Box>
      <NationSelectorContainer
        repository={repository}
        selectedNation={
          factorState?.selectedNation ??
          JSON.parse(
            getFromLS(
              'SELECTED_NATION',
              '{"code": 840,"name": "UNITED STATES"}',
            ),
          )
        }
        onSelectNation={(nation) => {
          logEvent('backtesting basic setting adjusted', {
            'selected country to invest': nation.name,
          });
          if (nation != null && factorDispatch != null) {
            factorDispatch({
              type: 'UPDATE_SELECTED_NATION',
              nation,
            });
          }
        }}
      />

      <Spacer h="20px" />

      <CustomDatePicker
        label={t('text.startDatetime')}
        selected={backTestState.startDate}
        minDate={new Date(2005, 1)}
        maxDate={startEndDate}
        onChange={(date) => {
          if (date) {
            logEvent('backtesting basic setting adjusted', {
              'backtesting start date': getYYYYMMDD({ date }),
            });
          }
          if (date && backtestDispatch) {
            factorDispatch?.({
              type: 'UPDATE_IS_EDIT_CURRENT_CONDITIONS',
              isEditCurrentConditions: true,
            });

            backtestDispatch({
              type: 'UPDATE_START_DATE',
              date: date as Date,
            });

            const msDiff =
              backTestState.endDate.getTime() - (date as Date).getTime();
            const diff = Math.floor(msDiff / (1000 * 60 * 60 * 24));

            if (diff < 365) {
              appState?.showToast(
                'info',
                t('text.backtestDatetimeChangeAlert'),
                {
                  position: 'bottom-left',
                },
              );
              const fixedDate = new Date(date as Date);
              fixedDate.setFullYear(fixedDate.getFullYear() + 1);
              factorDispatch?.({
                type: 'UPDATE_IS_EDIT_CURRENT_CONDITIONS',
                isEditCurrentConditions: true,
              });

              backtestDispatch({
                type: 'UPDATE_END_DATE',
                date: fixedDate,
              });
            }
          }
        }}
      />

      <Spacer h="20px" />

      <CustomDatePicker
        label={t('text.endDatetime')}
        selected={backTestState.endDate}
        minDate={endStartDate}
        maxDate={new Date()}
        onChange={(date) => {
          if (date) {
            logEvent('backtesting basic setting adjusted', {
              'backtesting end date': getYYYYMMDD({ date }),
            });
          }
          if (date && backtestDispatch) {
            factorDispatch?.({
              type: 'UPDATE_IS_EDIT_CURRENT_CONDITIONS',
              isEditCurrentConditions: true,
            });

            backtestDispatch({
              type: 'UPDATE_END_DATE',
              date: date as Date,
            });
          }
        }}
      />

      <Spacer h="20px" />
      <Caption color={themeData.colors.text3}>
        {t('text.transactionCost')}
      </Caption>
      <Spacer h="8px" />
      <NumberEditableInput
        initialValue={backTestState.tradeCost}
        tailText="%"
        onUpdate={(tradeCost) => {
          logEvent('backtesting basic setting adjusted', {
            'backtesting transaction cost': tradeCost.toString(),
          });
          if (tradeCost !== undefined && backtestDispatch) {
            factorDispatch?.({
              type: 'UPDATE_IS_EDIT_CURRENT_CONDITIONS',
              isEditCurrentConditions: true,
            });
            backtestDispatch({
              type: 'UPDATE_TRADE_COST',
              tradeCost,
            });
          }
        }}
        canDecimals
      />

      <Spacer h="20px" />

      <Caption color={themeData.colors.text3}>
        {t('text.maxCount')} (max 50)
      </Caption>
      <Spacer h="8px" />
      <NumberEditableInput
        max={50}
        initialValue={backTestState.top}
        onUpdate={(top) => {
          logEvent('backtesting basic setting adjusted', {
            'backtesting num total stocks': top.toString(),
          });

          if (top && backtestDispatch) {
            factorDispatch?.({
              type: 'UPDATE_IS_EDIT_CURRENT_CONDITIONS',
              isEditCurrentConditions: true,
            });
            backtestDispatch({
              type: 'UPDATE_TOP',
              top,
            });
          }
        }}
      />

      <Spacer h="20px" />

      <Caption color={themeData.colors.text3}>
        {t('text.rebalancingPeriod')}
      </Caption>
      <Spacer h="8px" />
      <ReactSelect
        value={{
          value: backTestState.rebalancingPeriod,
          // @ts-ignore
          label: t(`period.${backTestState.rebalancingPeriod}`),
        }}
        isSearchable={false}
        // @ts-ignore
        onChange={({ value }) => {
          logEvent('backtesting basic setting adjusted', {
            'backtesting rebalancing period': value.toString(),
          });
          if (value && backtestDispatch) {
            factorDispatch?.({
              type: 'UPDATE_IS_EDIT_CURRENT_CONDITIONS',
              isEditCurrentConditions: true,
            });
            backtestDispatch({
              type: 'UPDATE_REBALANCING_PERIOD',
              rebalancingPeriod: value,
            });
          }
        }}
        options={
          ['ANNUALLY', 'QUARTERLY', 'SEMIANNUALLY'].map((e) => {
            // @ts-ignore
            return { value: e, label: t(`period.${e}`) };
          }) as Array<any>
        }
        styles={{
          option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? '#F5FCF8' : 'white',
            color: themeData.colors.text1,
            fontSize: themeData.typography.h5.fontSize,
          }),
          control: (provided) => ({
            ...provided,
            border: `solid 1px ${themeData.colors.primary[900]}`,
            borderRadius: 8,
            color: themeData.colors.text2,
            fontSize: themeData.typography.h5.fontSize,
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
        }}
      />

      <Spacer h="20px" />

      <ScreenerSortSelector
        onSelect={(data) => {
          logEvent('backtesting basic setting adjusted', {
            'sorting priority': data.name,
          });

          if (data != null && factorDispatch != null) {
            // setToLS('SELECTED_SORT_DATA', JSON.stringify(data));
            factorDispatch({
              type: 'UPDATE_SORT_OPTION',
              sortData: data,
            });
          }
        }}
        selectedData={
          screenerSortData.find(
            (v) =>
              v.data.factorId === factorState?.sortData.data.factorId &&
              v.data.order === factorState?.sortData.data.order,
          ) ??
          (JSON.parse(
            getFromLS(
              'SELECTED_SORT_OPTION',
              JSON.stringify(screenerSortData[0]),
            ),
          ) as ScreenerSortType)
        }
      />

      <Spacer h="20px" />

      <SectorSelect />

      {/* <Spacer h="20px" />

      <BannerContent /> */}
    </Box>
  );
};

export default BacktestOptionContainer;
