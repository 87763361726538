import { createContext, Dispatch } from 'react';

/*
SCREENER STATEMENT
*/
export type ScreenerState = { count: number };
export type ScreenerAction = { type: 'SCREENER_LOAD'; count: number }; // TODO REMOVE COUNT

function ScreenerReducer(
  state: ScreenerState,
  action: ScreenerAction,
): ScreenerState {
  switch (action.type) {
    case 'SCREENER_LOAD':
      return { ...state, count: action.count };
    default:
      return { ...state };
  }
}

type ScreenerDispatch = Dispatch<ScreenerAction>;
const ScreenerDispatchContext = createContext<ScreenerDispatch | null>(null);
const ScreenerStateContext = createContext<ScreenerState | null>(null);

export { ScreenerReducer, ScreenerStateContext, ScreenerDispatchContext };
