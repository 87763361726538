import { AmplitudeClient } from 'amplitude-js';

interface AmplitudeProps {
  amplitudeInstance: AmplitudeClient | undefined;
}
export const addCriticalActionsCountAmplitude = ({
  amplitudeInstance,
}: AmplitudeProps) => {
  if (amplitudeInstance) {
    const identify = new amplitudeInstance.Identify().add(
      'num total of critical actions performed',
      1,
    );
    amplitudeInstance?.identify(identify);
  }
};
