import { AxiosError } from 'axios';
import fetch from '~/utils/fetch';
import { Factor } from '~/models/factor';
import { Nation } from '~/models/nation';
import { FactorHistogram } from '~/models/factorHistogram';
import { Repository } from '.';
import {
  getFactorList,
  getFactorHistogram,
  getNationList,
  getFactorCategoryList,
  postExecScreener,
  postScreenerCountResultWithoutKey,
  postExecBacktesting,
  getBacktestingRequestStatus,
  getBacktestingYieldIndexResult,
  postDirectIndexBacktestingExecute,
  getDirectIndexIndexList,
  getDirectIndexCompanyListFromIndex,
  getDirectIndexIndexPriceList,
  getDirectIndexIndexSummary,
  getDirectIndexCompanyFactorByIndex,
  getDirectIndexFactorDistribution,
  getBacktestingPortfolioResult,
  getFinancialStatementValues,
  getCompanySummaryInfo,
  getFinancialRatioValues,
  getCompanyMarketPrices,
  getSearchCompanies,
  getScreenerResult,
  getScreenerCountResult,
  getCompanyGroupList,
  getDIBacktestingYieldIndexResult,
  getBacktestingRequestByKey,
  postStrategyFactorUnitPerformance,
} from '~/constants/endPoint';
import { FactorCategory } from '~/models/factorCategory';
import { ResponseBody } from '~/models/responseBody';
import {
  CompanyGroupListResponse,
  ScreenerCountResult,
  ScreenerExecResult,
  ScreenerResult,
} from '~/models/screenerResult';
import {
  ExecScreenerCountBody,
  ExecScreenerRequest,
  ExecScreenerResultRequest,
} from '~/models/exeScreenerBody';
import {
  BacktestingEsResult,
  BacktestingPortfolioResult,
  BacktestingRequestStatusResult,
  BacktestingYieldIndexResult,
  ExecBackTestingBody,
} from '~/models/execBacktesting';
import {
  DirectIndexBacktestExecuteBody,
  DirectIndexCompanyListFromIndexBody,
  DirectIndexCompanyListFromIndexResult,
  DirectIndexFactorValueDistribution,
  DirectIndexFactorValues,
  DirectIndexIndex,
  DirectIndexIndexPriceListRequest,
  DirectIndexIndexPriceListResponse,
  DirectIndexIndexSummary,
  StrategyFactorUnitPerformanceRequest,
  StrategyFactorUnitPerformanceResponse,
} from '~/models/directIndex';
import {
  CompanyFinancialRatioValuesRequest,
  CompanyFinancialRatioValuesResponse,
  CompanyMarketPricesRequest,
  CompanyMarketPricesResponse,
  CompanySummaryInfoResponse,
  FinancialStatementValuesRequest,
  FinancialStatementValuesResponse,
  SearchCompanyRequest,
  SearchCompanyResponse,
} from '~/models/companyDetail';

const baseUrl = process.env.REACT_APP_SERVER_URL;

export default class implements Repository {
  async postExecScreener(
    body: ExecScreenerRequest,
  ): Promise<ResponseBody<ScreenerExecResult> | AxiosError> {
    this.postExecScreener = this.postExecScreener.bind(this);

    const result = await fetch({
      method: 'POST',
      uri: `${baseUrl}/${postExecScreener}`,
      body,
    })
      .then((response) => response.data as ResponseBody<ScreenerExecResult>)
      .catch((e) => {
        throw e;
      });
    return result;
  }

  async getScreenerResult(
    params: ExecScreenerResultRequest,
  ): Promise<ResponseBody<ScreenerResult> | AxiosError> {
    this.getScreenerResult = this.getScreenerResult.bind(this);

    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getScreenerResult}`,
      params,
    })
      .then((response) => response.data as ResponseBody<ScreenerResult>)
      .catch((e) => {
        throw e;
      });
    return result;
  }

  async getScreenerCountResult(
    requestKey: string,
  ): Promise<number | AxiosError> {
    this.getScreenerCountResult = this.getScreenerCountResult.bind(this);

    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getScreenerCountResult}`,
      params: { requestKey },
    })
      .then(
        (response) =>
          (response.data as ResponseBody<any>).result['totalCount'] as number,
      )
      .catch((e) => {
        throw e;
      });
    return result;
  }

  async getFactorCategoryList(): Promise<Array<FactorCategory> | AxiosError> {
    this.getFactorCategoryList = this.getFactorCategoryList.bind(this);

    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getFactorCategoryList}`,
    })
      .then((response) => response.data as Array<FactorCategory>)
      .catch((e) => {
        throw e;
      });

    return result;
  }

  async getFactorList() {
    this.getFactorList = this.getFactorList.bind(this);
    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getFactorList}`,
    })
      .then((response) => response.data as Array<Factor>)
      .catch((e) => {
        throw e;
      });

    return result;
  }

  async getFactorHistogram(factorId: number, nationCode: number) {
    this.getFactorHistogram = this.getFactorHistogram.bind(this);
    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getFactorHistogram}`,
      params: {
        factorId,
        nationCode,
      },
    })
      .then((response) => response.data as FactorHistogram)
      .catch((e) => {
        throw e;
      });

    return result;
  }

  async getNationList() {
    this.getNationList = this.getNationList.bind(this);
    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getNationList}`,
    })
      .then((response) => response.data as Array<Nation>)
      .catch((e) => {
        // console.log(`ERROR ${e}`);
        throw e;
      });

    return result;
  }

  async postScreenerCountResultWithoutKey(body: ExecScreenerCountBody) {
    this.postScreenerCountResultWithoutKey =
      this.postScreenerCountResultWithoutKey.bind(this);
    const result = await fetch({
      method: 'POST',
      uri: `${baseUrl}/${postScreenerCountResultWithoutKey}`,
      body,
    })
      .then((response) => response.data as ResponseBody<ScreenerCountResult>)
      .catch((e) => {
        throw e;
      });
    return result;
  }

  // async getCompaniesByTickers(tickers: Array<string>) {
  //   this.getCompaniesByTickers = this.getCompaniesByTickers.bind(this);

  //   const result = await fetch({
  //     method: 'GET',
  //     uri: `${baseUrl}/${getCompaniesByTickers}`,
  //     params: { tickers: tickers.join(',') },
  //   })
  //     .then((response) => {
  //       return response.data as ResponseBody<ScreenerResult>;
  //     })
  //     .catch((e) => {
  //       // console.log(`ERROR ${e}`);
  //       throw e;
  //     });
  //   return result;
  // }

  async postExecBacktesting(
    body: ExecBackTestingBody,
  ): Promise<ResponseBody<BacktestingRequestStatusResult> | AxiosError> {
    this.postExecBacktesting = this.postExecBacktesting.bind(this);

    const result = await fetch({
      method: 'POST',
      uri: `${baseUrl}/${postExecBacktesting}`,
      body,
    })
      .then(
        (response) =>
          response.data as ResponseBody<BacktestingRequestStatusResult>,
      )
      .catch((e) => {
        throw e;
      });
    return result;
  }

  async postDirectIndexBacktestingExecute(
    body: DirectIndexBacktestExecuteBody,
  ): Promise<ResponseBody<BacktestingRequestStatusResult> | AxiosError> {
    this.postDirectIndexBacktestingExecute =
      this.postDirectIndexBacktestingExecute.bind(this);

    const result = await fetch({
      method: 'POST',
      uri: `${baseUrl}/${postDirectIndexBacktestingExecute}`,
      body,
    })
      .then(
        (response) =>
          response.data as ResponseBody<BacktestingRequestStatusResult>,
      )
      .catch((e) => {
        // console.log(`ERROR ${e}`);
        throw e;
      });
    return result;
  }

  async getBacktestingRequestStatus(requestKey: string) {
    this.getBacktestingRequestStatus =
      this.getBacktestingRequestStatus.bind(this);

    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getBacktestingRequestStatus}`,
      params: {
        requestKey,
      },
    });

    return result.data as ResponseBody<BacktestingRequestStatusResult>;
  }

  async getBacktestingYieldIndexResult(requestKey: string) {
    this.getBacktestingYieldIndexResult =
      this.getBacktestingYieldIndexResult.bind(this);

    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getBacktestingYieldIndexResult}`,
      params: {
        requestKey,
      },
    });

    return result.data as ResponseBody<BacktestingEsResult>;
  }

  async getDIBacktestingYieldIndexResult(requestKey: string) {
    this.getDIBacktestingYieldIndexResult =
      this.getDIBacktestingYieldIndexResult.bind(this);

    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getDIBacktestingYieldIndexResult}`,
      params: {
        requestKey,
      },
    });

    return result.data as ResponseBody<BacktestingYieldIndexResult>;
  }

  async getBacktestingPortfolioResult(requestKey: string) {
    this.getBacktestingPortfolioResult =
      this.getBacktestingPortfolioResult.bind(this);

    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getBacktestingPortfolioResult}`,
      params: {
        requestKey,
      },
    });

    return result.data as ResponseBody<BacktestingPortfolioResult>;
  }

  async getDirectIndexCompanyListFromIndex(
    body: DirectIndexCompanyListFromIndexBody,
  ): Promise<ResponseBody<Array<DirectIndexCompanyListFromIndexResult>>> {
    this.getDirectIndexCompanyListFromIndex =
      this.getDirectIndexCompanyListFromIndex.bind(this);
    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getDirectIndexCompanyListFromIndex}`,
      params: { indexCode: body.indexCode },
    })
      .then((response) => {
        return response.data as ResponseBody<
          Array<DirectIndexCompanyListFromIndexResult>
        >;
      })
      .catch((e) => {
        // console.log(`ERROR ${e}`);
        throw e;
      });
    return result;
  }

  async getDirectIndexCompanyFactorsByIndex(
    indexCode: number,
    factorIds: Array<number>,
  ): Promise<ResponseBody<Array<DirectIndexFactorValues>>> {
    this.getDirectIndexCompanyFactorsByIndex =
      this.getDirectIndexCompanyFactorsByIndex.bind(this);

    const { data } = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getDirectIndexCompanyFactorByIndex}`,
      headers: { 'X-DMM-API': 'PX-683c8b3f165ae79b53d697e7076de788' },
      params: {
        indexCode,
        factorIds,
      },
    });

    return data as ResponseBody<Array<DirectIndexFactorValues>>;
  }

  async getDirectIndexFactorValueDistributionByIndex(
    indexCode: number,
    factorIds: Array<number>,
  ): Promise<ResponseBody<Array<DirectIndexFactorValueDistribution>>> {
    this.getDirectIndexFactorValueDistributionByIndex =
      this.getDirectIndexFactorValueDistributionByIndex.bind(this);

    const { data } = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getDirectIndexFactorDistribution}`,
      headers: { 'X-DMM-API': 'PX-683c8b3f165ae79b53d697e7076de788' },
      params: {
        indexCode,
        factorIds,
      },
    });

    return data as ResponseBody<Array<DirectIndexFactorValueDistribution>>;
  }

  async getDirectIndexIndexList(): Promise<
    ResponseBody<Array<DirectIndexIndex>>
  > {
    this.getDirectIndexIndexList = this.getDirectIndexIndexList.bind(this);

    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getDirectIndexIndexList}`,
    })
      .then((response) => {
        return response.data as ResponseBody<Array<DirectIndexIndex>>;
      })
      .catch((e) => {
        // console.log(`ERROR ${e}`);
        throw e;
      });
    return result;
  }

  async getDirectIndexIndexPriceList(
    params: DirectIndexIndexPriceListRequest,
  ): Promise<ResponseBody<Array<DirectIndexIndexPriceListResponse>>> {
    this.getDirectIndexIndexPriceList =
      this.getDirectIndexIndexPriceList.bind(this);

    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getDirectIndexIndexPriceList}`,
      params,
    })
      .then((response) => {
        return response.data as ResponseBody<
          Array<DirectIndexIndexPriceListResponse>
        >;
      })
      .catch((e) => {
        // console.log(`ERROR ${e}`);
        throw e;
      });
    return result;
  }

  async getDirectIndexIndexSummary(
    indexCode: number,
  ): Promise<ResponseBody<DirectIndexIndexSummary>> {
    this.getDirectIndexIndexSummary =
      this.getDirectIndexIndexSummary.bind(this);

    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getDirectIndexIndexSummary}`,
      params: { indexCode },
    })
      .then((response) => {
        return response.data as ResponseBody<DirectIndexIndexSummary>;
      })
      .catch((e) => {
        // console.log(`ERROR ${e}`);
        throw e;
      });
    return result;
  }

  async getFinancialStatementValues(
    params: FinancialStatementValuesRequest,
  ): Promise<ResponseBody<FinancialStatementValuesResponse>> {
    this.getFinancialStatementValues =
      this.getFinancialStatementValues.bind(this);

    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getFinancialStatementValues}`,
      params,
    })
      .then((response) => {
        return response.data as ResponseBody<FinancialStatementValuesResponse>;
      })
      .catch((e) => {
        // console.log(`ERROR ${e}`);
        throw e;
      });
    return result;
  }

  async getCompanySummaryInfo(
    cosmosCode: number,
  ): Promise<ResponseBody<CompanySummaryInfoResponse>> {
    this.getCompanySummaryInfo = this.getCompanySummaryInfo.bind(this);
    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getCompanySummaryInfo}`,
      params: { cosmosCode },
    })
      .then((response) => {
        return response.data as ResponseBody<CompanySummaryInfoResponse>;
      })
      .catch((e) => {
        // console.log(`ERROR ${e}`);
        throw e;
      });
    return result;
  }

  async getFinancialRatioValues(
    params: CompanyFinancialRatioValuesRequest,
  ): Promise<ResponseBody<CompanyFinancialRatioValuesResponse>> {
    this.getFinancialRatioValues = this.getFinancialRatioValues.bind(this);
    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getFinancialRatioValues}`,
      params,
    })
      .then((response) => {
        return response.data as ResponseBody<CompanyFinancialRatioValuesResponse>;
      })
      .catch((e) => {
        // console.log(`ERROR ${e}`);
        throw e;
      });
    return result;
  }

  async getCompanyMarketPrices(
    params: CompanyMarketPricesRequest,
  ): Promise<ResponseBody<CompanyMarketPricesResponse>> {
    this.getCompanyMarketPrices = this.getCompanyMarketPrices.bind(this);
    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getCompanyMarketPrices}`,
      params,
    })
      .then((response) => {
        return response.data as ResponseBody<CompanyMarketPricesResponse>;
      })
      .catch((e) => {
        // console.log(`ERROR ${e}`);
        throw e;
      });
    return result;
  }

  async getSearchCompanies(
    params: SearchCompanyRequest,
  ): Promise<ResponseBody<SearchCompanyResponse>> {
    this.getSearchCompanies = this.getSearchCompanies.bind(this);

    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getSearchCompanies}`,
      params,
    })
      .then((response) => {
        return response.data as ResponseBody<SearchCompanyResponse>;
      })
      .catch((e) => {
        // console.log(`ERROR ${e}`);
        throw e;
      });
    return result;
  }

  async getCompanyGroupList(
    level: number,
  ): Promise<ResponseBody<Array<CompanyGroupListResponse>> | AxiosError> {
    this.getCompanyGroupList = this.getCompanyGroupList.bind(this);

    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getCompanyGroupList}`,
      params: { level },
    })
      .then((response) => {
        return response.data as ResponseBody<Array<CompanyGroupListResponse>>;
      })
      .catch((e) => {
        // console.log(`ERROR ${e}`);
        throw e;
      });
    return result;
  }

  async getBacktestingRequestByKey(
    requestKey: string,
  ): Promise<ResponseBody<ExecBackTestingBody> | AxiosError> {
    this.getBacktestingRequestByKey =
      this.getBacktestingRequestByKey.bind(this);

    const result = await fetch({
      method: 'GET',
      uri: `${baseUrl}/${getBacktestingRequestByKey}`,
      params: { requestKey },
    })
      .then((response) => {
        return response.data as ResponseBody<ExecBackTestingBody>;
      })
      .catch((e) => {
        // console.log(`ERROR ${e}`);
        throw e;
      });
    return result;
  }

  async postStrategyFactorUnitPerformance(
    id: number,
    body: StrategyFactorUnitPerformanceRequest,
  ): Promise<StrategyFactorUnitPerformanceResponse | string | AxiosError> {
    this.postStrategyFactorUnitPerformance =
      this.postStrategyFactorUnitPerformance.bind(this);

    const result = await fetch({
      method: 'POST',
      uri: `https://api.tailor.im${postStrategyFactorUnitPerformance}`
        // uri: `https://api.tailors.dev${postStrategyFactorUnitPerformance}`
        .replace(':id', id.toString()),
      // uri: 'https://api.tailors.dev/portfolio/1/positioning/sync',
      body,
    })
      .then((response) => {
        return response.data as StrategyFactorUnitPerformanceResponse;
      })
      .catch((e) => {
        // console.log(`ERROR ${e}`);
        throw e;
      });
    return result;
  }
}
