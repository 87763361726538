import { useContext } from 'react';
import { Box, Center, Flex, Spacer } from '@chakra-ui/layout';
import { Redirect, useHistory, useParams } from 'react-router';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { H1, H5 } from '~/components/Typography';
import { AppStateContext } from '../App/context';
import { getThemeData } from '~/utils/theme';
import BackTestResultContainer from '~/container/backtest/BacktestResultContainer';

type ParamTypes = {
  requestKey: string;
};
const Header = () => {
  const history = useHistory();
  const themeData = getThemeData();
  const [t] = useTranslation();
  return (
    <Flex
      style={{
        display: 'sticky',
        overflow: 'scroll',
        width: '100%',
        borderBottom: 'solid 1px #e5e5e5',
        alignContent: 'center',
      }}
      padding={['12px 24px', '24px 24px']}
    >
      <Flex flexDirection="column">
        <H1 bold>{t('text.backtestResult')}</H1>
      </Flex>
      <Spacer />
      <Button
        colorScheme="primary"
        borderRadius="full"
        size="xl"
        variant="outline"
        onClick={() => {
          if (history.action === 'POP') {
            history.replace('/backtest');
          } else if (
            history.action === 'PUSH' ||
            history.action === 'REPLACE'
          ) {
            history.goBack();
          }
        }}
      >
        <Center flexDirection="row" display="flex" p="16px 32px">
          <H5
            whiteSpace="break-spaces"
            bold
            color={themeData.colors.primary[500]}
          >
            {t('text.backButtonTitle')}
          </H5>
        </Center>
      </Button>
    </Flex>
  );
};

const BacktestResultRoute = () => {
  const { requestKey } = useParams<ParamTypes>();
  const appState = useContext(AppStateContext);
  if (!requestKey || !appState || !appState?.repository)
    return <Redirect to="/backtest" />;

  const themeData = getThemeData();
  return (
    <Box
      direction="column"
      w="100%"
      h={['-webkit-fill-available', '100%']}
      overflow="hidden"
    >
      <Header />
      <Box
        h={['calc(100vh - 140px)', 'calc(100vh - 160px)']}
        bg={themeData.colors.gray[100]}
      >
        <BackTestResultContainer
          repository={appState.repository}
          requestKey={requestKey}
        />
      </Box>
    </Box>
  );
};
export default BacktestResultRoute;
