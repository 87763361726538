import { Box, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { H5 } from '~/components/Typography';
import { iconLogoImage } from '~/constants/assets';

const RequiredLoginPopupContent = () => {
  const [t] = useTranslation();
  return (
    <Box align="center" alignItems="center">
      <Image src={iconLogoImage} marginBottom="16px" />
      <H5 align="center" whiteSpace="break-spaces" noOfLines={2}>
        {t('strategy.requiredLogin')}
      </H5>
    </Box>
  );
};
export default RequiredLoginPopupContent;
