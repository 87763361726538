import { Box, Button, Center, Flex } from '@chakra-ui/react';
import { useContext, useMemo, useState } from 'react';
import { VegaLite } from 'react-vega';
import { getAuth, signInWithPopup } from 'firebase/auth';
import { useAmplitude } from 'react-amplitude-hooks';
import { iconRelativeTooltipDesc } from '~/constants/assets';
import useFetch from '~/hooks/useFetch';
import { StrategyFactorUnitPerformanceResponse } from '~/models/directIndex';
import { AppStateContext } from '~/routes/App/context';
import { FactorStateContext } from '~/routes/App/context/factor_context';
import { BackTestStateContext } from '~/routes/backtest/context';
import { getFormatedDate } from '~/utils/datetime';
import { Body2 } from '~/components/Typography';
import { provider } from '~/utils/firebase';
import { AuthDispatchContext } from '~/routes/App/context/auth_context';
import { getThemeData } from '~/utils/theme';

const colorScale = ['#660005', '#B30015', '#FF0032', '#FF6678', '#FFCCCE'];
const getColorIndex = (value: number) => {
  if (value <= -0.25) {
    return 0;
  }
  if (value < -0.1) {
    return 1;
  }
  if (value < 0.1) {
    return 2;
  }
  if (value < 0.25) {
    return 3;
  }
  return 4;
};

function g(value: any[], fn: (n: any) => number) {
  return Math.max(...value.map(fn));
}

function kn(k: string) {
  return (n: any) => Math.abs(Number(n[k]));
}

function makeGraphData(
  companySummaryInfoResponseData: StrategyFactorUnitPerformanceResponse,
) {
  return (companySummaryInfoResponseData?.performances ?? []).map((v) => {
    return {
      period: `${companySummaryInfoResponseData.startDate} ~ ${companySummaryInfoResponseData.endDate}`,
      range: 100 - (v.range[0] + v.range[1]) / 2,
      zero: 0,
      diffAnnualYield: (v.diffAnnualYield * 100).toFixed(2),
      diffMdd: (v.diffMinMdd * 100).toFixed(2),
      diffSharpe: (v.diffAnnualSharpe ?? 0).toFixed(2),
      c: colorScale[getColorIndex(v.diffMinMdd)],
      image: iconRelativeTooltipDesc,
    };
  });
}

function getVegaLite(
  values: {
    zero: number;
    diffMdd: string;
    image: any;
    period: string;
    diffAnnualYield: string;
    c: string;
    range: number;
    diffSharpe: string;
  }[],
  yieldMax: number,
  mddMax: number,
  opacity: number,
) {
  return (
    <VegaLite
      style={{ width: '100%', opacity }}
      actions={false}
      spec={{
        $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
        width: 'container',
        height: 50,
        autosize: 'fit',
        data: {
          values,
        },
        encoding: {
          x: { field: 'range' },
          tooltip: [
            {
              field: 'period',
              type: 'nominal',
              title: '기간',
            },

            { field: 'image' },
            {
              field: 'diffAnnualYield',
              type: 'nominal',
              title: '수익률차',
            },
            {
              field: 'diffMdd',
              type: 'nominal',
              title: 'MDD차',
            },
            {
              field: 'diffSharpe',
              type: 'nominal',
              title: '샤프지수차',
            },
          ],
        },

        layer: [
          {
            mark: 'line',
            encoding: {
              y: { field: 'zero', type: 'quantitative' },
              color: { value: '#e0e0e0' },
            },
          },
          {
            mark: { type: 'bar', width: 20 },
            encoding: {
              x: {
                field: 'range',
                type: 'temporal',
                scale: { domainMin: 0, domainMax: 100 },
              },
              y: {
                field: 'diffAnnualYield',
                scale: { domain: [yieldMax * -1, yieldMax] },
                type: 'quantitative',
              },
              color: {
                condition: {
                  test: 'datum.diffAnnualYield > 0',
                  value: '#9DE3BC',
                },
                value: '#BDBDBD',
              },
            },
          },
          {
            mark: 'rule',
            params: [
              {
                name: 'hover',
                select: { type: 'point', on: 'mouseover', nearest: true },
              },
            ],
            encoding: {
              color: {
                condition: {
                  param: 'hover',
                  empty: false,
                  value: '#aaaaaa',
                },
                value: 'transparent',
              },
            },
          },
          {
            mark: 'line',
            encoding: {
              x: { field: 'range', type: 'temporal' },
              y: {
                field: 'diffMdd',
                scale: { domain: [mddMax * -1, mddMax] },
                type: 'quantitative',
              },
              color: { value: '#FF9393' },
            },
          },
        ],
        resolve: { scale: { y: 'independent' } },
        config: {
          style: {
            cell: {
              stroke: 'transparent',
            },
          },

          padding: 0,
          axisX: {
            title: null,
            labels: false,
            grid: false,
            ticks: false,
            domain: false,
          },
          axisY: {
            title: null,
            labels: false,
            grid: false,
            ticks: false,
            domain: false,
          },
        },
      }}
    />
  );
}

const RelativeFactorHistogramChart = ({
  factorId,
  signInWord,
}: {
  factorId: number;
  signInWord: string;
}) => {
  const appState = useContext(AppStateContext);
  const backTestState = useContext(BackTestStateContext);
  const factorState = useContext(FactorStateContext);
  const authDispatch = useContext(AuthDispatchContext);
  const themeData = getThemeData();
  const { logEvent } = useAmplitude();

  const categoryIds = useMemo(() => {
    const selectGroups = Array.from(
      factorState?.companyGroupList.entries() ?? [],
    )
      .map(([key, value]) => {
        if (value.length === 0) {
          return [];
        }
        if (value.length === 1) {
          return [key];
        }
        return value;
      })
      .flatMap((v) => v)
      .filter((v) => !factorState?.unselectedGroups.has(v.cosmosGroupId));

    return selectGroups.map((v) => v.cosmosGroupId);
  }, [factorState?.companyGroupList, factorState?.unselectedGroups]);

  const [tryCnt, setTryCnt] = useState<number>(0);
  const [companySummaryInfoResponse] = useFetch<
    StrategyFactorUnitPerformanceResponse | string | null
  >(() => {
    // companyGroupList가 초기화되지 않은 케이스. 요청하지 않음
    if ((factorState?.companyGroupList.size ?? 0) < 1) {
      return null;
    }
    return appState?.repository.postStrategyFactorUnitPerformance(factorId, {
      nationCode: factorState?.selectedNation.code ?? 840,
      categoryIds,
      startDate: backTestState?.startDate
        ? getFormatedDate({ date: backTestState.startDate })
        : undefined,
      endDate: backTestState?.endDate
        ? getFormatedDate({ date: backTestState.endDate })
        : undefined,
      rebalancingPeriod: backTestState?.rebalancingPeriod,
      count: backTestState?.top ?? 30,
      tradeCost: backTestState?.tradeCost ?? 0.6,
    });
  }, [
    categoryIds,
    factorState?.selectedNation.code,
    backTestState?.startDate,
    backTestState?.endDate,
    backTestState?.rebalancingPeriod,
    backTestState?.top,
    backTestState?.tradeCost,
    tryCnt,
  ]);

  // 요청하지 않은 케이스에 대해 sign up 권유
  if (!companySummaryInfoResponse?.data) {
    const rowData = {
      startDate: '2005-01',
      endDate: '2022-10',
      performances: [
        {
          range: [0, 10],
          diffAnnualYield: 0.094500896,
          diffMinMdd: 0.12538749,
          diffAnnualSharpe: 0.55039994,
        },
        {
          range: [10, 20],
          diffAnnualYield: 0.07226121600000002,
          diffMinMdd: 0.07449465999999999,
          diffAnnualSharpe: 0.41820345999999997,
        },
        {
          range: [20, 30],
          diffAnnualYield: 0.035792826,
          diffMinMdd: 0.07231056999999996,
          diffAnnualSharpe: 0.20093815000000004,
        },
        {
          range: [30, 40],
          diffAnnualYield: 0.06193232600000001,
          diffMinMdd: 0.018080890000000016,
          diffAnnualSharpe: 0.3259672,
        },
        {
          range: [40, 50],
          diffAnnualYield: 0.02986145100000001,
          diffMinMdd: -0.014198470000000074,
          diffAnnualSharpe: 0.11038689999999995,
        },
        {
          range: [50, 60],
          diffAnnualYield: 0.04464340600000001,
          diffMinMdd: 0.05857042999999995,
          diffAnnualSharpe: 0.13766539999999994,
        },
        {
          range: [60, 70],
          diffAnnualYield: 0.02774691600000001,
          diffMinMdd: 0.06456839999999997,
          diffAnnualSharpe: 0.06609808,
        },
        {
          range: [70, 80],
          diffAnnualYield: 0.032143951000000004,
          diffMinMdd: -0.024728670000000008,
          diffAnnualSharpe: 0.055120100000000005,
        },
        {
          range: [80, 90],
          diffAnnualYield: 0.058047056,
          diffMinMdd: 0.004074429999999962,
          diffAnnualSharpe: 0.18670719999999996,
        },
        {
          range: [90, 100],
          diffAnnualYield: 0.025552036,
          diffMinMdd: -0.0066190400000000205,
          diffAnnualSharpe: 0.040609459999999986,
        },
      ],
    };
    const s = rowData as StrategyFactorUnitPerformanceResponse;
    const values = makeGraphData(s);

    const yieldMax = g(values, kn('diffAnnualYield'));
    const mddMax = g(values, kn('diffMdd'));

    return (
      <Center marginBottom={['0px', '12px']} position="relative">
        {getVegaLite(values, yieldMax, mddMax, 0.33)}
        <Button
          position="absolute"
          size="sm"
          variant="outline"
          backgroundColor="rgba(225,225,225,0.40)"
          onClick={() => {
            logEvent('sign up', {
              'sign up path': 'relative factor histogram chart',
            });
            signInWithPopup(getAuth(), provider).then((credential) => {
              if (authDispatch) {
                authDispatch({ type: 'LOGIN', user: credential.user });
              }
            });
          }}
        >
          <Flex flexDirection={['row', 'column']} alignItems="center">
            <Box
              marginLeft={['4px', '0px']}
              whiteSpace={['inherit', 'break-spaces']}
            >
              <Body2
                whiteSpace="inherit"
                color={themeData.colors.gray[400]}
                align="center"
              >
                {signInWord}
              </Body2>
            </Box>
          </Flex>
        </Button>
      </Center>
    );
  }
  if (typeof companySummaryInfoResponse?.data === 'string') {
    if (tryCnt < 9) {
      setTimeout(() => {
        setTryCnt(tryCnt + 1);
      }, 3000);
    }
    return <Box h="50px" w="100%" />;
  }

  const companySummaryInfoResponseData = companySummaryInfoResponse.data;

  const values = makeGraphData(companySummaryInfoResponseData);

  const yieldMax = g(values, kn('diffAnnualYield'));
  const mddMax = g(values, kn('diffMdd'));
  return getVegaLite(values, yieldMax, mddMax, 1.0);
};

export default RelativeFactorHistogramChart;
