export const setToLS = (key: string, value: string) => {
  window.localStorage.setItem(key, value);
};

export const getFromLS = (key: any, defaultValue?: string): string => {
  const value = window.localStorage.getItem(key);
  if (value) {
    return value;
  }
  return defaultValue ?? '';
};

export const removeFromLS = (key: string) => {
  window.localStorage.removeItem(key);
};
