import { useState } from 'react';
import { Box, Flex, Spacer } from '@chakra-ui/layout';
import { useTranslation } from 'react-i18next';
import Creatable from 'react-select/creatable';
import { useAmplitude } from 'react-amplitude-hooks';
import { OnChangeValue } from 'react-select';

import { H1, H2 } from '~/components/Typography';
// import MappedTable from '~/components/MappedTable';
import { getFromLS } from '~/utils/localStorage';
import Drawer from '~/container/shared/Drawer';
import { addCriticalActionsCountAmplitude } from '~/utils/logger';

// type AttentionTableProps = {
//   tickers: readonly Option[];
// };
const AttentionTable = () => {
  return <div />;
  // if (tickers.length === 0) return <div />;
  // const { amplitudeInstance } = useAmplitude();
  // const appState = useContext(AppStateContext);

  // const [resultState] = useFetch<ResponseBody<ScreenerResult>>(() => {
  //   amplitudeInstance?.setUserProperties({
  //     'num total of watchlist stocks': tickers.length,
  //   });
  //   setToLS('ATTENTION_OPTIONS', JSON.stringify(tickers));

  //   if (!appState?.repository) return <Text>ERROR </Text>;

  //   return appState?.repository.getCompaniesByTickers(
  //     tickers.map((v) => v.label),
  //   );
  // }, [tickers]);

  // if (resultState.error) {
  //   return <Text> ERROR </Text>;
  // }

  // return (
  //   <Box
  //     p={['24px 12px', '24px 48px']}
  //     w={['none', 'calc(100vw - 118px)']}
  //     h="calc(100vh - 180px)"
  //   >
  //     {resultState.loading ||
  //     !resultState?.data?.result?.companyFactorInfoList ||
  //     resultState?.data?.result?.companyFactorInfoList?.length === 0 ? (
  //       <Skeleton w="100%" h="100%" />
  //     ) : (
  //       <div />
  //     )}
  //   </Box>
  // );
};
const Header = () => {
  const [t] = useTranslation();
  return (
    <Flex
      style={{
        position: 'sticky',
        borderBottom: 'solid 1px #e5e5e5',
      }}
      height={['64px', '122px']}
      bg="white"
      alignItems="flex-end"
      padding="0px 24px"
    >
      <Flex flexDirection="column" alignSelf="center">
        <Box display={['inherit', 'none']}>
          <H2 bold>{t('text.attention')}</H2>
        </Box>
        <Box display={['none', 'inherit']}>
          <H1 bold>{t('text.attention')}</H1>
        </Box>
      </Flex>
      <Spacer />
      <Box display={['inherit', 'none']} alignSelf="center">
        <Drawer />
      </Box>
    </Flex>
  );
};
interface Option {
  readonly label: string;
  readonly value: string;
}

const AttentionRoute = () => {
  const { amplitudeInstance } = useAmplitude();

  const [value, setValue] = useState<readonly Option[]>(
    JSON.parse(getFromLS('ATTENTION_OPTIONS', '[]')) as readonly Option[],
  );

  const handleChange = (newValue: OnChangeValue<Option, true>) => {
    setValue(newValue);
    addCriticalActionsCountAmplitude({ amplitudeInstance });
    // setTickers([...tickers, newValue[0].label]);
  };

  return (
    <Box direction="column" w="100%" h="100%">
      <Header />
      <Box m="48px 48px">
        <Creatable
          isMulti
          isClearable
          onChange={handleChange}
          options={[]}
          value={value}
        />
      </Box>

      <AttentionTable />
    </Box>
  );
};
/*
function getRowDataFromScreenrResult(
  screenerResult: ScreenerResult,
): Array<Record<string, any>> {
  if (!screenerResult) return [];
  return screenerResult.companyWithFactorsList.map((result) => {
    return {
      company: result.companyName,
      ticker: result.ticker,
      ...getMapFromRecord(result.factorValueList),
    };
  });
}

function getColumn(factors: Factor[]): Array<Column> {
  return [
    // @ts-ignore
    { Header: 'company', accessor: 'company', sticky: 'left' },
    { Header: 'ticker', accessor: 'ticker' },
    ...factors.map((factor, i) => {
      return {
        // @ts-ignore
        Header: factor?.id,
        accessor: i.toString(),
      };
    }),
  ];
}
function getMapFromRecord(valueList: Array<number>): Record<string, string> {
  const r: Record<string, string> = {};

  valueList.forEach((v, i) => {
    if (!v) return;
    r[i.toString()] =
      v % 1 === 0
        ? v.toString()
        : v.toLocaleString(undefined, { maximumFractionDigits: 2 });
  });
  return r;
}
*/

export default AttentionRoute;
