import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Flex,
  HStack,
  Grid,
  GridItem,
  Spacer,
  Button,
  Image,
  Center,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Box,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderMark,
} from '@chakra-ui/slider';
import { useTranslation } from 'react-i18next';
import { VegaLite } from 'react-vega';
import { useAmplitude } from 'react-amplitude-hooks';

import {
  FactorHistogram,
  FactorHistogramDefaultRange,
} from '~/models/factorHistogram';
import { getThemeData } from '~/utils/theme';
import { Body1, Body2, H5 } from '../Typography';
import { getFromLS, setToLS } from '~/utils/localStorage';
import {
  FactorDispatchContext,
  FactorStateContext,
} from '~/routes/App/context/factor_context';
import { iconDelete } from '~/constants/assets';
import { BorderButton } from '../CustomButton';
import { useDidUpdateEffect } from '~/hooks/useDidUpdateEffect';
import NumberEditableInput from '../NumberEditableInput';
import { AppStateContext } from '~/routes/App/context';
import RelativeFactorHistogramChart from './RelativeFactorHistogramChart';

type FactorControlProps = {
  // range: [number, number];z
  // onUpdateRange: () => void;
  factorId: number;
  factorHistogram: FactorHistogram;
  onUpdateRange: (range: [FactorControlValue, FactorControlValue]) => void;
  value: [FactorControlValue, FactorControlValue];
};

export type FactorControlValue = {
  value: number | 'min' | 'max';
  freqIndex: number;
  isAbsolute: boolean;
};

type ChartControllerProps = {
  factorId: number;
  bins: Array<number>;
  freqs: Array<number>;
  range: [FactorControlValue, FactorControlValue];
  updateByInput: [boolean, boolean];
  setUpdateByInput: (value: [boolean, boolean]) => void;
  onUpdateRange: (
    range: [FactorControlValue | null, FactorControlValue | null],
  ) => void;
  isAbsolute: boolean;
  signInWord: string;
};

const ChartController = ({
  factorId,
  bins,
  freqs,
  range,
  onUpdateRange,
  setUpdateByInput,
  updateByInput,
  isAbsolute,
  signInWord,
}: ChartControllerProps) => {
  const getFactorControlValueByFreqIndex = (
    freqIndex: number,
    isAbsolute: boolean,
  ): FactorControlValue => {
    if (isAbsolute) {
      if (freqIndex === 0) {
        return { value: 'min', freqIndex, isAbsolute };
      }
      if (freqIndex === bins.length) {
        return { value: 'max', freqIndex, isAbsolute };
      }

      return { value: bins[freqIndex - 1], freqIndex, isAbsolute };
    }
    return { value: freqIndex, freqIndex, isAbsolute };
  };

  // const getRangeFromArray = (value: Array<number>): [number, number] => {
  //   const start = value[0];
  //   const last = value[value.length - 1];
  //   return [start, last + 1];
  // };
  const themeData = getThemeData();
  const [t] = useTranslation();
  const selectedColor = themeData.colors.primary[300];
  const unSelectedColor = '#EDEDED';

  const values = [
    {
      x: 0,
      y: 100,
      v: 0,
      c: 'white',
    },
    ...freqs.map((number, i) => {
      return {
        x: i + 1,
        y: number,
        v: getFactorControlValueByFreqIndex(i, isAbsolute),
        c:
          i + 1 >= range[0].freqIndex && i <= range[1].freqIndex - 2
            ? selectedColor
            : unSelectedColor,
      };
    }),
  ];

  const chart = useMemo(() => {
    if (isAbsolute) {
      return (
        <VegaLite
          style={{ width: '100%' }}
          actions={false}
          spec={{
            $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
            width: 'container',
            height: 50,
            autosize: 'fit',
            data: {
              values,
            },
            layer: [
              {
                mark: {
                  type: 'bar',
                  strokeWidth: 0,
                },
                encoding: {
                  x: { field: 'x', scale: { paddingInner: 0 } },
                  y: { field: 'y', type: 'quantitative' },
                  color: { field: 'c', type: 'nominal', scale: null },
                  tooltip: [
                    {
                      field: 'y',
                      type: 'nominal',
                      title: t('text.result_count'),
                    },
                  ],
                },
              },
            ],
            config: {
              style: {
                cell: {
                  stroke: 'transparent',
                },
              },
              padding: 0,
              axisX: {
                title: null,
                labels: false,
                grid: false,
                ticks: false,
                domain: false,
              },
              axisY: {
                title: null,
                labels: false,
                grid: false,
                ticks: false,
                domain: false,
              },
            },
          }}
        />
      );
    }

    return (
      <RelativeFactorHistogramChart
        key={`relative-${factorId}`}
        factorId={factorId}
        signInWord={signInWord}
      />
    );
  }, [isAbsolute, factorId]);

  return (
    <Flex flexDirection="column">
      {chart}

      <RangeSlider
        marginBottom="8px"
        marginTop={-1}
        onFocus={(v) => {
          if (v.target.title === 'right') {
            setUpdateByInput([updateByInput[0], false]);
          } else if (v.target.title === 'left') {
            setUpdateByInput([false, updateByInput[1]]);
          }
        }}
        onChange={(v) => {
          onUpdateRange([
            updateByInput[0]
              ? null
              : getFactorControlValueByFreqIndex(v[0], isAbsolute),
            updateByInput[1]
              ? null
              : getFactorControlValueByFreqIndex(v[1], isAbsolute),
          ]);
        }}
        min={0}
        max={isAbsolute ? bins.length : 100}
        step={isAbsolute ? 1 : 10}
        value={range.map((e) =>
          isAbsolute ? e.freqIndex : (e.value as number),
        )}
        // defaultValue={[0, 10]}
        colorScheme="primary"
        color={themeData.colors.primary[900]}
        minStepsBetweenThumbs={1}
      >
        {!isAbsolute ? (
          <>
            {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((v) => (
              <RangeSliderMark
                value={v}
                mt="-2.5"
                ml="-0.5"
                fontSize="sm"
                color={
                  v % 50 === 0
                    ? themeData.colors.gray[400]
                    : themeData.colors.gray[200]
                }
              >
                |
              </RangeSliderMark>
            ))}
          </>
        ) : (
          <div />
        )}
        <RangeSliderTrack>
          <RangeSliderFilledTrack />
        </RangeSliderTrack>
        <RangeSliderThumb
          index={0}
          title="left"
          boxSize={5}
          zIndex="auto"
          borderRadius={5}
          borderWidth={2}
          bg={themeData.colors.primary[500]}
          borderColor={themeData.colors.primary[900]}
        />
        <RangeSliderThumb
          index={1}
          title="right"
          zIndex="auto"
          boxSize={5}
          borderRadius={5}
          borderWidth={2}
          bg={isAbsolute ? 'white' : themeData.colors.primary[500]}
          borderColor={themeData.colors.primary[900]}
        />
      </RangeSlider>
    </Flex>
  );
};

type FocusType = 'left' | 'right';

const FactorControl = ({
  factorId,
  factorHistogram,
  onUpdateRange,
  value,
}: FactorControlProps) => {
  const getIndexByValue = (_value: number): number => {
    let index = factorHistogram.bins.findIndex((e) => e > _value);

    if (index < 0 && _value < factorHistogram.bins[0]) {
      index = 0;
    } else if (
      index < 0 &&
      _value >= factorHistogram.bins[factorHistogram.bins.length - 1]
    ) {
      index = factorHistogram.bins.length - 1;
    }
    return index;
  };
  const factorContext = useContext(FactorStateContext);
  const appState = useContext(AppStateContext);
  const isMobile = useBreakpointValue([true, false]);

  const themeData = getThemeData();
  const factorRangeString = getFromLS(
    `FACTOR_RANGE_VALUE_${factorHistogram.factorId}`,
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [focus, setFocus] = useState<FocusType>();
  // const [isAbsolute, setIsAbsolute] = useState<boolean>(
  //   factorHistogram.defaultRange === 'BOTTOM20POSITIVE'
  //     ? true
  //     : factorContext?.selectedFactors.get(factorHistogram.factorId)?.[0]
  //         .isAbsolute ?? true,
  // );
  const factor = factorContext?.selectedFactors.get(factorHistogram.factorId);
  const isAbsolute =
    factorHistogram.defaultRange === 'BOTTOM20POSITIVE'
      ? true
      : factor?.[0].isAbsolute ?? false;
  const initialRange: [FactorControlValue, FactorControlValue] =
    /* eslint-disable-next-line no-nested-ternary */
    factor?.[0].freqIndex !== -1 || factor?.[1].freqIndex !== -1
      ? factor
      : /* eslint-disable-next-line no-nested-ternary */
      factorRangeString !== ''
      ? JSON.parse(factorRangeString)
      : factorHistogram.defaultRange === 'BOTTOM20POSITIVE'
      ? getAbsoluteInitialRange(factorHistogram, factorHistogram.defaultRange)
      : getReletiveInitialRange(factorHistogram, factorHistogram.defaultRange);

  const [updateByInput, setUpdateByInput] = useState<[boolean, boolean]>([
    true,
    true,
  ]);
  const [range, setRange] =
    useState<[FactorControlValue, FactorControlValue]>(initialRange);
  const factorDispatch = useContext(FactorDispatchContext);
  const [t] = useTranslation();
  const { logEvent } = useAmplitude();

  useEffect(() => {
    const update1 = setTimeout(() => {
      setToLS(
        `FACTOR_RANGE_VALUE_${factorHistogram.factorId}`,
        JSON.stringify(range),
      );
      onUpdateRange([range[0], range[1]]);
    }, 300);
    return () => clearTimeout(update1);
  }, [range]);

  useDidUpdateEffect(() => {
    if (factorDispatch) {
      factorDispatch({
        type: 'UPDATE_IS_EDIT_CURRENT_CONDITIONS',
        isEditCurrentConditions: true,
      });
    }
    const update2 = setTimeout(() => {
      logEvent('factor value adjusted', {
        'factor category': factorHistogram.categoryName,
      });
    }, 300);
    return () => clearTimeout(update2);
  }, [range]);

  useDidUpdateEffect(() => {
    if (range[0] !== value[0] || range[1] !== value[1]) setRange(value);
  }, [value]);
  return useMemo(() => {
    const left = range[0].value;
    const right = range[1].value;
    const signInWords = [
      '10분위가 궁금하면 지금 바로 가입하세요',
      '가입하고 인사이트 얻기',
    ];
    const random = Math.floor(Math.random() * signInWords.length);
    const signInWord = signInWords[random];

    return (
      <Flex
        flexDirection="column"
        p="32px 0px"
        m="0px 24px"
        marginBottom="24px"
        borderBottom="solid 1px #e5e5e5"
      >
        <Flex>
          <H5 whiteSpace="break-spaces" bold>
            {/* @ts-ignore */}
            {t(`factor.${factorHistogram.factorId}.name`)}
          </H5>
          <H5 whiteSpace="break-spaces" color={themeData.colors.text2}>
            {/* @ts-ignore */}
            {` | ${t(`factor_category.${factorHistogram.categoryName}.name`)}`}
          </H5>
          <Spacer />
          <Button
            variant="unstyled"
            onClick={() => {
              if (factorDispatch != null)
                factorDispatch({
                  type: 'REMOVE_FACTOR',
                  factorId: factorHistogram.factorId,
                });
            }}
          >
            <Center>
              <Image src={iconDelete} />
            </Center>
          </Button>
        </Flex>
        {factorHistogram.defaultRange === 'BOTTOM20POSITIVE' ? (
          <div />
        ) : (
          <HStack spacing="0px">
            <Button
              m="0px"
              borderRightRadius="0px"
              colorScheme={isAbsolute ? 'gray' : 'primary'}
              color={
                isAbsolute
                  ? themeData.colors.gray[300]
                  : themeData.colors.primary[500]
              }
              variant="outline"
              onClick={() => {
                if (!isAbsolute) return;
                setRange(
                  getReletiveInitialRange(
                    factorHistogram,
                    factorHistogram.defaultRange,
                  ),
                );
              }}
            >
              {t('text.percentileAdjust')}
            </Button>
            <Button
              m="0px"
              borderLeftRadius="0px"
              colorScheme={!isAbsolute ? 'gray' : 'primary'}
              color={
                !isAbsolute
                  ? themeData.colors.gray[300]
                  : themeData.colors.primary[500]
              }
              variant="outline"
              onClick={() => {
                if (isAbsolute) return;
                setRange(
                  getAbsoluteInitialRange(
                    factorHistogram,
                    factorHistogram.defaultRange,
                  ),
                );
              }}
            >
              {t('text.valueAdjust')}
            </Button>
          </HStack>
        )}
        {isMobile ? (
          <Grid
            marginTop="24px"
            templateColumns="repeat(3, 1fr)"
            p="0px 12px"
            alignItems="end"
            display={['grid', 'none']}
          >
            <GridItem
              colSpan={1}
              justifySelf="center"
              w="100%"
              backgroundColor={
                !isAbsolute ? themeData.colors.gray[100] : 'white'
              }
              border={`solid 1px ${themeData.colors.gray[200]}`}
              borderRadius="4px"
              _hover={{ cursor: 'pointer' }}
              onClick={() => {
                if (!isAbsolute) return;
                setFocus('left');
                onOpen();
              }}
            >
              <Center>
                <Body2 whiteSpace="nowrap" color={themeData.colors.text2}>
                  {left === 'min'
                    ? 'MIN'
                    : `${((left ?? 0) as number).toLocaleString()}${
                        isAbsolute ? factorHistogram.valueUnit : '%'
                      }`}
                </Body2>
              </Center>
            </GridItem>
            <GridItem colSpan={1} justifySelf="center">
              <Body2 whiteSpace="nowrap">-</Body2>
            </GridItem>
            <GridItem
              colSpan={1}
              justifySelf="center"
              w="100%"
              border={`solid 1px ${themeData.colors.gray[200]}`}
              borderRadius="4px"
              _hover={{ cursor: 'pointer' }}
              backgroundColor={
                !isAbsolute ? themeData.colors.gray[100] : 'white'
              }
              onClick={() => {
                if (!isAbsolute) return;
                setFocus('right');
                onOpen();
              }}
            >
              <Center>
                <Body2 whiteSpace="nowrap" color={themeData.colors.text2}>
                  {right === 'max'
                    ? 'MAX'
                    : `${((right ?? 1) as number).toLocaleString()}${
                        isAbsolute ? factorHistogram.valueUnit : '%'
                      }`}
                </Body2>
              </Center>
            </GridItem>
            <GridItem colSpan={3} padding="0px 20px">
              <ChartController
                key={`factor-controller-${factorId}`}
                range={range}
                // initialRange={
                //   factorHistogram.defaultRange === 'BOTTOM20POSITIVE'
                //     ? getAbsoluteInitialRange(
                //         factorHistogram,
                //         factorHistogram.defaultRange,
                //       )
                //     : getReletiveInitialRange(
                //         factorHistogram,
                //         factorHistogram.defaultRange,
                //       )
                // }
                factorId={factorId}
                isAbsolute={isAbsolute}
                setUpdateByInput={setUpdateByInput}
                updateByInput={updateByInput}
                bins={factorHistogram.bins}
                freqs={factorHistogram.freqs}
                onUpdateRange={(v) =>
                  setRange([v[0] ?? range[0], v[1] ?? range[1]])
                }
                // factorHistogramDefaultRange={factorHistogram.defaultRange}
                signInWord={signInWord}
              />
            </GridItem>
          </Grid>
        ) : (
          <Grid
            marginTop="24px"
            templateColumns="repeat(5, 1fr)"
            p="0px 12px"
            alignItems="end"
            display={['none', 'grid']}
          >
            <GridItem
              colSpan={1}
              w="100%"
              backgroundColor={
                !isAbsolute ? themeData.colors.gray[100] : 'white'
              }
              border={`solid 1px ${themeData.colors.gray[200]}`}
              borderRadius="4px"
              _hover={{ cursor: 'pointer' }}
              onClick={() => {
                if (!isAbsolute) return;
                setFocus('left');
                onOpen();
              }}
              justifySelf="center"
            >
              <Center padding="8px 0px">
                <Body2 whiteSpace="nowrap" color={themeData.colors.text2}>
                  {left === 'min'
                    ? 'MIN'
                    : `${((left ?? 0) as number).toLocaleString()}${
                        isAbsolute ? factorHistogram.valueUnit : '%'
                      }`}
                </Body2>
              </Center>
            </GridItem>
            <GridItem colSpan={3} padding="0px 20px">
              <ChartController
                key={`factor-controller-${factorId}`}
                range={range}
                isAbsolute={isAbsolute}
                // initialRange={
                //   factorHistogram.defaultRange === 'BOTTOM20POSITIVE'
                //     ? getAbsoluteInitialRange(
                //         factorHistogram,
                //         factorHistogram.defaultRange,
                //       )
                //     : getReletiveInitialRange(
                //         factorHistogram,
                //         factorHistogram.defaultRange,
                //       )
                // }
                setUpdateByInput={setUpdateByInput}
                updateByInput={updateByInput}
                bins={factorHistogram.bins}
                freqs={factorHistogram.freqs}
                factorId={factorId}
                onUpdateRange={(v) =>
                  setRange([v[0] ?? range[0], v[1] ?? range[1]])
                }
                // factorHistogramDefaultRange={factorHistogram.defaultRange}
                signInWord={signInWord}
              />
            </GridItem>

            <GridItem
              colSpan={1}
              justifySelf="center"
              w="100%"
              border={`solid 1px ${themeData.colors.gray[200]}`}
              backgroundColor={
                !isAbsolute ? themeData.colors.gray[100] : 'white'
              }
              borderRadius="4px"
              _hover={{ cursor: 'pointer' }}
              onClick={() => {
                if (!isAbsolute) return;

                setFocus('right');
                onOpen();
              }}
            >
              <Center padding="8px 0px">
                <Body2 whiteSpace="nowrap" color={themeData.colors.text2}>
                  {right === 'max'
                    ? 'MAX'
                    : `${((right ?? 1) as number).toLocaleString()}${
                        isAbsolute ? factorHistogram.valueUnit : '%'
                      }`}
                </Body2>
              </Center>
            </GridItem>
          </Grid>
        )}

        {isAbsolute && factorHistogram.defaultRange === 'BOTTOM20POSITIVE' ? (
          <HStack justify="center" marginTop="12px">
            <BorderButton
              color={themeData.colors.gray[300]}
              onClick={() => {
                if (factorHistogram.defaultRange === 'BOTTOM20POSITIVE') {
                  setRange(
                    getAbsoluteInitialRange(
                      factorHistogram,
                      'BOTTOM20POSITIVE',
                    ),
                  );
                } else {
                  setRange(
                    getAbsoluteInitialRange(factorHistogram, 'BOTTOM20'),
                  );
                }
              }}
              text={
                factorHistogram.defaultRange === 'BOTTOM20POSITIVE'
                  ? t('text.BOTTOM20POSITIVE')
                  : t('text.BOTTOM20')
              }
            />

            <BorderButton
              color={themeData.colors.gray[300]}
              onClick={() => {
                setRange(getAbsoluteInitialRange(factorHistogram, 'MIDDLE20'));
              }}
              text={t('text.MIDDLE20')}
            />

            <BorderButton
              color={themeData.colors.gray[300]}
              onClick={() => {
                setRange(getAbsoluteInitialRange(factorHistogram, 'TOP20'));
              }}
              text={t('text.TOP20')}
            />
          </HStack>
        ) : (
          <div />
        )}

        {!isAbsolute && factorHistogram.defaultRange !== 'BOTTOM20POSITIVE' ? (
          <HStack justify="center" marginTop="12px">
            <BorderButton
              color={
                range[0].value === 0 && range[1].value === 10
                  ? themeData.colors.primary[500]
                  : themeData.colors.gray[300]
              }
              onClick={() => {
                setRange([
                  { freqIndex: 0, value: 0, isAbsolute: false },
                  { freqIndex: 0, value: 10, isAbsolute: false },
                ]);
              }}
              text={`${t('text.bottom')} 10%`}
            />
            <BorderButton
              color={
                range[0].value === 0 && range[1].value === 20
                  ? themeData.colors.primary[500]
                  : themeData.colors.gray[300]
              }
              onClick={() => {
                setRange([
                  { freqIndex: 0, value: 0, isAbsolute: false },
                  { freqIndex: 0, value: 20, isAbsolute: false },
                ]);
              }}
              text={`${t('text.bottom')} 20%`}
            />

            <Spacer />

            <BorderButton
              color={
                range[0].value === 80 && range[1].value === 100
                  ? themeData.colors.primary[500]
                  : themeData.colors.gray[300]
              }
              onClick={() => {
                setRange([
                  { freqIndex: 0, value: 80, isAbsolute: false },
                  { freqIndex: 0, value: 100, isAbsolute: false },
                ]);
              }}
              text={`${t('text.top')} 20%`}
            />
            <BorderButton
              color={
                range[0].value === 90 && range[1].value === 100
                  ? themeData.colors.primary[500]
                  : themeData.colors.gray[300]
              }
              onClick={() => {
                setRange([
                  { freqIndex: 0, value: 90, isAbsolute: false },
                  { freqIndex: 0, value: 100, isAbsolute: false },
                ]);
              }}
              text={`${t('text.top')} 10%`}
            />
          </HStack>
        ) : (
          <div />
        )}
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={undefined}
          onClose={onClose}
          isCentered
        >
          <AlertDialogOverlay>
            <FactorControlDialog
              // @ts-ignore
              name={t(`factor.${factorHistogram.factorId}.name`)}
              valueUnit={factorHistogram.valueUnit}
              focus={focus}
              initialValue={
                /* eslint-disable-next-line no-nested-ternary */
                focus === 'left'
                  ? typeof range[0].value === 'number'
                    ? range[0].value
                    : factorHistogram.bins[0]
                  : typeof range[1].value === 'number'
                  ? range[1].value
                  : factorHistogram.bins[factorHistogram.bins.length - 1]
              }
              onSubmit={(inputValue) => {
                logEvent('factor value input submitted', {
                  'factor category': factorHistogram.categoryName,
                });

                if (focus === 'left') {
                  if (
                    typeof inputValue === 'number' &&
                    inputValue >= range[1].value
                  ) {
                    appState?.showToast(
                      'error',
                      '최소값은 최대값보다 크거나 같을 수 없습니다',
                      {
                        position: 'bottom-left',
                      },
                    );
                    return;
                  }
                  setUpdateByInput([true, updateByInput[1]]);

                  setRange([
                    {
                      freqIndex:
                        /* eslint-disable-next-line no-nested-ternary */
                        inputValue === 'min'
                          ? 0
                          : inputValue === 'max'
                          ? factorHistogram.bins.length + 1
                          : getIndexByValue(inputValue),
                      value: inputValue,
                      isAbsolute,
                    },
                    range[1],
                  ]);
                } else if (focus === 'right') {
                  if (
                    typeof inputValue === 'number' &&
                    inputValue <= range[0].value
                  ) {
                    appState?.showToast(
                      'error',
                      '최대값은 최소값보다 작거나 같을 수 없습니다',
                      {
                        position: 'bottom-left',
                      },
                    );
                    return;
                  }
                  setUpdateByInput([updateByInput[0], true]);

                  setRange([
                    range[0],
                    {
                      freqIndex:
                        /* eslint-disable-next-line no-nested-ternary */
                        inputValue === 'min'
                          ? 0
                          : inputValue === 'max'
                          ? factorHistogram.bins.length + 1
                          : getIndexByValue(inputValue),
                      value: inputValue,
                      isAbsolute,
                    },
                  ]);
                }
              }}
              onClose={onClose}
            />
          </AlertDialogOverlay>
        </AlertDialog>
      </Flex>
    );
  }, [
    factorHistogram,
    range,
    isOpen,
    isMobile,
    isAbsolute,
    themeData,
    updateByInput,
  ]);
};

type FactorControlDialogProps = {
  name: string;
  valueUnit: string;
  initialValue: number;
  focus: FocusType | undefined;
  onSubmit: (v: number | 'min' | 'max') => void;

  onClose: () => void;
};
const FactorControlDialog = ({
  name,
  valueUnit,
  initialValue,
  onSubmit,
  onClose,
  focus,
}: FactorControlDialogProps) => {
  const [inputValue, setInputValue] = useState<number | 'min' | 'max'>(
    initialValue,
  );

  const [t] = useTranslation();
  return (
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        {name}
      </AlertDialogHeader>

      <AlertDialogBody>
        <Box marginBottom="16px">
          <Body2>
            {t('text.factorControlInputDescription', {
              value: focus === 'left' ? t('text.lower') : t('text.upper'),
            })}
          </Body2>
        </Box>

        <NumberEditableInput
          tailText={valueUnit}
          onUpdate={(number) => {
            setInputValue(number);
          }}
          initialValue={initialValue}
          canDecimals
        />
      </AlertDialogBody>
      <AlertDialogFooter>
        <Button
          colorScheme="blue"
          onClick={() => {
            onSubmit(focus === 'left' ? 'min' : 'max');
            onClose();
          }}
          ml={3}
        >
          <Body1 color="white">
            {t('text.factorControlInputButton', {
              value: focus === 'left' ? t('text.min') : t('text.max'),
            })}
          </Body1>
        </Button>
        <Button onClick={onClose} ml={3}>
          {t('text.cancel')}
        </Button>
        <Button
          colorScheme="primary"
          onClick={() => {
            onSubmit(
              inputValue && typeof inputValue === 'number' ? inputValue : 0,
            );
            onClose();
          }}
          ml={3}
        >
          {t('text.submit')}
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
};

export const getAbsoluteInitialRange = (
  factorHistogram: FactorHistogram,
  defaultRange: FactorHistogramDefaultRange,
): [FactorControlValue, FactorControlValue] => {
  const totalCount = factorHistogram.freqs.reduce((a, b) => a + b, 0);
  let count = 0;
  let i = 0;
  let start = 0;
  let end = factorHistogram.bins.length;

  switch (defaultRange) {
    case 'BOTTOM20':
      while (count <= totalCount / 5) {
        count += factorHistogram.freqs[i];
        i += 1;
      }

      return [
        { freqIndex: 0, value: 'min', isAbsolute: true },
        { freqIndex: i + 1, value: factorHistogram.bins[i], isAbsolute: true },
      ];
    case 'BOTTOM20POSITIVE':
      start = factorHistogram.bins.findIndex((e) => e > 0);
      i = start;
      while (count <= totalCount / 5) {
        count += factorHistogram.freqs[i];
        i += 1;
      }
      return [
        {
          freqIndex: start + 1,
          value: factorHistogram.bins[start],
          isAbsolute: true,
        },
        { freqIndex: i + 1, value: factorHistogram.bins[i], isAbsolute: true },
      ];

    case 'MIDDLE20':
      while (count <= (totalCount * 2) / 5) {
        count += factorHistogram.freqs[i];
        i += 1;
      }
      start = i;

      while (count <= (totalCount * 3) / 5) {
        count += factorHistogram.freqs[i];
        i += 1;
      }
      end = i;

      return [
        {
          freqIndex: start + 1,
          value: factorHistogram.bins[start],
          isAbsolute: true,
        },
        {
          freqIndex: end + 1,
          value: factorHistogram.bins[end],
          isAbsolute: true,
        },
      ];

    case 'TOP20':
      i = end - 2;
      while (count <= totalCount / 5) {
        count += factorHistogram.freqs[i];
        i -= 1;
      }

      return [
        {
          freqIndex: i + 2,
          value: factorHistogram.bins[i + 1],
          isAbsolute: true,
        },
        { freqIndex: end, value: 'max', isAbsolute: true },
      ];

    case 'ALL':
    default:
      return [
        { freqIndex: 0, value: 'min', isAbsolute: true },
        { freqIndex: end, value: 'max', isAbsolute: true },
      ];
  }
};

export default FactorControl;

export const getReletiveInitialRange = (
  factorHistogram: FactorHistogram,
  defaultRange: FactorHistogramDefaultRange,
): [FactorControlValue, FactorControlValue] => {
  const totalCount = factorHistogram.freqs.reduce((a, b) => a + b, 0);
  let count = 0;
  let i = 0;
  let start = 0;

  switch (defaultRange) {
    case 'BOTTOM20':
      return [
        { freqIndex: 0, value: 0, isAbsolute: false },
        { freqIndex: 0, value: 20, isAbsolute: false },
      ];
    case 'BOTTOM20POSITIVE':
      start = factorHistogram.bins.findIndex((e) => e > 0);
      i = start;
      while (count <= totalCount / 5) {
        count += factorHistogram.freqs[i];
        i += 1;
      }
      return [
        {
          freqIndex: start + 1,
          value: factorHistogram.bins[start],
          isAbsolute: true,
        },
        { freqIndex: i + 1, value: factorHistogram.bins[i], isAbsolute: true },
      ];

    case 'MIDDLE20':
      return [
        { freqIndex: 0, value: 40, isAbsolute: false },
        { freqIndex: 0, value: 60, isAbsolute: false },
      ];

    case 'TOP20':
      return [
        { freqIndex: 0, value: 80, isAbsolute: false },
        { freqIndex: 0, value: 100, isAbsolute: false },
      ];

    case 'ALL':
    default:
      return [
        { freqIndex: 0, value: 0, isAbsolute: false },
        { freqIndex: 0, value: 100, isAbsolute: false },
      ];
  }
};
