import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { VegaLite } from 'react-vega';
import { H3 } from '~/components/Typography';
import { getThemeData } from '~/utils/theme';
import { ChartProps } from '.';
import { getYYYYMMDD } from '~/utils/datetime';

const BackTestingMDDChart = ({ value, nationCode }: ChartProps) => {
  const themeData = getThemeData();
  const [t] = useTranslation();
  const v = value.portBenchResultList.map((v) => {
    return {
      ...v,
      t_bmMdd: `${(v.bmMdd * 100).toFixed(2)}%`,
      t_portMdd: `${(v.portMdd * 100).toFixed(2)}%`,
      date: getYYYYMMDD({ date: new Date(v.startDate) }),
    };
  });
  return (
    <Box w="100%" h="100%" p={['0px', '24px']} marginTop={['12px', '0px']}>
      <H3 bold>{t('text.mdd')}</H3>
      <VegaLite
        style={{ width: '100%', height: '100%', marginTop: '8px' }}
        actions={false}
        spec={{
          $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
          width: 'container',
          height: 300,
          autosize: 'fit',
          data: {
            values: v,
            format: {
              parse: { startDate: "utc:'%Y-%m-%d'" },
            },
          },
          encoding: {
            x: {
              field: 'startDate',
              type: 'quantitative',
              timeUnit: 'yearmonth',
              title: null,
              axis: {
                format: '%Y %m',
                formatType: 'time',
              },
            },
          },
          layer: [
            {
              mark: 'line',
              encoding: {
                y: {
                  field: 'portMdd',
                  type: 'quantitative',
                  title: null,
                },

                color: {
                  value: themeData.colors.primary[500],
                },
              },
            },
            {
              mark: 'line',
              encoding: {
                y: {
                  field: 'bmMdd',
                  type: 'quantitative',
                  title: null,
                  axis: { format: '.1~%' },
                },
                color: {
                  value: themeData.colors.text3,
                },
              },
            },
            {
              mark: 'rule',
              encoding: {
                opacity: {
                  condition: { value: 0.3, param: 'hover', empty: false },
                  value: 0,
                },
                tooltip: [
                  {
                    field: 'date',
                    type: 'nominal',
                    title: t('text.date'),
                  },
                  {
                    field: 't_portMdd',
                    type: 'nominal',
                    title: t('text.portfolio'),
                  },
                  {
                    field: 't_bmMdd',
                    type: 'nominal',
                    // @ts-ignore
                    title: t(`nation.benchmark.${nationCode}`).replace(
                      '\n',
                      ' ',
                    ),
                  },
                ],
              },
              params: [
                {
                  name: 'hover',
                  select: {
                    type: 'point',
                    fields: ['startDate'],
                    nearest: true,
                    on: 'mouseover',
                    clear: 'mouseout',
                  },
                },
              ],
            },
          ],
        }}
      />
    </Box>
  );
};

export default BackTestingMDDChart;
