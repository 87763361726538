import { createContext } from 'react';
import {
  TypographyThemeInterface,
  getTypographyTheme,
} from './typographyTheme';
import { ColorThemeInterface, dark, light, blue } from './colorThemes';

export interface DefaultTheme {
  id: string;
  name: string;
  colors: ColorThemeInterface;
  typography: TypographyThemeInterface;
}

export type Themes = 'light' | 'dark' | 'blue';

type ThemeDataInterface = {
  [key in Themes]: DefaultTheme;
};

const ThemeData: ThemeDataInterface = {
  dark: {
    id: 'dark',
    name: 'dark',
    colors: dark,
    typography: getTypographyTheme(),
  },
  light: {
    id: 'light',
    name: 'light',
    colors: light,
    typography: getTypographyTheme(),
  },
  blue: {
    id: 'blue',
    name: 'blue',
    colors: blue,
    typography: getTypographyTheme(),
  },
};

const ThemeContext = createContext({ theme: 'light' as Themes });
export { ThemeData, ThemeContext };
