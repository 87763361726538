import { useMemo } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Box,
  HStack,
  Spacer,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useTable, Column, useSortBy } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { Body2 } from '~/components/Typography';
import { getThemeData } from '~/utils/theme';

type Props = {
  columns: Array<Column>;
  data: Array<any>;
  currency?: string;
  ratio?: string;
};

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */

const FinancialTable = ({ columns, data, currency, ratio }: Props) => {
  if (data.length === 0) {
    return <div />;
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: useMemo<Column[]>(
          () =>
            /* @ts-ignore */
            columns,
          [columns],
        ),
        data: useMemo(() => data, []),
      },

      useSticky,
      useSortBy,
    );

  const themeData = getThemeData();
  const [t] = useTranslation();

  return (
    <Box h="100%" overflow="hidden" w="100%">
      <HStack marginBottom="12px">
        <Spacer />
        <Body2 color={themeData.colors.gray[300]}>
          {ratio} {currency}
        </Body2>
      </HStack>
      <Table
        {...getTableProps()}
        display="inherit"
        h="100%"
        w="100%"
        overflow="scroll"
        style={{
          margin: '0 auto',
          outline: '2px solid white',
          outlineOffset: '-2px',
        }}
      >
        <Thead
          bg={themeData.colors.gray[100]}
          boxShadow={`0 2px ${themeData.colors.gray[200]}`}
        >
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <Td
                  {...column.getHeaderProps(column.getToggleHiddenProps())}
                  title={undefined}
                  style={{
                    ...column.getHeaderProps().style,
                    ...{
                      padding: '8px 24px',
                      fontSize: '14px',
                      fontWeight: 'normal',
                      color: themeData.colors.text2,
                      background: themeData.colors.gray[100],
                    },
                  }}
                  borderRight={
                    i === 0 ? `solid 3px ${themeData.colors.gray[200]}` : 'none'
                  }
                  noOfLines={1}
                  display="table-cell"
                  borderBlock={`solid 1px ${themeData.colors.gray[200]}`}
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  minW={i === 0 ? '300px' : i === 1 ? '30px' : undefined}
                  w={i === 0 ? '300px' : i === 1 ? '30px' : undefined}
                  // @ts-ignore
                  isNumeric={column.isNumeric}
                  align="right"
                >
                  <Box align="right">{column.Header}</Box>
                  {/* {column.render('Header')} */}
                </Td>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody
          {...getTableBodyProps()}
          position="relative"
          zIndex={0}
          bg="white"
        >
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <Td
                      {...cell.getCellProps()}
                      p="12px 24px"
                      m="0px"
                      borderRight={
                        i === 0
                          ? `solid 3px ${themeData.colors.gray[200]}`
                          : 'none'
                      }
                      borderBlockEnd={`solid 1px ${themeData.colors.gray[200]}`}
                      style={{
                        ...{
                          background: 'white',
                          fontSize: '12px',
                        },
                        ...cell.getCellProps().style,
                      }}
                      w={i === 0 ? '300px' : i === 1 ? '30px' : undefined}
                      // @ts-ignore
                      isNumeric
                    >
                      <Body2 align="right">
                        {cell.column.id === 'title'
                          ? // @ts-ignore
                            t(`companyDetailFactor.${row.values['title']}`)
                          : cell.render('Cell')}
                      </Body2>
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};
FinancialTable.defaultProps = {
  currency: null,
  ratio: null,
};
export default FinancialTable;
