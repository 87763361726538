import { useContext } from 'react';
import { Flex, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'theme';
import { useAmplitude } from 'react-amplitude-hooks';

import { useTheme } from '~/hooks/useTheme';
import { H4 } from '../Typography';

type DrawerItemProps = {
  isSelected: boolean;
  link: string;
  onIcon: string;
  offIcon: string;
  children: string;
};
const DrawerItem = ({
  isSelected,
  link,
  onIcon,
  offIcon,
  children,
}: DrawerItemProps) => {
  const themeData = useTheme().getThemeData(useContext(ThemeContext).theme);
  const { logEvent } = useAmplitude();
  return (
    <Link
      onClick={() => {
        logEvent('menu clicked', { 'menu feature': link });
      }}
      to={link}
    >
      <Flex
        flexDirection="column"
        p={['16px 22px', '20px 0px']}
        _hover={{ bg: themeData.colors.primary[900] }}
        bg={
          isSelected
            ? themeData.colors.primary[900]
            : themeData.colors.primary[500]
        }
      >
        <Flex flexDirection={['row', 'column']} align="center">
          <Image
            w="24px"
            h="24px"
            src={isSelected ? onIcon : offIcon}
            marginBottom={['0px', '8px']}
            marginRight={['12px', '0px']}
          />
          <H4
            color={isSelected ? 'white' : themeData.colors.primary[200]}
            bold={isSelected}
          >
            {children}
          </H4>
        </Flex>
      </Flex>
    </Link>
  );
};

export default DrawerItem;
