import { Button, Image, Tooltip } from '@chakra-ui/react';
import { useContext, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { iconDownload } from '~/constants/assets';
import { SortData } from '~/models/exeScreenerBody';
import { ScreenerResult } from '~/models/screenerResult';
import { Repository } from '~/repository';
import { getformatedYYYYMMDD } from '~/utils/datetime';
import { getThemeData } from '~/utils/theme';
import { FactorStateContext } from '../App/context/factor_context';

type Props = {
  requestKey: string;
  repository: Repository;
  orderByFactors: Array<SortData>;
};

const CsvDownloadButton = ({
  requestKey,
  repository,
  orderByFactors,
}: Props) => {
  const [screenerResult, setScreenerResult] = useState<ScreenerResult>();
  const [t] = useTranslation();
  const themeData = getThemeData();
  const csvLink = useRef();
  const factorContext = useContext(FactorStateContext);

  const getDataFromResult = () => {
    if (!screenerResult) return [];
    const factorList =
      screenerResult.portResult.length === 0
        ? []
        : factorContext?.factorList.filter((factor) =>
            Object.keys(screenerResult.portResult[0]?.factorValueMap).includes(
              factor.id.toString(),
            ),
          ) ?? [];

    function getRowDataFromFactorValueMap(
      factorValueMap: Map<string, number>,
    ): Array<string> {
      const r = new Array<string>();
      const objs = new Map(Object.entries(factorValueMap));
      factorList?.forEach((factor) => {
        const v = objs.get(factor.id.toString());
        if (!v) {
          r.push('');
          return;
        }
        r.push(v);
      });
      return r;
    }

    const data = [
      [
        'COMPANY',
        'TICKER',
        'MARKET_PRICE',
        ...(factorList ?? []).map((factor) => {
          // @ts-ignore
          return t(`factor.${factor.id}.name`);
        }),
      ],
      ...screenerResult.portResult?.map((v) => {
        return [
          v.companyLocalName && v.companyLocalName !== ''
            ? v.companyLocalName
            : v.companyName,
          v.ticker,
          v.marketPrice,
          ...getRowDataFromFactorValueMap(v.factorValueMap),
        ];
      }),
    ];

    return data;
  };
  const getScreenerResult = async () => {
    repository
      .getScreenerResult({
        offset: 0,
        count: 100,
        requestKey,
        sortFactors: orderByFactors,
      })
      .then((v) => {
        if (v instanceof Error) {
          throw v;
        }

        setScreenerResult(v.result);
        // @ts-ignore
        csvLink.current.link.click();
      });
  };

  const getFileName = () => {
    return `${getformatedYYYYMMDD({ date: new Date() })}_${
      orderByFactors.length === 0
        ? ''
        : // @ts-ignore
          `_${t(`factor.${orderByFactors[0].factorId}.name`)}_${
            orderByFactors[0].order
          }`
    }.csv`
      .replaceAll('/', '_')
      .replaceAll(' ', '_');
  };
  return (
    <>
      <Tooltip
        color={themeData.colors.text2}
        bg="white"
        box-shadow="0px 3px 3px #ccc"
        whiteSpace="pre-line"
        label={t('text.downloadTooltip')}
      >
        <Button onClick={getScreenerResult} variant="outline" p="0px">
          <Image src={iconDownload} />
        </Button>
      </Tooltip>
      <CSVLink
        data={getDataFromResult()}
        // @ts-ignore
        ref={csvLink}
        asyncOnClick
        filename={getFileName()}
      />
    </>
  );
};
export default CsvDownloadButton;
