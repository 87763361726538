import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { intToCompressedString } from '~/utils/number';
import { getThemeData } from '~/utils/theme';
import { Body2 } from '../Typography';

type PriceRangeComponentProps = {
  current: number;
  low: number;
  high: number;
  lowUnit: string;
  highUnit: string;
  currentUnit: string;
  positionRatio: number;
};
const PriceRangeComponent = ({
  current,
  positionRatio,
  low,
  high,
  lowUnit,
  highUnit,
  currentUnit,
}: PriceRangeComponentProps) => {
  const themeData = getThemeData();

  return (
    <Box margin="48px 24px">
      <Slider
        colorScheme="gray"
        color="gray"
        value={positionRatio}
        isDisabled
        min={0}
        max={1}
      >
        <SliderThumb
          w="8px"
          h="8px"
          _disabled={{
            backgroundColor: themeData.colors.primary[500],
          }}
        >
          <Box marginTop="-60px">
            {current ? (
              <Body2 whiteSpace="nowrap" color={themeData.colors.primary[500]}>
                <Body2
                  color="inherit"
                  whiteSpace="inherit"
                  bold
                  align="center"
                  noOfLines={1}
                >{`${currentUnit}\n`}</Body2>
                <Body2
                  color="inherit"
                  whiteSpace="inherit"
                  bold
                  align="center"
                  noOfLines={1}
                >{`${intToCompressedString(current)}`}</Body2>
              </Body2>
            ) : (
              <div />
            )}
          </Box>
        </SliderThumb>
        <SliderTrack
          _disabled={{
            backgroundColor: themeData.colors.gray[200],
            opacity: 1,
          }}
        >
          <SliderFilledTrack bg={themeData.colors.gray[200]} />
        </SliderTrack>
      </Slider>
      <Flex>
        {low ? (
          <Body2
            noOfLines={2}
            whiteSpace="break-spaces"
            align="left"
          >{`${lowUnit}\n${intToCompressedString(low)}`}</Body2>
        ) : (
          <div />
        )}
        <Spacer />
        {high ? (
          <Body2
            noOfLines={2}
            whiteSpace="break-spaces"
            align="right"
          >{`${highUnit}\n${intToCompressedString(high)}`}</Body2>
        ) : (
          <div />
        )}
      </Flex>
    </Box>
  );
};

export default PriceRangeComponent;
