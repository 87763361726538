import { Box, Spacer } from '@chakra-ui/layout';
import { useContext } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import { useTranslation } from 'react-i18next';

import { Flex, Link } from '@chakra-ui/react';
import { H3 } from '~/components/Typography';
import SectorSelect from '~/container/SectorSelector';
import { Repository } from '~/repository';
import {
  FactorDispatchContext,
  FactorStateContext,
} from '~/routes/App/context/factor_context';
import { getFromLS } from '~/utils/localStorage';
import { getThemeData } from '~/utils/theme';
import NationSelectorContainer from '../../shared/NationSelector';
import ScreenerSortSelector, {
  screenerSortData,
  ScreenerSortType,
} from '../ScreenerSortSelector';
import BannerContent from '~/container/shared/BannerContent';

type Props = {
  repository: Repository;
};

const ScreenerOptionContainer = ({ repository }: Props) => {
  const factorState = useContext(FactorStateContext);
  const factorDispatch = useContext(FactorDispatchContext);
  const themeData = getThemeData();
  const { logEvent } = useAmplitude();
  const [t] = useTranslation();
  if (!factorState) return <div />;

  return (
    <Box
      className="screener-option-container"
      bg={themeData.colors.background}
      h="100%"
      p="24px 32px"
      w="100%"
    >
      <Box marginBottom="24px">
        <H3 bold>{t('text.defaultSettings')}</H3>
      </Box>
      <NationSelectorContainer
        repository={repository}
        selectedNation={
          factorState?.selectedNation ??
          JSON.parse(
            getFromLS(
              'SELECTED_NATION',
              '{"code": 840,"name": "UNITED STATES"}',
            ),
          )
        }
        onSelectNation={(nation) => {
          logEvent('screening basic setting adjusted', {
            'selected country to invest': nation.name,
          });
          if (nation != null && factorDispatch != null) {
            factorDispatch({
              type: 'UPDATE_SELECTED_NATION',
              nation,
            });
          }
        }}
      />
      <Spacer h="24px" />
      <ScreenerSortSelector
        onSelect={(data) => {
          logEvent('screening basic setting adjusted', {
            'sorting priority': data.name,
          });
          if (data != null && factorDispatch != null) {
            factorDispatch({
              type: 'UPDATE_SORT_OPTION',
              sortData: data,
            });
          }
        }}
        selectedData={
          screenerSortData.find(
            (v) =>
              v.data.factorId === factorState.sortData?.data.factorId &&
              v.data.order === factorState.sortData?.data.order,
          ) ??
          (JSON.parse(
            getFromLS(
              'SELECTED_SORT_OPTION',
              JSON.stringify(screenerSortData[0]),
            ),
          ) as ScreenerSortType)
        }
      />
      <Spacer h="24px" />

      <SectorSelect />

      {/* <Spacer h="24px" />

      <BannerContent /> */}
    </Box>
  );
};

export default ScreenerOptionContainer;
