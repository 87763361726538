import { User } from 'firebase/auth';
import { createContext, Dispatch } from 'react';
/*
GLOBAL STATEMENT
*/

type AuthState = {
  user?: User | null;
  plan?: string;
};

type AuthAction =
  | { type: 'LOGIN'; user?: User | null }
  | { type: 'SET_PLAN'; plan?: string }
  | { type: 'LOGOUT' };

function AuthReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, user: action.user };

    case 'SET_PLAN':
      return { ...state, plan: action.plan };

    case 'LOGOUT':
      return { ...state, user: undefined, plan: undefined };

    default:
      return { ...state };
  }
}

type AuthDispatch = Dispatch<AuthAction>;
const AuthDispatchContext = createContext<AuthDispatch | null>(null);
const AuthStateContext = createContext<AuthState | null>(null);
export { AuthReducer, AuthStateContext, AuthDispatchContext };
