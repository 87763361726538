import { Box, Spacer, Image, Button, ButtonProps } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import './react-datepicker.css';
import { forwardRef } from '@chakra-ui/system';

import { getThemeData } from '~/utils/theme';
import { Caption } from '../Typography';
import { iconCalendar } from '~/constants/assets';

type CustomDatePickerProps = {
  label: string;
  selected: Date | undefined;
  maxDate?: Date | null | undefined;
  minDate?: Date | null | undefined;
  onChange(
    date: Date | null,
    event: React.SyntheticEvent<any> | undefined,
  ): void;
};

const CustomDatePicker = ({
  label,
  selected,
  onChange,
  minDate,
  maxDate,
}: CustomDatePickerProps) => {
  const themeData = getThemeData();
  const ExampleCustomInput = forwardRef(
    ({ value, onClick }: ButtonProps, ref) => (
      <Button
        bg="white"
        w="100%"
        justifyContent="start"
        style={{
          border: `solid 1px ${themeData.colors.primary[900]}`,
          borderRadius: 8,
          color: themeData.colors.text2,
          fontSize: themeData.typography.h5.fontSize,
        }}
        onClick={onClick}
        ref={ref}
      >
        {value}
        <Spacer />
        <Image src={iconCalendar} />
      </Button>
    ),
  );

  return (
    <Box>
      <Caption color={themeData.colors.text3}>{label}</Caption>
      <Spacer h="8px" />
      <DatePicker
        dateFormat="yyyy/MM/dd"
        maxDate={maxDate}
        minDate={minDate}
        selected={selected}
        onChange={onChange}
        customInput={<ExampleCustomInput />}
        showYearDropdown
        dropdownMode="select"
      />
    </Box>
  );
};
CustomDatePicker.defaultProps = {
  minDate: null,
  maxDate: null,
};

export default CustomDatePicker;
