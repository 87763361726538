import { Grid, GridItem, Box } from '@chakra-ui/layout';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';

import { BacktestingYieldIndexResult } from '~/models/execBacktesting';
import { getThemeData } from '~/utils/theme';
import BackTestingChart from './BackTestingChart';
import BackTestingHistoryTable from './BackTestingHistoryTable';
import BackTestingMDDChart from './BackTestingMddChart';
import BacktestSummaryTable from './BackTestingSummaryTable';

type Props = {
  result: BacktestingYieldIndexResult;
  nationCode: number;
  isDI?: boolean;
};
const BackTestingResultGrid = ({ result, isDI, nationCode }: Props) => {
  const themeData = getThemeData();
  const [t] = useTranslation();
  return (
    <Grid
      h="100%"
      w="100%"
      display={['block', 'inline-grid']}
      templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
      templateRows={['auto', 'repeat(3, 1fr)']}
    >
      <GridItem rowSpan={['auto', 1]} colSpan={[1, 2]}>
        <BackTestingChart value={result} nationCode={nationCode} />
      </GridItem>
      <GridItem rowSpan={['auto', 2]} colSpan={[1, 1]}>
        <Box display={['initial', 'none']}>
          <BackTestingMDDChart value={result} nationCode={nationCode} />
        </Box>
        <Box display={['none', 'initial']}>
          <BacktestSummaryTable value={result} nationCode={nationCode} />
          {isDI ? (
            <Button
              w="100%"
              size="lg"
              bg={themeData.colors.gray[100]}
              border={`solid 1px ${themeData.colors.primary[900]}`}
              _hover={{ backgroundColor: themeData.colors.gray[200] }}
              color={themeData.colors.primary[900]}
              onClick={() => {
                // console.log('CLICK');
              }}
            >
              {t('text.dummybuy')}
            </Button>
          ) : (
            <div />
          )}
        </Box>
      </GridItem>
      <GridItem rowSpan={['auto', 1]} colSpan={[1, 2]}>
        <Box display={['initial', 'none']}>
          <BacktestSummaryTable value={result} nationCode={nationCode} />
        </Box>
        <Box display={['none', 'initial']}>
          <BackTestingMDDChart value={result} nationCode={nationCode} />
        </Box>
      </GridItem>

      <GridItem
        rowSpan={['auto', 1]}
        colSpan={[1, 3]}
        borderTop={`solid 1px ${themeData.colors.gray[200]}`}
      >
        <BackTestingHistoryTable value={result} nationCode={nationCode} />
      </GridItem>
    </Grid>
  );
};

BackTestingResultGrid.defaultProps = { isDI: false };
export default BackTestingResultGrid;
