import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import DrawerItem from '~/components/DrawerItem';
import DrawerMenu from '~/components/DrawerMenu';
import {
  iconBacktestOff,
  iconBacktestOn,
  iconSearchOff,
  iconSearchOn,
} from '~/constants/assets';
import { getIsDiDomain } from '~/utils/domain';

const Drawer = () => {
  const { pathname } = useLocation();
  const [t] = useTranslation();
  const isDi = getIsDiDomain();
  return (
    <DrawerMenu>
      {/* {getIsDiDomain() ? (
        <DrawerItem
          isSelected={pathname === '/direct-indexing'}
          onIcon={iconDI}
          offIcon={iconDIOff}
          link="/direct-indexing"
        >
          {t(isDi ? 'directIndex.indexEdition' : 'text.direct_indexing')}
        </DrawerItem>
      ) : (
        <div />
      )} */}
      <DrawerItem
        isSelected={pathname === '/screener'}
        onIcon={iconSearchOn}
        offIcon={iconSearchOff}
        link="/screener"
      >
        {t(isDi ? 'directIndex.indexCreation' : 'text.screenerMenu')}
      </DrawerItem>
      <DrawerItem
        isSelected={pathname === '/backtest'}
        onIcon={iconBacktestOn}
        offIcon={iconBacktestOff}
        link="/backtest"
      >
        {t('text.backtestMenu')}
      </DrawerItem>
      {/* <DrawerItem
        isSelected={pathname === '/attention'}
        onIcon={iconBacktestOn}
        offIcon={iconBacktestOff}
        link="/attention"
      >
        {t('text.attention')}
      </DrawerItem> */}
    </DrawerMenu>
  );
};

export default Drawer;
