import { ReactElement, useEffect, useReducer, Suspense } from 'react';
import { Flex, extendTheme, ChakraProvider, Box } from '@chakra-ui/react';
import { ISnippetsParams } from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import amplitude from 'amplitude-js';
import { Amplitude, AmplitudeProvider } from 'react-amplitude-hooks';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';

import {
  AppDispatchContext,
  AppReducer,
  AppStateContext,
  Language,
} from './context/index';
import {
  FactorStateContext,
  FactorDispatchContext,
  FactorReducer,
} from './context/factor_context';
import { Repository } from '~/repository';
import { useTheme } from '~/hooks/useTheme';
import { ThemeContext } from '~/theme';
import { Screener, BackTest } from '..';
import { FactorControlValue } from '~/components/FactorControl';

import ScreenerResult from '../screenerResult';
import BacktestResult from '../backtestResult';
import { getThemeData } from '~/utils/theme';
import { getFromLS, setToLS } from '~/utils/localStorage';
import {
  screenerSortData,
  ScreenerSortType,
} from '~/container/screener/ScreenerSortSelector';
import AttentionRoute from '../attention';
import Drawer from '~/container/shared/Drawer';
import { FactorCategory } from '~/models/factorCategory';
import { Factor } from '~/models/factor';
import useFetch from '~/hooks/useFetch';
import { getYYYYMMDDHHmm } from '~/utils/datetime';
import { initI18n } from '~/i18n';
import '~/utils/firebase';
import {
  AuthDispatchContext,
  AuthReducer,
  AuthStateContext,
} from './context/auth_context';
import { CSContainer } from '~/container/CSContainer';
import CompanyDetailRouter from '../company_detail';
import { CompanyGroupListResponse } from '~/models/screenerResult';
import { ResponseBody } from '~/models/responseBody';

type AppProps = {
  repository: Repository;
};

type WrapperProps = {
  children: ReactElement;
};
type RepositoryWrapperProps = {
  children: ReactElement;
  repository: Repository;
};
const ThemeProviderWrapper = ({ children }: WrapperProps) => {
  const breakpoints = ['0em', '60em', '48em'];
  const { theme, getThemeData } = useTheme();
  const chakraTheme = extendTheme({
    // components: {
    //   Button: { baseStyle: { _focus: { boxShadow: 'none' } } },
    //   Switch: {
    //     baseStyle: {
    //       track: {
    //         _focus: {
    //           boxShadow: 'none',
    //         },
    //       },
    //     },
    //   },
    //   Image: {
    //     baseStyle: {
    //       track: {
    //         _focus: {
    //           boxShadow: 'none',
    //         },
    //       },
    //     },
    //   },
    // },
    breakpoints,
    colors: getThemeData(theme).colors,
    fonts: {
      body: 'Pretendard',
      heading: 'Pretendard',
      mono: 'Pretendard',
    },
  });

  return (
    <ThemeContext.Provider value={{ theme }}>
      <ChakraProvider theme={chakraTheme}>{children}</ChakraProvider>
    </ThemeContext.Provider>
  );
};

const AmplitudeWrapper = ({ children }: WrapperProps) => {
  const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY ?? '';

  useEffect(() => {
    setTimeout(() => {
      const isA = (amplitude.getInstance()?.getSessionId() ?? 0) % 2 === 0;
      amplitude.getInstance().logEvent('APP_INITIALIZED', {
        'A/B Type': isA ? 'A' : 'B',
      });
      amplitude.getInstance().setUserProperties({
        'A/B Type': isA ? 'A' : 'B',
      });
    }, 100);

    // amplitude
    //   .getInstance()
    //   .setUserId(amplitude.getInstance().getSessionId().toString()); // TODO;
    setTimeout(() => {
      amplitude.getInstance().setUserProperties({
        'last visit date': getYYYYMMDDHHmm({ date: new Date() }),
      });
    }, 10000);
  }, []);

  return (
    <AmplitudeProvider
      amplitudeInstance={amplitude.getInstance()}
      apiKey={AMPLITUDE_KEY}
    >
      <Amplitude>{children}</Amplitude>
    </AmplitudeProvider>
  );
};

const AppContextProviderWrapper = ({
  children,
  repository,
}: RepositoryWrapperProps) => {
  const [appState, appDispatch] = useReducer(AppReducer, {
    repository,
    language: getFromLS('SELECTED_LANGUAGE', 'kr') as Language,
    showToast: (type, content, options) => {
      switch (type) {
        case 'success':
          return toast.success(content, options);
        case 'error':
          return toast.error(content, options);
        case 'info':
        default:
          return toast.info(content, options);
      }
    },
  });

  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(appState.language);
    setToLS('SELECTED_LANGUAGE', appState.language);
  }, [appState.language]);

  return (
    <AppStateContext.Provider value={appState}>
      <AppDispatchContext.Provider value={appDispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

const AuthContextProviderWrapper = ({ children }: WrapperProps) => {
  const [authState, authDispatch] = useReducer(AuthReducer, {});

  return (
    <AuthStateContext.Provider value={authState}>
      <AuthDispatchContext.Provider value={authDispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

const FactorContextProviderWrapper = ({
  children,
  repository,
}: RepositoryWrapperProps) => {
  const [factorCategoryListState] = useFetch<Array<FactorCategory>>(
    () => repository.getFactorCategoryList(),
    [],
  );
  const [factorListState] = useFetch<Array<Factor>>(
    () => repository.getFactorList(),
    [],
  );
  const [companyGroupListState] = useFetch<
    Map<CompanyGroupListResponse, Array<CompanyGroupListResponse>>
  >(async () => {
    const result = new Map<
      CompanyGroupListResponse,
      Array<CompanyGroupListResponse>
    >();
    const lv1Result: ResponseBody<Array<CompanyGroupListResponse>> | Error =
      await repository.getCompanyGroupList(1);
    const lv2Result: ResponseBody<Array<CompanyGroupListResponse>> | Error =
      await repository.getCompanyGroupList(2);

    if (lv1Result instanceof Error || lv1Result.errorMessage) {
      throw lv1Result;
    }

    if (lv2Result instanceof Error || lv2Result.errorMessage) {
      throw lv2Result;
    }

    lv1Result.result.forEach((v) => {
      // ex v.cosmosGroupId = 71

      result.set(v, []);
    });

    lv2Result.result.forEach((v) => {
      result.forEach((_, lv1K) => {
        if (Math.floor(v.cosmosGroupId / 100) === lv1K.cosmosGroupId) {
          result.get(lv1K)?.push(v);
        }
      });
    });

    return result;
  }, []);

  const [factorState, factorDispatch] = useReducer(FactorReducer, {
    factorList: [],
    factorCategoryList: [],
    companyGroupList: new Map(),
    unselectedGroups: new Set<number>(
      JSON.parse(getFromLS('UNSELECTED_GROUPS', '[]')),
    ),

    selectedFactors: new Map<number, [FactorControlValue, FactorControlValue]>(
      JSON.parse(getFromLS('SELECTED_FACTORS_VALUE', '[]')),
    ),

    selectedNation: JSON.parse(
      getFromLS('SELECTED_NATION', '{"code": 840,"name": "UNITED STATES"}'),
    ),
    sortData: JSON.parse(
      getFromLS('SELECTED_SORT_OPTION', JSON.stringify(screenerSortData[0])),
    ) as ScreenerSortType,
    selectedConditions: '',
    isEditCurrentConditions: true,
    myConditionsCount: 0,
  });

  useEffect(() => {
    factorDispatch({
      type: 'UPDATE_FACTOR_CATEGORY_LIST',
      factorCategoryList: factorCategoryListState.data ?? [],
    });
  }, [factorCategoryListState.data]);

  useEffect(() => {
    factorDispatch({
      type: 'UPDATE_FACTOR_LIST',
      factorList: factorListState.data ?? [],
    });
  }, [factorListState.data]);

  useEffect(() => {
    factorDispatch({
      type: 'UPDATE_COMPANY_GROUP_LIST',
      companyGroupList: companyGroupListState.data ?? new Map(),
    });
  }, [companyGroupListState?.data]);

  return (
    <FactorStateContext.Provider value={factorState}>
      <FactorDispatchContext.Provider value={factorDispatch}>
        {children}
      </FactorDispatchContext.Provider>
    </FactorStateContext.Provider>
  );
};

const GTMProviderWrapper = ({ children }: WrapperProps) => {
  const gtmParams = {
    id: process.env.REACT_APP_GTM_ID,
  } as ISnippetsParams;

  return <GTMProvider state={gtmParams}>{children}</GTMProvider>;
};

const App = ({ repository }: AppProps) => {
  initI18n();
  // const appHeight = () => {
  //   const vh = window.innerHeight * 0.01;
  //   document.documentElement.style.setProperty('--vh', `${vh}px`);
  // };
  // window.addEventListener('resize', appHeight);
  // appHeight();
  return (
    <Suspense fallback={<div />}>
      <ThemeProviderWrapper>
        <AppContextProviderWrapper repository={repository}>
          <AuthContextProviderWrapper>
            <FactorContextProviderWrapper repository={repository}>
              <GTMProviderWrapper>
                <AmplitudeWrapper>
                  <Router>
                    <Flex
                      width="100vw"
                      overflow="hidden"
                      bg={getThemeData().colors.background}
                    >
                      <Box display={['none', 'inherit']}>
                        <Drawer />
                      </Box>
                      <Box flexDirection="column" width="100%" height="100%">
                        <Box height="100%">
                          <Switch>
                            <Route
                              exact
                              path="/"
                              render={() => {
                                return <Redirect to="/screener" />;
                              }}
                            />
                            <Route path="/screener">
                              <Screener />
                            </Route>
                            <Route path="/screener-result/:requestKey">
                              <ScreenerResult />
                            </Route>
                            <Route path="/backtest">
                              <BackTest />
                            </Route>
                            <Route path="/backtest-result/:requestKey">
                              <BacktestResult />
                            </Route>
                            <Route path="/attention">
                              <AttentionRoute />
                            </Route>
                            {/* <Route path="/direct-indexing">
                              <DirectIndexing />
                            </Route>
                            <Route path="/direct-indexing-v2">
                              <DirectIndexingV2 />
                            </Route> */}
                            <Route path="/company-detail/:cosmosCode">
                              <CompanyDetailRouter />
                            </Route>
                          </Switch>
                        </Box>
                        <Box
                          display={['none', 'inherit']}
                          position="fixed"
                          bottom="32px"
                          right="32px"
                        >
                          <CSContainer />
                        </Box>
                      </Box>
                    </Flex>
                    <ToastContainer closeOnClick={false} />
                  </Router>
                </AmplitudeWrapper>
              </GTMProviderWrapper>
            </FactorContextProviderWrapper>
          </AuthContextProviderWrapper>
        </AppContextProviderWrapper>
      </ThemeProviderWrapper>
    </Suspense>
  );
};

export default App;
