import { Button } from '@chakra-ui/button';
import { Box } from '@chakra-ui/layout';
import { getThemeData } from '~/utils/theme';
import { Caption } from '../Typography';

type Props = {
  value: number;
  onClick: (value: number) => void;
  focused: boolean;
};
const PaginationItem = ({ value, onClick, focused }: Props) => {
  const themeData = getThemeData();
  return (
    <Button
      border="solid 1px #e5e5e5"
      onClick={() => onClick(value)}
      bg={focused ? themeData.colors.primary[500] : 'white'}
    >
      <Box>
        <Caption color={focused ? 'white' : themeData.colors.text3}>
          {value + 1}
        </Caption>
      </Box>
    </Button>
  );
};

export default PaginationItem;
