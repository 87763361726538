export const intToCompressedString = (value: number) => {
  const suffixes = ['', 'k', 'm', 'b', 't'];
  const suffixNum = Math.floor(
    (value.toFixed(0).length - (value < 0 ? 2 : 1)) / 3,
  );

  const shortValue = parseFloat(
    (suffixNum !== 0 ? value / 1000 ** suffixNum : value).toPrecision(3),
  );
  return intToLocaledString(shortValue) + suffixes[suffixNum];
};

export const intToLocaledString = (value: number) => {
  return value.toLocaleString(undefined, { maximumFractionDigits: 2 });
};
