import {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { Grid, GridItem, Flex, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import { getThemeData } from '~/utils/theme';
import { AppStateContext } from '../App/context';
import FactorListContainer from '~/container/shared/FactorList';
import {
  ScreenerStateContext,
  ScreenerDispatchContext,
  ScreenerReducer,
} from './context';
import { Repository } from '~/repository';
import FactorHistogramContainer from '~/container/shared/FactorHistogramContainer';
import ScreenerOptionContainer from '~/container/screener/ScreenerOptionContainer';
import ScreenerHeader from './ScreenerHeader';
import IndexedTab from '~/components/IndexedTab';
import ScreenerExecButton from './ScreenerExecButton';
import ConditionsStorageContainer from '~/container/ConditionsStorageContainer';

type WithRepository = {
  repository: Repository;
};

type Props = {
  children: ReactElement;
};

const ScreenerProvider = ({ children }: Props) => {
  const [screenerState, screenerDispatch] = useReducer(ScreenerReducer, {
    count: 0,
  });
  return (
    <ScreenerStateContext.Provider value={screenerState}>
      <ScreenerDispatchContext.Provider value={screenerDispatch}>
        {children}
      </ScreenerDispatchContext.Provider>
    </ScreenerStateContext.Provider>
  );
};

const Screener = () => {
  const Monitoring = ({ repository }: WithRepository) => {
    const [t] = useTranslation();
    return (
      <>
        <Grid
          display={['none', 'grid']}
          width="100%"
          gap={4}
          templateColumns="repeat(8, 1fr)"
        >
          <GridItem colSpan={2} overflowY="scroll">
            <ScreenerOptionContainer repository={repository} />
            <Box position="sticky" bottom="12px" w="100%">
              <ConditionsStorageContainer
                isFullScreen={false}
                onUpdate={() => {
                  // console.log('');
                }}
              />
            </Box>
          </GridItem>
          <GridItem colSpan={6} overflow="hidden">
            <Grid
              width="100%"
              h="100%"
              overflow="hidden"
              templateColumns="repeat(6, 1fr)"
            >
              <GridItem colSpan={2} overflowY="scroll" bg="white">
                <FactorListContainer />
              </GridItem>

              <GridItem colSpan={4} overflowY="scroll" bg="white">
                <FactorHistogramContainer repository={repository} />
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
        <Box display={['inherit', 'none']} w="100%">
          <IndexedTab
            lastActionButton={<ScreenerExecButton repository={repository} />}
            hasStorageButton
            items={[
              {
                title: `1. ${t('text.defaultSettings')}`,
                child: <ScreenerOptionContainer repository={repository} />,
              },
              {
                title: `2. ${t('text.factorSettings')}`,
                child: <FactorListContainer />,
              },
              {
                title: `3. ${t('text.factorDetailSettings')}`,
                child: <FactorHistogramContainer repository={repository} />,
              },
            ]}
          />
        </Box>
      </>
    );
  };
  const themeData = getThemeData();
  const appState = useContext(AppStateContext);
  const location = useLocation<{ error: boolean }>();
  const history = useHistory();
  useEffect(() => {
    if (location?.state?.error) {
      appState?.showToast('error', location.state.error, {
        position: 'bottom-left',
      });
    }
    history.replace('screener', { ...location.state, error: undefined });
  }, [location?.state?.error]);

  return (
    <ScreenerProvider>
      <Flex
        h={['-webkit-fill-available', '100%']}
        w="100%"
        flexDirection="column"
      >
        {appState != null ? (
          <ScreenerHeader repository={appState?.repository} />
        ) : (
          <div />
        )}

        <Flex
          h={['calc(100vh - 150px)', 'calc(100vh - 122px)']}
          bg={themeData.colors.background}
        >
          {appState != null ? (
            <Monitoring repository={appState?.repository} />
          ) : (
            <div />
          )}
        </Flex>
      </Flex>
    </ScreenerProvider>
  );
};

export default Screener;
