import { Input, InputGroup, InputRightElement } from '@chakra-ui/input';
import { useState } from 'react';
import { useDidUpdateEffect } from '~/hooks/useDidUpdateEffect';
import { getThemeData } from '~/utils/theme';
import { Body2 } from '../Typography';

type Props = {
  tailText?: string;
  onUpdate?: (value: number) => void;
  initialValue?: number;
  canDecimals?: boolean;
  min?: number;
  max?: number;
};

const NumberEditableInput = ({
  tailText,
  onUpdate,
  initialValue,
  canDecimals,
  min,
  max,
}: Props) => {
  const themeData = getThemeData();
  const [value, setValue] = useState<number | '-'>(initialValue ?? 0);

  const handleChange = (evt: any) => {
    if (evt.target.validity.valid) {
      if ((min && evt.target.value < min) || (max && evt.target.value > max)) {
        // console.log('break;');
      } else if (
        evt.target.value.slice(-1) === '.' ||
        evt.target.value.slice(-1) === '0'
      ) {
        setValue(evt.target.value);
      } else if (evt.target.value === '0-' || evt.target.value === '-') {
        setValue('-');
      } else {
        setValue(evt.target.value === '' ? 0 : +evt.target.value);
      }
    }
  };
  useDidUpdateEffect(() => {
    onUpdate?.(parseFloat(value.toString()));
  }, [value]);
  useDidUpdateEffect(() => {
    setValue(initialValue ?? 0);
  }, [initialValue]);
  return (
    <InputGroup size="md" bg="white">
      <Input
        border="solid 1px #e5e5e5"
        borderRadius="8px"
        value={value?.toString()}
        textColor={themeData.colors.text2}
        pattern={
          canDecimals ? '(0-)|((^[-|0-9][0-9]*)(\\.*)[0-9]*$)' : '[0-9]*'
        }
        onChange={handleChange}
        padding="8px 16px"
      />
      <InputRightElement zIndex={0}>
        <Body2 whiteSpace="nowrap" color={themeData.colors.primary[500]}>
          {tailText}
        </Body2>
      </InputRightElement>
    </InputGroup>
  );
};
NumberEditableInput.defaultProps = {
  tailText: '',
  initialValue: 0,
  canDecimals: false,
  min: null,
  max: null,
  onUpdate: () => {
    ('');
  },
};

export default NumberEditableInput;
