export const getIsDiDomain = (): boolean => {
  const diDomains = ['stmos-w.web.app', 'dmm.direct', 'hk.dmm.direct'];

  return diDomains.includes(document.location.host);
};

export const getIsHkDomain = (): boolean => {
  const diDomains = ['hk.dmm.direct'];

  return diDomains.includes(document.location.host);
};
