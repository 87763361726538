import { Flex, Box, Spacer } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { H1, H2 } from '~/components/Typography';
import BacktestingExecButton from './BacktestingExecButton';
import Drawer from '~/container/shared/Drawer';
import { Repository } from '~/repository';
import ConditionSotrageSaveButton from '~/container/ConditionsStorageContainer/ConditionStroageSaveButton';

type WithRepository = {
  repository: Repository;
};
const BacktestingHeader = ({ repository }: WithRepository) => {
  const [t] = useTranslation();

  return (
    <Flex
      style={{
        position: 'sticky',
        borderBottom: 'solid 1px #e5e5e5',
      }}
      p={['12px 24px', '24px 24px']}
      bg="white"
      alignItems="flex-end"
    >
      <Flex flexDirection="column" alignSelf="center">
        <Box display={['inherit', 'none']}>
          <H2 bold> {t('text.backtestMenu')}</H2>
        </Box>
        <Box display={['none', 'inherit']}>
          <H1 bold> {t('text.backtestMenu')}</H1>
        </Box>
      </Flex>
      <Spacer />
      <Box display={['inherit', 'none']} alignSelf="center">
        <Drawer />
      </Box>

      <Box h="100%" display={['none', 'inherit']} alignSelf="center">
        <ConditionSotrageSaveButton />
      </Box>
      <Box display={['none', 'inherit']} alignSelf="center">
        <BacktestingExecButton repository={repository} />
      </Box>
    </Flex>
  );
};

export default BacktestingHeader;
