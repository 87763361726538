import { VStack, Box } from '@chakra-ui/layout';
import { Repository } from '~/repository';
import PriceHistoryChart from './PriceHistoryChart';

type Props = {
  repository: Repository;
  cosmosCode: number;
};

const PriceValues = ({ repository, cosmosCode }: Props) => {
  const endDate = new Date();
  const startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 1);

  return (
    <VStack align="start" w="100%">
      <Box marginBottom="12px" w="100%">
        <PriceHistoryChart
          repository={repository}
          endDate={endDate}
          startDate={startDate}
          cosmosCode={cosmosCode}
        />

        {/* 
        <SubtitleWithTooltip
          label="Price VS Data"
          description="Price VS Data"
        />
        <PriceVSChart /> */}
      </Box>
    </VStack>
  );
};
export default PriceValues;
