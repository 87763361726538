import { useEffect, useState } from 'react';
import { DefaultTheme, ThemeData } from '~/theme';
import { setToLS, getFromLS } from '~/utils/localStorage';
import { getIsDiDomain } from '~/utils/domain';

type Themes = 'light' | 'dark' | 'blue';
export const useTheme = () => {
  const [theme, setTheme] = useState<Themes>('light');

  const setMode = (mode: Themes) => {
    setToLS('theme', mode);
    setTheme(mode);
  };
  const getThemeData = (t: Themes): DefaultTheme => {
    return ThemeData[t];
  };

  useEffect(() => {
    const localTheme = getFromLS('theme');
    if (
      localTheme === 'light' ||
      localTheme === 'dark' ||
      localTheme === 'blue'
    ) {
      setMode(localTheme);
    } else if (getIsDiDomain()) {
      setMode('blue');
    } else {
      setMode('light');
    }
  }, []);

  return { theme, setMode, getThemeData };
};
