import { initializeApp, FirebaseApp } from 'firebase/app';
// import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// import { onBackgroundMessage } from 'firebase/messaging/sw';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app: FirebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// auth.setPersistence(browserSessionPersistence);
/*
export const messaging = getMessaging(app);

getToken(messaging, {
  vapidKey:
    'BOzM7dvVBrAtRbI_HxJAtr47o1dfKsY5wh0E34-9tZSmOcH2_OiEHMGOgAfzEh3EzfEL0BK3Uuhuu8gBHK4E5Pg',
})
  .then((currentToken) => {
    if (currentToken) {
      // navigator.serviceWorker.register('/firebase-messaging-sw.js', {
      //   type: 'module',
      // });

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .register('./firebase-messaging-sw.js')
          .then(function (registration) {
            console.log(
              'Registration successful, scope is:',
              registration.scope,
            );
          })
          .catch(function (err) {
            console.log('Service worker registration failed, error:', err);
          });
      }

      console.log(currentToken);
    } else {
      console.log(
        'No registration token available. Request permission to generate one.',
      );
    }
  })
  .catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
*/
export const provider = new GoogleAuthProvider();
const functions = getFunctions(app);
export const isValidKey = httpsCallable(functions, 'isValidKey');
