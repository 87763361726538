import { useContext, useState } from 'react';
import { Box, Center, Flex, HStack, Spacer } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { Skeleton } from '@chakra-ui/skeleton';
import { Column } from 'react-table';
import { Redirect, useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Caption, H1, H5 } from '~/components/Typography';
import { AppStateContext } from '../App/context';

import useFetch from '~/hooks/useFetch';
import { ResponseBody } from '~/models/responseBody';
import { ScreenerResult } from '~/models/screenerResult';
import MappedTable from '~/components/MappedTable';
import { Factor } from '~/models/factor';
import { getThemeData } from '~/utils/theme';
import Pagination from '~/components/Pagination';
import { ExecButton } from '~/components/CustomButton';
import { ExecScreeneOrderType, SortData } from '~/models/exeScreenerBody';
import CsvDownloadButton from './CsvDownloadButton';
import { FactorStateContext } from '../App/context/factor_context';

interface ParamTypes {
  requestKey: string;
}
const Header = () => {
  const history = useHistory();
  const themeData = getThemeData();
  const [t] = useTranslation();
  return (
    <Flex
      style={{
        display: 'sticky',
        width: '100%',
        borderBottom: 'solid 1px #e5e5e5',
        alignContent: 'center',
      }}
      p={['12px 12px', '24px 24px']}
    >
      <Flex flexDirection="column">
        <H1 bold>{t('text.screenerResult')}</H1>
      </Flex>
      <Spacer />
      <Box
        marginRight="12px"
        borderRadius="full"
        overflow="hidden"
        display={['none', 'inherit']}
      >
        <ExecButton
          text={t('text.goToBacktest')}
          onClick={() => {
            history.push('/backtest');
          }}
        />
      </Box>

      <Button
        colorScheme="primary"
        borderRadius="full"
        size="xl"
        variant="outline"
        onClick={() => {
          if (history.action === 'POP') {
            history.replace('/screener');
          } else if (
            history.action === 'PUSH' ||
            history.action === 'REPLACE'
          ) {
            history.goBack();
          }
        }}
      >
        <Center flexDirection="row" display="flex" p="16px 32px">
          <H5
            whiteSpace="break-spaces"
            bold
            color={themeData.colors.primary[500]}
          >
            {t('text.backButtonTitle')}
          </H5>
        </Center>
      </Button>
    </Flex>
  );
};
const ScreenerResultRoute = () => {
  const [page, setPage] = useState<number>(0);
  const history = useHistory();
  const count = 30;
  const themeData = getThemeData();
  const [totalCount, setTotalCount] = useState<number>(0);
  const appState = useContext(AppStateContext);
  const { requestKey } = useParams<ParamTypes>();

  const factorContext = useContext(FactorStateContext);
  const factorList = factorContext?.factorList;

  // const [sortData, setSortData] = useState<SortData>();
  // const [isCompleted, setIsCompleted] = useState<boolean>();
  if (!requestKey || !appState || !appState.repository) {
    return <Redirect to="/screener" />;
  }

  const queryParams = new URLSearchParams(window.location.search);

  /// {factorId}:{order}
  const orderByFactorsQueryParams = queryParams.getAll('orderByFactors') ?? [];

  const [orderByFactors, setOrderByFactors] = useState(
    orderByFactorsQueryParams.map<SortData>((v) => {
      const value = v.split(':');
      return {
        factorId: parseInt(value[0], 10),
        order: value[1] as ExecScreeneOrderType,
      };
    }),
  );

  const [screenerResultState] = useFetch<
    ResponseBody<ScreenerResult>
  >(async () => {
    appState.repository
      .getScreenerCountResult(requestKey)
      .then((_totalCount) => {
        if (typeof _totalCount === 'number') {
          setTotalCount(_totalCount);
        }
      });

    return appState.repository.getScreenerResult({
      offset: page * count,
      count,
      requestKey,
      sortFactors: orderByFactors,
    });
  }, [page, orderByFactors]);

  if (screenerResultState.error || screenerResultState?.data?.code === 500) {
    useHistory().push('/screener', {
      error: screenerResultState.error,
    });
    return <H1>ERROR</H1>;
  }

  return (
    <Box direction="column" w="100%" h={['-webkit-fill-available', '100%']}>
      <Header />
      <Box
        p={['24px 0px', '24px 48px']}
        w={['100vw', 'calc(100vw - 118px)']}
        h={['calc(100vh - 240px)', 'calc(100vh - 200px)']}
      >
        <HStack paddingBottom="16px" paddingLeft="16px">
          <Caption color={themeData.colors.text2}>Total</Caption>
          <Caption bold color={themeData.colors.primary[500]}>
            {totalCount}
          </Caption>
          <Spacer />
          <CsvDownloadButton
            repository={appState.repository}
            requestKey={requestKey}
            orderByFactors={orderByFactors}
          />
        </HStack>
        {screenerResultState.loading ? (
          <Skeleton w="100%" h="100%" />
        ) : (
          <MappedTable
            orderByFactors={orderByFactors}
            updateSortData={(factorId) => {
              const orderByFactorIndex =
                orderByFactors?.findIndex((v) => v?.factorId === factorId) ??
                -1;

              let updatedOrderByFactors: SortData[] = [];

              if (orderByFactorIndex < 0) {
                /// desc
                updatedOrderByFactors = [{ factorId, order: 'DESC' }];
              } else if (orderByFactors[orderByFactorIndex].order === 'DESC') {
                /// asc
                updatedOrderByFactors = [{ factorId, order: 'ASC' }];
              } else {
                /// delete
              }

              history.replace(
                `/screener-result/${requestKey}${getQueryParams(
                  updatedOrderByFactors,
                )}`,
              );

              setOrderByFactors(updatedOrderByFactors);

              /*
                  sort 조건이 2개 이상 가능 할 때 이 로직을 쓴다
              
              const updatedOrderByFactors = [...orderByFactors];
              if (orderByFactorIndex < 0) {
                /// asc
                updatedOrderByFactors.push({ factorId, order: 'ASC' });
              } else if (orderByFactors[orderByFactorIndex].order === 'ASC') {
                /// desc
                updatedOrderByFactors[orderByFactorIndex] = {
                  factorId,
                  order: 'DESC',
                };
              } else {
                /// delete
                updatedOrderByFactors.splice(orderByFactorIndex, 1);
              }
              history.replace(
                `/screener-result/${requestKey}${getQueryParams(
                  updatedOrderByFactors,
                )}`,
              );
              setOrderByFactors(updatedOrderByFactors);

              */
            }}
            columns={getColumn(
              screenerResultState.data.result.portResult.length === 0
                ? []
                : factorList?.filter(
                    (factor) =>
                      Object(
                        screenerResultState.data.result.portResult[0]
                          ?.factorValueMap,
                      )[factor.id.toString()],
                  ) ?? [],
            )}
            data={getRowDataFromScreenrResult(screenerResultState.data.result)}
          />
        )}
      </Box>
      <Center marginTop="12px" p={['24px 0px', '24px 0px']}>
        <Pagination
          current={page}
          length={Math.ceil(totalCount / count)}
          onChange={(v) => setPage(v)}
        />
      </Center>
    </Box>
  );
};
const getQueryParams = (orderByFactors: SortData[]) => {
  return `?${orderByFactors
    .map((v) => `orderByFactors=${v.factorId}:${v.order}`)
    .join('&')}`;
};
function getRowDataFromScreenrResult(
  screenerResult: ScreenerResult,
): Array<Record<string, any>> {
  return screenerResult.portResult.map((result) => {
    return {
      company:
        result.companyLocalName && result.companyLocalName !== ''
          ? result.companyLocalName
          : result.companyName,
      ticker: result.ticker,
      cosmosCode: result.cosmosCode,
      marketPrice: result.marketPrice?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      ...getMapFromRecord(result.factorValueMap),
    };
  });
}

function getColumn(factors: Factor[]): Array<Column> {
  const fixeddFactorIds = [1133, 1100, 1096, 1087, 1112];
  return [
    // @ts-ignore
    { Header: 'company', accessor: 'company', sticky: 'left' },
    { Header: 'ticker', accessor: 'ticker' },
    { Header: 'cosmosCode', accessor: 'cosmosCode' },
    { Header: 'marketPrice', accessor: 'marketPrice' },
    ...fixeddFactorIds.map((factorId) => {
      return {
        // @ts-ignore
        Header: factorId,
        accessor: factorId.toString(),
      };
    }),
    ...factors
      .filter((e) => !fixeddFactorIds.includes(e.id))
      .map((factor) => {
        return {
          // @ts-ignore
          Header: factor?.id,
          accessor: factor?.id.toString(),
        };
      }),
  ];
}
function getMapFromRecord(
  factorValueMap: Map<string, number>,
): Record<string, string> {
  const r: Record<string, string> = {};

  Object.entries(factorValueMap).forEach(([i, v]) => {
    // const [i, v] = value;
    if (!v) return;
    r[i.toString()] =
      parseInt(i, 10) <= 100
        ? v.toLocaleString(undefined, { maximumFractionDigits: 0 })
        : v.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          });
  });
  return r;
}
export default ScreenerResultRoute;
