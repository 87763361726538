import { useReducer, useEffect } from 'react';

type UseFetchState = {
  loading: boolean;
  data: any;
  error: any;
};
type UseFetchAction =
  | { type: 'LOADING' }
  | { type: 'SUCCESS'; data: any }
  | { type: 'ERROR'; error: any };

function reducer(state: UseFetchState, action: UseFetchAction) {
  switch (action.type) {
    case 'LOADING':
      return {
        loading: true,
        data: null,
        error: null,
      };
    case 'SUCCESS':
      return {
        loading: false,
        data: action.data,
        error: null,
      };
    case 'ERROR':
      return {
        loading: false,
        data: null,
        error: action.error,
      };
    default:
      throw new Error(`Unhandled action type: ${action}`);
  }
}

function useFetch<R>(callback: () => any, deps?: Array<any>) {
  const initialState: UseFetchState = {
    loading: true,
    data: null,
    error: false,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchData = async () => {
    dispatch({ type: 'LOADING' });
    try {
      const data = await callback();
      dispatch({ type: 'SUCCESS', data });
    } catch (error) {
      dispatch({ type: 'ERROR', error });
    }
  };

  useEffect(() => {
    fetchData();
  }, deps ?? []);

  return [{ ...state, data: state.data as R }];
}
export default useFetch;
