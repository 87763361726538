import { Button } from '@chakra-ui/button';
import { Center, Flex } from '@chakra-ui/layout';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConditionsStorageContainer from '~/container/ConditionsStorageContainer';
import { getThemeData } from '~/utils/theme';
import { H5 } from '../Typography';

export type IndexedTabItem = {
  title: string;
  child: ReactElement;
};
type Props = {
  items: Array<IndexedTabItem>;
  hasBottomButtons?: boolean;
  lastActionButton: ReactElement;
  hasNextButton?: boolean;
  hasStorageButton?: boolean;
};

type IndexButtonProps = {
  item: IndexedTabItem;
  index: number;
  onUpdateIndex: (index: number) => void;
  isSelected: boolean;
};
const IndexButton = ({
  item,
  index,
  onUpdateIndex,
  isSelected,
}: IndexButtonProps) => {
  const themeData = getThemeData();
  return (
    <Button
      border={`solid 1px ${
        isSelected ? themeData.colors.primary[500] : themeData.colors.text3
      }`}
      bg={
        isSelected ? themeData.colors.primary[100] : themeData.colors.gray[100]
      }
      onClick={() => onUpdateIndex(index)}
    >
      <H5
        whiteSpace="break-spaces"
        bold
        color={
          isSelected ? themeData.colors.primary[500] : themeData.colors.text3
        }
      >
        {item.title}
      </H5>
    </Button>
  );
};
const IndexedTab = ({
  items,
  lastActionButton,
  hasNextButton,
  hasBottomButtons,
  hasStorageButton,
}: Props) => {
  const [t] = useTranslation();
  const [index, setIndex] = useState(0);
  const themeData = getThemeData();

  return (
    <Flex w="100%" bg="white" flexDirection="column">
      <Flex p="12px">
        {items.map((item, itemIndex) => (
          <Center key={`tab-${item.title}`} flex={1}>
            <IndexButton
              item={item}
              index={itemIndex}
              onUpdateIndex={setIndex}
              isSelected={itemIndex === index}
            />
          </Center>
        ))}
      </Flex>
      <Flex
        h="-webkit-fill-available"
        flex={1}
        overflowY="scroll"
        w="100%"
        marginBottom={hasBottomButtons ? '64px' : '0px'}
      >
        {items[index].child}
      </Flex>
      {hasBottomButtons ? (
        <Flex
          position="fixed"
          w="100%"
          padding="8px 0px"
          bottom="0px"
          backgroundColor="white"
        >
          {hasStorageButton ? (
            <ConditionsStorageContainer
              isFullScreen
              onUpdate={() => {
                // console.log('');
              }}
            />
          ) : (
            <div />
          )}
          {index === 0 ? (
            <div />
          ) : (
            <Flex flex={1} padding="0px 12px">
              <Button
                variant="outline"
                w="100%"
                size="lg"
                onClick={() => {
                  setIndex(index - 1);
                }}
              >
                {t('text.prev')}
              </Button>
            </Flex>
          )}
          {!hasNextButton ? (
            <div />
          ) : (
            <Flex flex={1} padding="0px 12px">
              {index === items.length - 1 ? (
                lastActionButton
              ) : (
                <Button
                  color={themeData.colors.primary[100]}
                  bg={themeData.colors.primary[500]}
                  variant="outline"
                  _hover={{ backgroundColor: themeData.colors.primary[400] }}
                  w="100%"
                  size="lg"
                  onClick={() => {
                    setIndex(index + 1);
                  }}
                >
                  {t('text.next')}
                </Button>
              )}
            </Flex>
          )}
        </Flex>
      ) : (
        <div />
      )}
    </Flex>
  );
};
IndexedTab.defaultProps = {
  hasNextButton: true,
  hasBottomButtons: true,
  hasStorageButton: false,
};
export default IndexedTab;
