import { VegaLite } from 'react-vega';
import { Price } from '~/models/companyDetail';
import { intToLocaledString } from '~/utils/number';
import { getThemeData } from '~/utils/theme';

const PriceChart = ({
  // currency,
  values,
}: {
  // currency: string;
  values: Array<Price>;
}) => {
  const themeData = getThemeData();
  const minClose = Math.min(...values.map((v) => v.close));
  // const maxOpen = Math.max(...chartValues.map((v) => v.open));
  const maxVolume = Math.max(...values.map((v) => v.volume));
  const minDate = values[0].date;
  const maxDate = values[values.length - 1].date;
  // currency;
  const minDateTime = {
    year: parseInt(minDate.split('-')[0], 10),
    month: parseInt(minDate.split('-')[1], 10),
    date: parseInt(minDate.split('-')[2], 10),
  };

  const maxDateTime = {
    year: parseInt(maxDate.split('-')[0], 10),
    month: parseInt(maxDate.split('-')[1], 10),
    date: parseInt(maxDate.split('-')[2], 10),
  };
  return (
    <VegaLite
      style={{ width: '100%', height: '100%', marginTop: '8px' }}
      actions={false}
      spec={{
        $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
        width: 'container',
        height: 300,
        autosize: 'fit',
        data: {
          values: values.map((v) => {
            return {
              open: v.open,
              close: v.close,
              high: v.high,
              low: v.low,
              volume: v.volume,
              date: v.date,
              day_yield: `${intToLocaledString((1 - v.open / v.close) * 100)}%`,
              localeVolume: `${intToLocaledString(v.volume)}`,
            };
          }),
        },
        config: {
          style: {
            cell: {
              stroke: 'transparent',
            },
          },
        },
        transform: [
          {
            calculate: `((datum.volume/${maxVolume})*${minClose / 3})+${
              (minClose * 2) / 3
            }`,
            as: 'volume2',
          },
          // {
          //   calculate: `(1-(datum.open/datum.close))*100`,
          //   as: 'day_yield',
          // },
        ],
        encoding: {
          x: {
            field: 'date',
            type: 'quantitative',
            timeUnit: 'yearmonthdate',
            scale: {
              zero: false,
              domainMax: maxDateTime,
              domainMin: minDateTime,
            },
            axis: {
              format: '%Y.%m.%d',
              formatType: '1',
              title: null,
              gridColor: 'transparent',
            },
          },
        },
        layer: [
          {
            mark: {
              type: 'bar',
            },
            encoding: {
              color: {
                condition: {
                  test: 'datum.open < datum.close',
                  value: '#E6BDB8',
                },
                value: '#B5EAD0',
              },
              y: {
                field: 'volume2',
                type: 'quantitative',

                scale: {
                  // domainMin: minClose,
                },
                axis: {
                  title: null,
                  orient: 'right',
                },
              },
            },
          },
          {
            mark: {
              type: 'area',
              line: true,
              point: false,
            },
            encoding: {
              color: {
                value: '#A0C4EC88',
              },
              y: {
                type: 'quantitative',
                field: 'close',
                scale: {
                  zero: false,
                },
                axis: {
                  title: null,
                  orient: 'right',
                  domainColor: 'transparent',
                  tickColor: 'transparent',
                  labelColor: themeData.colors.text2,
                  gridColor: 'transparent',
                },
              },
            },
          },
          {
            mark: 'rule',
            encoding: {
              opacity: {
                condition: { value: 0.3, param: 'hover', empty: false },
                value: 0,
              },
              tooltip: [
                {
                  field: 'date',
                  type: 'temporal',
                  title: '날짜',
                  format: '%Y-%m-%d',
                },
                {
                  field: 'open',
                  type: 'nominal',
                  title: '시가',
                },
                {
                  field: 'close',
                  type: 'nominal',
                  title: '종가',
                },
                {
                  field: 'day_yield',
                  type: 'nominal',
                  title: '변동',
                },
                {
                  field: 'high',
                  type: 'nominal',
                  title: '고가',
                },
                {
                  field: 'low',
                  type: 'nominal',
                  title: '저가',
                },
                {
                  field: 'localeVolume',
                  type: 'ordinal',
                  title: '거래량',
                },
              ],
            },
            params: [
              {
                name: 'hover',
                select: {
                  type: 'point',
                  fields: ['date'],
                  nearest: true,
                  on: 'mouseover',
                  clear: 'mouseout',
                },
              },
            ],
          },
        ],
      }}
    />
  );
};

export default PriceChart;
