import { createContext, Dispatch } from 'react';
import { ToastContent, ToastOptions } from 'react-toastify';
import { Repository } from '~/repository';
/*
GLOBAL STATEMENT
*/

export type Language = 'kr' | 'en';

type AppState = {
  repository: Repository;
  language: Language;
  showToast: (
    type: 'success' | 'info' | 'error',
    content: ToastContent,
    options?: ToastOptions | undefined,
  ) => React.ReactText;
};

type AppAction =
  | { type: 'APP_LOAD' }
  | { type: 'CHANGE_LANGUAGE'; language: Language };

function AppReducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case 'APP_LOAD':
      return { ...state };

    case 'CHANGE_LANGUAGE':
      return { ...state, language: action.language };
    default:
      return { ...state };
  }
}

type AppDispatch = Dispatch<AppAction>;
const AppDispatchContext = createContext<AppDispatch | null>(null);
const AppStateContext = createContext<AppState | null>(null);
export { AppReducer, AppStateContext, AppDispatchContext };
