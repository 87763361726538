import { Image } from '@chakra-ui/image';
import { Box, Center } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/skeleton';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FactorControl, { FactorControlValue } from '~/components/FactorControl';
import { Body2 } from '~/components/Typography';
import { iconBack } from '~/constants/assets';
import useFetch from '~/hooks/useFetch';
import { FactorHistogram } from '~/models/factorHistogram';
import { Repository } from '~/repository';
import {
  FactorDispatchContext,
  FactorStateContext,
} from '~/routes/App/context/factor_context';
import { getThemeData } from '~/utils/theme';

type FactorHistogramItemProps = {
  factorId: number;
  repository: Repository;
  onUpdateRange: (range: [FactorControlValue, FactorControlValue]) => void;
  nationCode: number;
  value: [FactorControlValue, FactorControlValue];
};
type FactorHistogramContainerProps = {
  repository: Repository;
};

const FactorHistogramItem = ({
  factorId,
  repository,
  onUpdateRange,
  nationCode,
  value,
}: FactorHistogramItemProps) => {
  const factorDispatch = useContext(FactorDispatchContext);
  const [state] = useFetch<FactorHistogram>(() => {
    return repository.getFactorHistogram(factorId, nationCode);
  }, [nationCode, factorId]);

  const controller = useMemo(() => {
    if (state.data) {
      return (
        <Box style={{ animation: 'smoothAppear .5s' }}>
          <FactorControl
            factorId={factorId}
            onUpdateRange={onUpdateRange}
            factorHistogram={state.data}
            value={value}
          />
        </Box>
      );
    }
    return <div />;
  }, [state?.data, value, onUpdateRange]);

  if (state.loading) return <Skeleton h="200px" />;

  if (state.error) {
    if (factorDispatch != null)
      factorDispatch({
        type: 'REMOVE_FACTOR',
        factorId,
      });
  }

  return controller;
};

const FactorHistogramContainer = ({
  repository,
}: FactorHistogramContainerProps) => {
  const factorDispatch = useContext(FactorDispatchContext);
  const factorContext = useContext(FactorStateContext);
  const themeData = getThemeData();
  const [t] = useTranslation();

  const contents = useMemo(() => {
    if (!factorContext) {
      return null;
    }
    if (factorContext.selectedFactors.size === 0) {
      return (
        <Center h="100%" flexDirection="column" display="flex">
          <Image src={iconBack} marginBottom="16px" />
          <Body2 whiteSpace="nowrap" color={themeData.colors.text3}>
            {t('text.factorEmpty')}
          </Body2>
        </Center>
      );
    }
    return Array.from(factorContext.selectedFactors.entries()).map(
      ([factorId, value]) => {
        return (
          <FactorHistogramItem
            key={factorId}
            value={value}
            onUpdateRange={(range) => {
              if (factorDispatch != null) {
                factorDispatch({
                  type: 'UPDATE_RANGE',
                  factorId,
                  range,
                });
              }
            }}
            repository={repository}
            factorId={factorId}
            nationCode={factorContext.selectedNation.code}
          />
        );
      },
    );
  }, [
    factorContext?.selectedNation?.code,
    factorContext?.selectedFactors,
    themeData,
  ]);

  if (!factorContext) return <div>ERROR</div>;

  return (
    <Box
      className="factor-histogram-container"
      p="0px 0px 36px 0px"
      height="100%"
      w="100%"
    >
      <Box height="100%" borderLeft={['none', 'solid 2px #f7f7f7']}>
        {contents}
      </Box>
    </Box>
  );
};

export default FactorHistogramContainer;
