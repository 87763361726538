import { Column } from 'react-table';
import { VegaLite } from 'react-vega';
import { VStack, Box, HStack } from '@chakra-ui/layout';
import { useTranslation } from 'react-i18next';
import { useBreakpointValue } from '@chakra-ui/react';

import LoadingComponent from '~/components/LoadingComponent';
import SubtitleWithTooltip from '~/components/SubtitleWithTooltip';
import useFetch from '~/hooks/useFetch';
import {
  CompanyFinancialRatioValuesResponse,
  FinancialStatementValuesResponse,
} from '~/models/companyDetail';
import { ResponseBody } from '~/models/responseBody';
import { Repository } from '~/repository';
import FinancialTable from './FinancialTable';
import { intToCompressedString } from '~/utils/number';

type Props = {
  repository: Repository;
  cosmosCode: number;
};
type ValueItemProps = {
  repository: Repository;
  cosmosCode: number;

  type: 'BALANCE_SHEET' | 'CASH_FLOW' | 'INCOME';
};
type RatioItemProps = {
  repository: Repository;
  cosmosCode: number;

  type: 'EFFICIENCY' | 'GROWTH' | 'PROFITABILITY' | 'SHARE' | 'STABILITY';
};

const FinancialValues = ({ repository, cosmosCode }: Props) => {
  const [t] = useTranslation();

  return (
    <VStack align="start">
      <HStack marginTop="12px" marginBottom="12px" w="100%">
        <SubtitleWithTooltip label={t('companyDetail.financialRatio')} />
      </HStack>
      <FinancialRatioItem
        repository={repository}
        cosmosCode={cosmosCode}
        type="SHARE"
      />

      <FinancialRatioItem
        repository={repository}
        cosmosCode={cosmosCode}
        type="GROWTH"
      />
      <FinancialRatioItem
        repository={repository}
        cosmosCode={cosmosCode}
        type="PROFITABILITY"
      />

      <FinancialRatioItem
        repository={repository}
        cosmosCode={cosmosCode}
        type="EFFICIENCY"
      />
      <FinancialRatioItem
        repository={repository}
        cosmosCode={cosmosCode}
        type="STABILITY"
      />

      <Box marginBottom="12px">
        <SubtitleWithTooltip label={t('companyDetail.financialStatement')} />
      </Box>
      <FinancialValueItem
        repository={repository}
        cosmosCode={cosmosCode}
        type="INCOME"
      />

      <FinancialValueItem
        repository={repository}
        cosmosCode={cosmosCode}
        type="BALANCE_SHEET"
      />

      <FinancialValueItem
        repository={repository}
        cosmosCode={cosmosCode}
        type="CASH_FLOW"
      />
    </VStack>
  );
};

const FinancialRatioItem = ({
  repository,
  cosmosCode,
  type,
}: RatioItemProps) => {
  const [t] = useTranslation();
  const isMobile = useBreakpointValue([true, false, false]);

  const [financialStatementValues] = useFetch<
    ResponseBody<CompanyFinancialRatioValuesResponse>
  >(
    () =>
      repository.getFinancialRatioValues({
        cosmosCode,
        period: 'ANNUALLY',
        type,
      }),
    [],
  );

  if (financialStatementValues.loading) return <LoadingComponent />;

  if (financialStatementValues.error || !financialStatementValues?.data?.result)
    return <div />;

  const columns = getColumn(
    financialStatementValues.data.result,
    t(`companyDetail.${type}`),
    t(`companyDetail.trend`),
    // @ts-ignore
  ).filter((e) => !isMobile || !e.hideMobile);
  return (
    <FinancialTable
      columns={columns}
      data={getRowData(financialStatementValues.data.result)}
    />
  );
};
const FinancialValueItem = ({
  repository,
  cosmosCode,
  type,
}: ValueItemProps) => {
  const [financialStatementValues] = useFetch<
    ResponseBody<FinancialStatementValuesResponse>
  >(
    () =>
      repository.getFinancialStatementValues({
        cosmosCode,
        period: 'ANNUALLY',
        type,
      }),
    [],
  );
  const [t] = useTranslation();
  const isMobile = useBreakpointValue([true, false, false]);

  if (financialStatementValues.loading) return <LoadingComponent />;

  if (financialStatementValues.error || !financialStatementValues?.data?.result)
    return <div />;

  const columns = getColumn(
    financialStatementValues.data.result,
    t(`companyDetail.${type}`),
    t(`companyDetail.trend`),
    // @ts-ignore
  ).filter((e) => !isMobile || !e.hideMobile);
  return (
    <FinancialTable
      columns={columns}
      data={getRowData(financialStatementValues.data.result)}
      currency={financialStatementValues.data.result.currency}
    />
  );
};

function getColumn(
  financialStatementValuesResponse: FinancialStatementValuesResponse,
  type: string,
  trend: string,
): Array<Column> {
  const value = (financialStatementValuesResponse.periodInfoList ?? []).map(
    (periodInfo) => {
      return {
        Header: periodInfo.calcEndDate.split('-').splice(0, 2).join('-'),
        accessor: periodInfo.calcEndDate,
        isNumeric: true,
        hideMobile: true,
      };
    },
  );

  return [
    // @ts-ignore
    { Header: type, accessor: 'title', sticky: 'left', hideMobile: false },
    // @ts-ignore
    { Header: trend, accessor: 'chart', hideMobile: false },
    // @ts-ignore
    ...value,
  ];
}
function getRowData(
  financialStatementValuesResponse: FinancialStatementValuesResponse,
): Array<Record<string, any>> {
  return (financialStatementValuesResponse.financialItemList ?? []).map(
    (title, i) => {
      return {
        title,
        ...getMapFromRecord(financialStatementValuesResponse, i),
      };
    },
  );
}

function getChart(data: Array<number>): any {
  if (data.length === 0) return <div />;
  return (
    <VegaLite
      actions={false}
      spec={{
        $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
        width: 40,
        height: 20,
        description:
          'A bar chart with negative values. We can hide the axis domain line, and instead use a conditional grid color to draw a zero baseline.',
        data: {
          values: data.map((v, i) => {
            return {
              i,
              v,
              c: v < 0 ? '#FF5D5D' : '#4A87FF',
            };
          }),
        },
        mark: 'bar',
        encoding: {
          x: {
            field: 'i',
            type: 'nominal',
            axis: {
              domain: false,
              ticks: false,
              labelAngle: 0,
              labelPadding: 4,
            },
          },
          y: {
            field: 'v',
            type: 'quantitative',
            axis: {
              gridColor: {
                condition: { test: 'datum.value === 0', value: 'black' },
                value: '#ddd',
              },
            },
          },
          color: { field: 'c', type: 'nominal', scale: null },
        },
        config: {
          style: {
            cell: {
              stroke: 'transparent',
            },
          },
          padding: 0,
          axisX: {
            title: null,
            labels: false,
            grid: false,
            ticks: false,
            domain: false,
          },
          axisY: {
            title: null,
            labels: false,
            grid: false,
            ticks: false,
            domain: false,
          },
        },
      }}
    />
  );
}

function getMapFromRecord(
  financialStatementValuesResponse: FinancialStatementValuesResponse,
  i: number,
): Record<string, any> {
  const r: Record<string, any> = {};
  const obj = Object.entries(
    financialStatementValuesResponse.financialValueMap,
  );
  const chartData = Array<number>();
  obj.forEach((v) => {
    if (!v || !v[1][i]) {
      r[v[0]] = '-';
      chartData.push(0);
      return;
    }
    r[v[0]] = intToCompressedString(v[1][i]);

    chartData.push(v[1][i]);
  });
  r['chart'] = getChart(chartData);
  return r;
}
export default FinancialValues;
