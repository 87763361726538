import { FactorControlValue } from '~/components/FactorControl';
import { ExecConditions } from '~/models/exeScreenerBody';

export const getExecConditions = (
  selectedFactors: Map<number, [FactorControlValue, FactorControlValue]>,
): Array<ExecConditions> => {
  const conditions: Array<ExecConditions> = [];

  selectedFactors.forEach((range, factorId) => {
    const { isAbsolute } = range[0];

    if (isAbsolute) {
      conditions.push({
        factorId,
        symbol: '>=',
        value: typeof range[0].value === 'number' ? range[0].value : null,
        isAbsolute,
      });

      conditions.push({
        factorId,
        symbol: range[1].isAbsolute ? '<' : '<=',
        value: typeof range[1].value === 'number' ? range[1].value : null,
        isAbsolute,
      });
    } else {
      if (range[0].value !== 0) {
        conditions.push({
          factorId,
          symbol: '>=',
          value: 100 - (range[0].value as number),
          isAbsolute,
        });
      }

      if (range[1].value !== 100) {
        conditions.push({
          factorId,
          symbol: '<=',
          value: 100 - (range[1].value as number),
          isAbsolute,
        });
      }
    }
  });
  return conditions;
};
