import * as crypto from 'crypto';

const encryptionEncoding = 'base64';
const bufferEncryption = 'utf-8';

export const encrpytAes128 = (value: string) => {
  const key = Buffer.from('KaNdRgUkXp2s5v8y');
  const iv = Buffer.from('KaNdRgUkXp2s5v8y');
  const cipher = crypto.createCipheriv('aes-128-cbc', key, iv);
  let encrypted = cipher.update(value, bufferEncryption, encryptionEncoding);
  encrypted += cipher.final(encryptionEncoding);
  return encrypted;
};

export const decrpytAes128 = (value: string) => {
  const key = Buffer.from('KaNdRgUkXp2s5v8y');
  const iv = Buffer.from('KaNdRgUkXp2s5v8y');
  const decipher = crypto.createDecipheriv('aes-128-cbc', key, iv);
  let decrpyted = decipher.update(value, encryptionEncoding, bufferEncryption);
  decrpyted += decipher.final(bufferEncryption);
  return decrpyted;
};
