import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Spacer,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
  collection,
} from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { getAuth, signInWithPopup, Unsubscribe } from 'firebase/auth';
import { useAmplitude } from 'react-amplitude-hooks';

import ConditionsList from './ConditionsList';
import {
  AuthDispatchContext,
  AuthStateContext,
} from '~/routes/App/context/auth_context';
import { getThemeData } from '~/utils/theme';
import { Body1, Body2, H3 } from '~/components/Typography';
import {
  FactorDispatchContext,
  FactorStateContext,
} from '~/routes/App/context/factor_context';
import { setToLS } from '~/utils/localStorage';
import { FactorControlValue } from '~/components/FactorControl';
import { iconDelete, iconFolder } from '~/constants/assets';
import ConditionSotrageSaveButton from './ConditionStroageSaveButton';
import { provider } from '~/utils/firebase';

type Props = {
  onUpdate: () => void;
  isFullScreen: boolean;
};
const StroageCountBadge = ({ count }: { count: number }) => {
  const themeData = getThemeData();

  return (
    <Center
      w="24px"
      h="24px"
      position="absolute"
      overflow="visible"
      right="-4px"
      top="-4px"
      borderRadius="full"
      backgroundColor={themeData.colors.error}
    >
      <Body1 bold color="white">
        {count}
      </Body1>
    </Center>
  );
};
const StroageBadgeButton = ({ onClick }: { onClick: () => void }) => {
  const themeData = getThemeData();
  const authState = useContext(AuthStateContext);
  const factorState = useContext(FactorStateContext);
  const factorDispatch = useContext(FactorDispatchContext);
  const [t] = useTranslation();
  let unsub: Unsubscribe | null;
  const { amplitudeInstance } = useAmplitude();

  useEffect(() => {
    if (authState?.user && !unsub) {
      unsub = onSnapshot(
        collection(getFirestore(), `storage/${authState.user.uid}/conditions`),
        (conditions) => {
          factorDispatch?.({
            type: 'UPDATE_MY_CONDITIONS_COUNT',
            myConditionsCount: conditions.docs.length,
          });
          amplitudeInstance?.setUserProperties({
            'num total of saved strategies': conditions.docs.length,
          });
        },
      );
    } else if (!authState?.user && unsub) {
      unsub();
    }
  }, [authState?.user]);

  return (
    <Button
      overflow="visible"
      p={['3px 0px', '8px']}
      marginLeft={['8px', '30px']}
      borderWidth="2px"
      size="xl"
      background={themeData.colors.navy}
      borderRadius={['16px', 'full']}
      onClick={onClick}
    >
      {authState?.user ? (
        <StroageCountBadge count={factorState?.myConditionsCount ?? 0} />
      ) : (
        <div />
      )}
      <Center h="40px" flexDirection="row" display="flex" margin="0px 16px">
        <Image src={iconFolder} marginRight={['0px', '8px']} />
        <Box display={['none', 'inherit']}>
          <Body2 bold color="white">
            {t('strategy.title')}
            {factorState?.selectedConditions === ''
              ? ''
              : `: ${factorState?.selectedConditions}`}
          </Body2>
        </Box>
      </Center>
    </Button>
  );
};
const ConditionsStorageContainer = ({ onUpdate, isFullScreen }: Props) => {
  const authState = useContext(AuthStateContext);
  const authDispatch = useContext(AuthDispatchContext);
  const factorState = useContext(FactorStateContext);
  const themeData = getThemeData();
  const factorDispatch = useContext(FactorDispatchContext);
  if (!authState || !factorState) return <div />;

  const { user } = authState;
  const [t] = useTranslation();
  const [isActive, setIsActive] = useState<boolean>(false);
  const isMobile = useBreakpointValue([true, false]);

  if (!isActive)
    return (
      <>
        <Center
          position="absolute"
          top="-12px"
          left="8px"
          display={['inherit', 'none']}
        >
          <Body2 bold color="black">
            {factorState?.selectedConditions === ''
              ? ''
              : `${factorState?.selectedConditions}`}
          </Body2>
        </Center>

        <StroageBadgeButton
          onClick={() => {
            setIsActive(true);
          }}
        />
      </>
    );

  const SaveConditionsBanner = () => {
    const themeData = getThemeData();

    if (
      factorState.selectedFactors.size === 0 ||
      !factorState.isEditCurrentConditions
    ) {
      return <div />;
    }

    return (
      <HStack backgroundColor={themeData.colors.primary[100]} p="20px">
        <Body2 noOfLines={5} whiteSpace="break-spaces">
          {t('strategy.mobileSaveBanner')}
        </Body2>
        <Spacer />
        <ConditionSotrageSaveButton
          onComplete={() => {
            setIsActive(false);
          }}
        />
      </HStack>
    );
  };
  const Header = () => {
    return (
      <Flex marginBottom="16px" w="100%">
        <H3 bold>
          {t('strategy.title')}
          {factorState.selectedConditions === ''
            ? ''
            : `: ${factorState.selectedConditions}`}
        </H3>
        <Spacer />
        <Button
          size="xs"
          alignSelf="end"
          variant="unstyled"
          onClick={(e) => {
            e.preventDefault();
            setIsActive(false);
          }}
        >
          <Image src={iconDelete} />
        </Button>
      </Flex>
    );
  };
  return (
    <Box
      shadow="3px -2px 2px #e0e0e0"
      borderRadius="24px"
      marginRight="4px"
      p="24px"
      background="white"
      position={isFullScreen ? 'inherit' : undefined}
      height={isFullScreen ? '-webkit-fill-available' : '480px'}
      w={isFullScreen ? '100vw' : 'auto'}
      overflow="scroll"
      bottom="0px"
      zIndex={1000}
    >
      <Header />
      {isFullScreen ? <SaveConditionsBanner /> : <div />}
      {user ? (
        <ConditionsList
          checkDuplicatedAlias={(alias) => {
            return getDoc(
              doc(getFirestore(), `storage/${user.uid}/conditions/${alias}`),
            ).then((v) => {
              if (v.data()) {
                return true;
              }
              return false;
            });
          }}
          onSelectAlias={(alias) => {
            getDoc(
              doc(getFirestore(), `storage/${user.uid}/conditions/${alias}`),
            ).then((v) => {
              const data = v.data();

              if (!data || !factorDispatch) return;

              setToLS('BACKTEST_START_DATETIME', data.startDate);
              setToLS('BACKTEST_END_DATETIME', data.endDate);
              setToLS('BACKTEST_TRADE_COST', data.tradeCost);
              setToLS('BACKTEST_TOP', data.top);
              setToLS('BACKTEST_REBALANCINGPERIOD', data.rebalancingPeriod);
              setToLS('UNSELECTED_GROUPS', data.unselectedGroups ?? []);

              factorDispatch({ type: 'CLEAR_FACTORS' });
              factorDispatch({
                type: 'UPDATE_ALL_SELECTED_DATA',
                selectedFactors: new Map<
                  number,
                  [FactorControlValue, FactorControlValue]
                >(JSON.parse(data.selectedFactors)),
                selectedNation: data.selectedNation,
                sortData: data.sortData,
                selectedConditions: alias,
                unselectedGroups: new Set<number>(
                  JSON.parse(data.unselectedGroups ?? '[]'),
                ),
              });

              onUpdate();
              if (isMobile) {
                setIsActive(false);
              }
            });
          }}
          onRemoveAlias={(alias) => {
            if (factorState.selectedConditions === alias && factorDispatch) {
              factorDispatch({
                type: 'UPDATE_SELECTED_CONDITIONS',
                selectedConditions: '',
              });
            }
            deleteDoc(
              doc(getFirestore(), `storage/${user.uid}/conditions/${alias}`),
            );
          }}
          onEditAlias={(prev, next) => {
            if (factorState.selectedConditions === prev && factorDispatch) {
              factorDispatch({
                type: 'UPDATE_SELECTED_CONDITIONS',
                selectedConditions: next,
              });
            }
            getDoc(
              doc(getFirestore(), `storage/${user.uid}/conditions/${prev}`),
            ).then((v) => {
              const data = v.data();
              deleteDoc(
                doc(getFirestore(), `storage/${user.uid}/conditions/${prev}`),
              );
              setDoc(
                doc(getFirestore(), `storage/${user.uid}/conditions/${next}`),
                data,
              );
            });
          }}
        />
      ) : (
        <Center h="100%" flexDirection="column">
          <Body2 align="center" color={themeData.colors.text2}>
            {t('strategy.requiredLogin')}
          </Body2>
          <Button
            marginTop="24px"
            background={themeData.colors.primary[800]}
            color="white"
            onClick={() => {
              if (!authDispatch) return;
              signInWithPopup(getAuth(), provider).then((credential) => {
                authDispatch({ type: 'LOGIN', user: credential.user });
              });
            }}
          >
            {t('text.startWithGoogle')}
          </Button>
        </Center>
      )}
    </Box>
  );
};

export default ConditionsStorageContainer;
