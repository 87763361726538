import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Popover,
  PopoverTrigger,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Box,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useAmplitude } from 'react-amplitude-hooks';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

import { getThemeData } from '~/utils/theme';
import { FactorStateContext } from '../App/context/factor_context';
import useFetch from '~/hooks/useFetch';
import { ResponseBody } from '~/models/responseBody';
import {
  ScreenerCountResult,
  ScreenerExecResult,
} from '~/models/screenerResult';
import { Repository } from '~/repository';
import { H5 } from '~/components/Typography';
import { addCriticalActionsCountAmplitude } from '~/utils/logger';
import { ExecButton } from '~/components/CustomButton';
import { getExecConditions } from '~/utils/factor';
import { AppStateContext } from '../App/context';
import ToastLimitComponent from '~/container/shared/ToastLimitComponent';

type WithRepository = {
  repository: Repository;
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */

const ScreenerExecButton = ({ repository }: WithRepository) => {
  const appState = useContext(AppStateContext);
  const history = useHistory();
  const themeData = getThemeData();
  const factorState = useContext(FactorStateContext);
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { logEvent } = useAmplitude();

  const selectGroups = Array.from(factorState?.companyGroupList.entries() ?? [])
    .map(([key, value]) => {
      if (value.length === 0) {
        return [];
      }
      if (value.length === 1) {
        return [key];
      }
      return value;
    })
    .flatMap((v) => v)
    .filter((v) => !factorState!.unselectedGroups.has(v.cosmosGroupId));
  const [screenerCountState] = useFetch<
    ResponseBody<ScreenerCountResult>
  >(() => {
    const conditions = factorState?.selectedFactors
      ? getExecConditions(factorState!.selectedFactors)
      : [];

    return repository.postScreenerCountResultWithoutKey({
      filterFactors: conditions,
      nationCode: factorState?.selectedNation.code ?? 840,
      cosmosGroupIds: selectGroups.map((v) => v.cosmosGroupId),
    });
  }, [
    factorState?.selectedFactors,
    factorState?.selectedNation,
    factorState?.sortData,
    factorState?.unselectedGroups,
  ]);
  const disabled =
    (factorState?.selectedFactors.size ?? 0) > 30 ||
    factorState?.selectedFactors.size === 0 ||
    screenerCountState.error ||
    screenerCountState.loading ||
    screenerCountState.data?.result?.totalCount === 0 ||
    selectGroups.length === 0;

  const { amplitudeInstance } = useAmplitude();
  const placement = useBreakpointValue(['top', 'bottom']);
  return (
    <Popover
      isOpen={factorState?.selectedFactors.size !== 0}
      // @ts-ignore
      placement={placement}
    >
      <PopoverTrigger>
        <Box w="100%">
          <ExecButton
            isLoading={isLoading}
            text={t('text.screenerButton')}
            disabled={disabled}
            onClick={() => {
              setIsLoading(true);

              addCriticalActionsCountAmplitude({
                amplitudeInstance,
              });

              const conditions = factorState?.selectedFactors
                ? getExecConditions(factorState!.selectedFactors)
                : [];

              repository
                .postExecScreener({
                  filterFactors: conditions,
                  nationCode: factorState!.selectedNation.code,
                  cosmosGroupIds: selectGroups.map((v) => v.cosmosGroupId),
                })
                .then((v) => {
                  logEvent('screening execute', {
                    'num total screening result':
                      screenerCountState?.data?.result?.totalCount,
                    'selected country to invest':
                      factorState!.selectedNation.name,
                  });

                  history.push(
                    `screener-result/${
                      (v as ResponseBody<ScreenerExecResult>).result.key
                    }?orderByFactors=${factorState!.sortData.data.factorId}:${
                      factorState!.sortData.data.order
                    }`,
                  );
                })
                .catch((e: AxiosError) => {
                  console.log(e);
                  setIsLoading(false);
                  appState?.showToast(
                    'error',
                    e.response?.status === 429 ? (
                      <ToastLimitComponent />
                    ) : (
                      <H5 noOfLines={10} whiteSpace="pre-wrap">
                        {`예상하지 못한 오류가 발생했습니다(${e.response?.status})\n해당 현상이 반복된다면 문의 바랍니다`}
                      </H5>
                    ),

                    {
                      position: 'bottom-left',
                      toastId: 'screener-exec-error-toast',
                    },
                  );
                });
            }}
          />
        </Box>
      </PopoverTrigger>
      {screenerCountState.error || screenerCountState.loading ? (
        <div />
      ) : (factorState?.selectedFactors.size ?? 0) > 30 ||
        selectGroups.length === 0 ? (
        <PopoverContent
          style={{ animation: 'smoothAppear .5s', boxShadow: 'none' }}
          bg={themeData.colors.error}
          w="fit-content"
        >
          <PopoverArrow bg={themeData.colors.error} />
          <PopoverBody w="fit-content" display="flex">
            <H5 whiteSpace="break-spaces" bold color="white">
              {(factorState?.selectedFactors.size ?? 0) > 30
                ? '팩터 조건이 30개를 넘을 수 없습니다'
                : t('text.sectorErrorPopover')}
            </H5>
          </PopoverBody>
        </PopoverContent>
      ) : (
        <PopoverContent
          style={{ animation: 'smoothAppear .5s', boxShadow: 'none' }}
          bg={themeData.colors.navy}
          w="fit-content"
        >
          <PopoverArrow bg={themeData.colors.navy} />
          <PopoverBody w="fit-content" display="flex">
            <H5
              whiteSpace="break-spaces"
              bold
              color={themeData.colors.primary[500]}
            >
              {screenerCountState?.data?.result?.totalCount}
            </H5>
            <H5 whiteSpace="break-spaces" bold color="white">
              {`${t('text.screenerCountTooltip')}`}
            </H5>
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  );
};

export default ScreenerExecButton;
