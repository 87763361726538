import { Center, Box, HStack, Flex } from '@chakra-ui/layout';
import { Image, Tooltip } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import LoadingComponent from '~/components/LoadingComponent';
import { Body1, Body2, H1, H2 } from '~/components/Typography';
import { iconInfo } from '~/constants/assets';
import useFetch from '~/hooks/useFetch';
import {
  BacktestingEsResult,
  BacktestingYieldIndexResult,
  ExecBackTestingBody,
} from '~/models/execBacktesting';
import { ResponseBody } from '~/models/responseBody';
import { Repository } from '~/repository';
import { getThemeData } from '~/utils/theme';
import BackTestingResultGrid from './BackTestingResultGrid';

export type ChartProps = {
  value: BacktestingYieldIndexResult;
  nationCode: number;
};

type Props = {
  repository: Repository;
  requestKey: string;
};
/* eslint-disable no-nested-ternary */
const BackTestResultContainer = ({ repository, requestKey }: Props) => {
  const themeData = getThemeData();
  const [t] = useTranslation();
  const [requestBody, setRequestBody] = useState<ExecBackTestingBody>();

  const [backtestResultState] = useFetch<
    ResponseBody<BacktestingEsResult>
  >(async () => {
    const requestBodyResponse = await repository.getBacktestingRequestByKey(
      requestKey,
    );
    if (
      requestBodyResponse instanceof Error ||
      requestBodyResponse.errorMessage
    ) {
      throw requestBodyResponse;
    }

    setRequestBody(requestBodyResponse.result);

    return repository.getBacktestingYieldIndexResult(requestKey);
  }, []);

  if (backtestResultState.loading) {
    return (
      <Center bg="white" h="100%">
        <LoadingComponent height="400px" />
      </Center>
    );
  }
  if (backtestResultState.error) {
    useHistory().push('/backtest', {
      error: backtestResultState.error,
    });
    return <H1>ERROR</H1>;
  }

  if (
    !backtestResultState.data.result.yieldResult.bmStats ||
    !backtestResultState.data.result.yieldResult.portBenchResultList ||
    backtestResultState.data.result.yieldResult.portBenchResultList.length ===
      0 ||
    !backtestResultState.data.result.yieldResult.portStats
  ) {
    useHistory().push('/backtest', {
      error: t('text.emptyBacktesting'),
    });
    return <H1>ERROR</H1>;
  }

  return (
    <Box
      h="100%"
      overflow="scroll"
      m={['0px', '24px']}
      bg="white"
      p={['12px 16px', '24px 32px']}
      border={`solid 1px ${themeData.colors.gray[200]}`}
    >
      <Box width="100%" h="100%">
        <HStack>
          <H2 bold color={themeData.colors.text1}>
            {t('text.performanceAnalysis')}
          </H2>
          <Body1 color={themeData.colors.text2}>
            {
              backtestResultState.data.result.yieldResult.portBenchResultList[0]
                .startDate
            }{' '}
            ~{' '}
            {
              backtestResultState.data.result.yieldResult.portBenchResultList[
                backtestResultState.data.result.yieldResult.portBenchResultList
                  .length - 1
              ].endDate
            }
          </Body1>
        </HStack>

        <HStack
          paddingTop="12px"
          paddingBottom="24px"
          borderBottom={`solid 1px ${themeData.colors.gray[200]}`}
        >
          <Flex align="center" flexWrap="nowrap">
            <Body2>
              {t('text.backtestResultDescriptionPrefix')}
              {t('text.survivorshipBias')}
            </Body2>

            <Tooltip
              color={themeData.colors.text2}
              bg="white"
              box-shadow="0px 3px 3px #ccc"
              whiteSpace="pre-line"
              label={t('text.survivorshipBiasInfo')}
              shouldWrapChildren
            >
              <Image marginLeft="2px" marginRight="5px" src={iconInfo} />
            </Tooltip>
            <Body2>
              {t('text.backtestResultDescriptionBetween')}
              {t('text.lookaheadBias')}
            </Body2>

            <Tooltip
              color={themeData.colors.text2}
              bg="white"
              box-shadow="0px 3px 3px #ccc"
              whiteSpace="pre-line"
              label={t('text.lookaheadBiasInfo')}
              shouldWrapChildren
            >
              <Image marginLeft="2px" marginRight="5px" src={iconInfo} />
            </Tooltip>
            <Body2>{t('text.backtestResultDescriptionPostfix')}</Body2>
          </Flex>
        </HStack>

        <BackTestingResultGrid
          nationCode={requestBody?.nationCode ?? 0}
          result={backtestResultState.data.result.yieldResult}
        />
      </Box>
    </Box>
  );
};

export default BackTestResultContainer;
