import {
  Box,
  Center,
  HStack,
  Link,
  Table,
  Tbody,
  Td,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PriceRangeComponent from '~/components/PriceRangeComponent';
import SpiderChart from '~/components/SpiderChart';
import { Body2, H2, H3, H5 } from '~/components/Typography';
import NegativeBarChart from '~/components/NegativeBarChart';
import { Repository } from '~/repository';
import useFetch from '~/hooks/useFetch';
import {
  CompanyDetailInfo,
  CompanyMarketInfo,
  CompanySummaryInfoResponse,
  FinancialStatementValuesResponse,
} from '~/models/companyDetail';
import { ResponseBody } from '~/models/responseBody';
import LoadingComponent from '~/components/LoadingComponent';
import { intToLocaledString } from '~/utils/number';
import { getThemeData } from '~/utils/theme';

type Props = {
  repository: Repository;
  cosmosCode: number;
};

const Summary = ({ repository, cosmosCode }: Props) => {
  const [companySummaryInfoResponse] = useFetch<
    ResponseBody<CompanySummaryInfoResponse>
  >(() => repository.getCompanySummaryInfo(cosmosCode), []);

  if (companySummaryInfoResponse.loading) return <LoadingComponent />;

  if (
    companySummaryInfoResponse.error ||
    !companySummaryInfoResponse?.data?.result
  )
    return <div />;

  const {
    companyDetailInfo,
    companyMarketInfo,
    financialValueTimeSeries,
    scoreFactorMap,
    statisticFactorMap,
    maxScoreValueMap,
  } = companySummaryInfoResponse.data.result;
  return (
    <VStack
      overflowX="hidden"
      w={['100%', 'initial']}
      m={['4px', '36px 4px']}
      marginLeft={['0px', '24px']}
      p={['0px 24px', '24px']}
      bg="white"
      borderRadius="8px"
      shadow={['0px', '0px 0px 8px #e0e0e0']}
    >
      <CompanySummary
        companyName={companyDetailInfo.companyName}
        companyLocalName={companyDetailInfo.companyLocalName}
        scoreFactorMap={scoreFactorMap}
        maxScoreValueMap={maxScoreValueMap}
      />
      <Price companyMarketInfo={companyMarketInfo} />
      {/* <Valuation /> */}
      <Financials financialValueTimeSeries={financialValueTimeSeries} />
      <CompanyInformation
        companyDetailInfo={companyDetailInfo}
        statisticFactorMap={statisticFactorMap}
      />
    </VStack>
  );
};

const CompanySummary = ({
  companyName,
  companyLocalName,
  scoreFactorMap,
  maxScoreValueMap,
}: {
  companyName: string;
  companyLocalName: string;
  scoreFactorMap: Map<string, number>;
  maxScoreValueMap: Map<string, number>;
}) => {
  const getSpiderChartData = (): [string, number][] => {
    const order = [10, 7, 4, 2, 5];
    const result = Array<[string, number]>();
    const scores = new Map(Object.entries(scoreFactorMap));

    order.forEach((v) => {
      const score = scores.get(v.toString()) ?? 0;
      result.push([
        // @ts-ignore
        t(`companyDetail.${v}`),
        score / (maxScoreValueMapObj[v] ?? 10),
      ]);
    });

    return result;
  };
  const [t] = useTranslation();
  const maxScoreValueMapObj = Object(maxScoreValueMap);
  return (
    <Box w="100%" marginBottom="32px">
      <Box display={['none', 'inherit']} marginBottom="32px">
        <H3 bold noOfLines={1}>
          {t('companyDetail.summary')}
        </H3>
      </Box>

      <Box marginBottom="8px">
        <H2 bold noOfLines={2}>
          {companyLocalName && companyLocalName !== ''
            ? companyLocalName
            : companyName}
        </H2>
      </Box>
      {/* <Body2 noOfLines={10} color={themeData.colors.gray[600]}>
        Apple Inc. designs, manufactures, and markets smartphones, personal
        computers, tablets, wearables, and accessories worldwide.
      </Body2> */}
      <Center>
        <SpiderChart data={getSpiderChartData()} />
      </Center>
    </Box>
  );
};

const getMarketCapPositionRatio = (
  rank: number,
  totalCount: number,
): number => {
  const ratio = rank / totalCount;

  const top = 0.05;
  const middle = 0.3;
  const topCount = totalCount * top;
  const middleCount = totalCount * (middle - top);

  if (ratio <= top) {
    const topRange = 1 / 3 / (topCount - 1);

    return 1 - topRange * (rank - 1);
  }
  if (ratio <= middle) {
    const rankOfPart = rank - topCount;
    const middleRange = 1 / 3 / (middleCount - 1);
    return 2 / 3 - middleRange * (rankOfPart - 1);
  }
  const smallCount = totalCount * (1 - middle);
  const rankOfPart = rank - topCount - middleCount;
  const smallRange = 1 / 3 / (smallCount - 1);

  return 1 / 3 - smallRange * (rankOfPart - 1);
};

const Price = ({
  companyMarketInfo,
}: {
  companyMarketInfo: CompanyMarketInfo;
}) => {
  const themeData = getThemeData();
  const [t] = useTranslation();
  return (
    <Box w="100%">
      <HStack>
        <H5 bold>{t('companyDetail.52WeekPriceRange.title')}</H5>
        <Body2 color={themeData.colors.gray[300]}>
          {companyMarketInfo.currency}
        </Body2>
      </HStack>
      <Box marginTop="12px">
        <PriceRangeComponent
          lowUnit={t('companyDetail.52WeekPriceRange.low')}
          highUnit={t('companyDetail.52WeekPriceRange.high')}
          currentUnit={t('companyDetail.52WeekPriceRange.current')}
          positionRatio={
            (companyMarketInfo.currentMarketPrice -
              companyMarketInfo.yearLowMarketPrice) /
            (companyMarketInfo.yearHighMarketPrice -
              companyMarketInfo.yearLowMarketPrice)
          }
          low={companyMarketInfo.yearLowMarketPrice}
          high={companyMarketInfo.yearHighMarketPrice}
          current={companyMarketInfo.currentMarketPrice}
        />
      </Box>

      <HStack>
        <H5 bold>{t('companyDetail.marketCapRank.title')}</H5>
        <Body2 color={themeData.colors.gray[300]}>
          {companyMarketInfo.currency}
        </Body2>
      </HStack>

      <Box marginTop="12px">
        <PriceRangeComponent
          lowUnit={t('companyDetail.marketCapRank.low')}
          highUnit={t('companyDetail.marketCapRank.high')}
          currentUnit={t('companyDetail.marketCapRank.current')}
          positionRatio={getMarketCapPositionRatio(
            companyMarketInfo.nationMarketCapRank,
            companyMarketInfo.maxNationMarketCapRank,
          )}
          low={companyMarketInfo.minNationLocalMarketCap}
          high={companyMarketInfo.maxNationLocalMarketCap}
          current={companyMarketInfo.localMarketCap}
        />
      </Box>
    </Box>
  );
};
// const Valuation = () => {
//   const [t] = useTranslation();
//   return (
//     <Box w="100%">
//       <SubtitleWithTooltip label="Valuation" description="Valuation" />
//       <DotPlot />
//     </Box>
//   );
// };

const Financials = ({
  financialValueTimeSeries,
}: {
  financialValueTimeSeries: FinancialStatementValuesResponse;
}) => {
  const themeData = getThemeData();
  const [t] = useTranslation();

  return (
    <Box w="100%" paddingBottom="32px">
      <H3 bold noOfLines={1}>
        {t('companyDetail.summaryFinancials')}
      </H3>
      {(financialValueTimeSeries.financialItemList ?? []).map((v, i) => {
        const data: [string, number][] =
          financialValueTimeSeries.periodInfoList.map((period) => {
            const { year, calcEndDate } = period;
            const value: number =
              (new Map(
                Object.entries(
                  new Map(
                    Object.entries(financialValueTimeSeries.financialValueMap),
                  ).get(calcEndDate),
                ),
              ).get(i.toString()) as number) ?? 0;

            return [year.toString(), value];
          });

        return (
          <VStack align="start" marginTop="36px">
            <HStack>
              {/* @ts-ignore */}
              <H5 bold>{t(`companyDetailFactor.${v}`)}</H5>
              <Body2 color={themeData.colors.gray[300]}>
                {financialValueTimeSeries.currency}
              </Body2>
            </HStack>

            <NegativeBarChart data={data} />
          </VStack>
        );
      })}
    </Box>
  );
};

const CompanyInformation = ({
  companyDetailInfo,
  statisticFactorMap,
}: {
  companyDetailInfo: CompanyDetailInfo;
  statisticFactorMap: Map<string, number>;
}) => {
  const [t] = useTranslation();
  return (
    <Box w="100%">
      <H3 bold noOfLines={1}>
        {t('companyDetail.companyInfo')}
      </H3>
      <Table
        display="inherit"
        variant="simple"
        w="100%"
        overflow="scroll"
        mt="18px"
      >
        <Tbody w="100%" display="inline-table">
          {companyDetailInfo.incorporationDate ? (
            <Tr>
              <Td p="12px 0px" minW="100px">
                <Body2> {t('companyDetail.foundedDate')}</Body2>
              </Td>
              <Td p="12px 0px" isNumeric>
                <Body2 align="inherit">
                  {companyDetailInfo.incorporationDate}
                </Body2>
              </Td>
            </Tr>
          ) : (
            <div />
          )}
          <Tr>
            <Td p="12px 0px">
              <Body2>{t('companyDetail.website')}</Body2>
            </Td>

            <Td p="12px 0px" isNumeric>
              <Link isExternal href={companyDetailInfo.url}>
                <Body2 align="inherit" decoration="underline" noOfLines={3}>
                  {companyDetailInfo.url}
                </Body2>
              </Link>
            </Td>
          </Tr>
          <Tr>
            <Td p="12px 0px">
              <Body2>{t('companyDetail.exchange')}</Body2>
            </Td>
            <Td p="12px 0px" isNumeric>
              <Body2 align="inherit"> {companyDetailInfo.exchange}</Body2>
            </Td>
          </Tr>
          {Object.entries(statisticFactorMap).map((v) => {
            return (
              <Tr>
                <Td p="12px 0px">
                  {/* @ts-ignore */}
                  <Body2 noOfLines={2}>{t(`factor.${v[0]}.name`)}</Body2>
                </Td>
                <Td p="12px 0px" isNumeric>
                  <Body2 noOfLines={2} align="inherit">
                    {intToLocaledString(v[1])}
                  </Body2>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default Summary;
