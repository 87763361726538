import { ReactElement, useContext, useReducer } from 'react';
import { Grid, GridItem, Flex, Box } from '@chakra-ui/react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { getThemeData } from '~/utils/theme';
import { AppStateContext } from '../App/context';
import {
  CompanyDetailStateContext,
  CompanyDetailDispatchContext,
  CompanyDetailReducer,
} from './context';
import { Repository } from '~/repository';
import CompanyDetailHeader from './CompanyDetailHeader';
import CompanyDetailSummaryContainer from '~/container/CompanyDetail/Summary';
import CompanyDetailSummaryTabBody from '~/container/CompanyDetail/TabBody';
import IndexedTab from '~/components/IndexedTab';

type WithRepository = {
  repository: Repository;
  cosmosCode: number;
};

type Props = {
  children: ReactElement;
};
interface ParamTypes {
  cosmosCode: string;
}

const CompanyDetailProvider = ({ children }: Props) => {
  const [CompanyDetailState, CompanyDetailDispatch] = useReducer(
    CompanyDetailReducer,
    {},
  );
  return (
    <CompanyDetailStateContext.Provider value={CompanyDetailState}>
      <CompanyDetailDispatchContext.Provider value={CompanyDetailDispatch}>
        {children}
      </CompanyDetailDispatchContext.Provider>
    </CompanyDetailStateContext.Provider>
  );
};

const CompanyDetailRouter = () => {
  const CompanyDetailBody = ({ repository, cosmosCode }: WithRepository) => {
    const [t] = useTranslation();
    return (
      <>
        <Grid
          display={['none', 'grid']}
          width="100%"
          gap={4}
          templateColumns="repeat(3, 1fr)"
        >
          <GridItem colSpan={1} overflowY="auto">
            <CompanyDetailSummaryContainer
              repository={repository}
              cosmosCode={cosmosCode}
            />
          </GridItem>
          <GridItem colSpan={2} overflowY="scroll">
            <CompanyDetailSummaryTabBody
              repository={repository}
              cosmosCode={cosmosCode}
            />
          </GridItem>
        </Grid>
        <Box display={['inherit', 'none']} w="100%">
          <IndexedTab
            hasBottomButtons={false}
            lastActionButton={<div />}
            items={[
              {
                title: `${t('companyDetail.summary')}`,
                child: (
                  <CompanyDetailSummaryContainer
                    repository={repository}
                    cosmosCode={cosmosCode}
                  />
                ),
              },
              {
                title: `${t('companyDetail.summaryFinancials')}`,
                child: (
                  <CompanyDetailSummaryTabBody
                    repository={repository}
                    cosmosCode={cosmosCode}
                  />
                ),
              },
            ]}
          />
        </Box>
      </>
    );
  };
  const themeData = getThemeData();
  const appState = useContext(AppStateContext);
  const { cosmosCode } = useParams<ParamTypes>();
  return (
    <CompanyDetailProvider>
      <Flex
        h={['-webkit-fill-available', '100%']}
        w="100%"
        flexDirection="column"
      >
        {appState != null ? (
          <CompanyDetailHeader repository={appState?.repository} />
        ) : (
          <div />
        )}

        <Flex
          h={['calc(100vh - 80px)', 'calc(100vh - 122px)']}
          bg={themeData.colors.background}
        >
          {appState != null ? (
            <CompanyDetailBody
              cosmosCode={parseInt(cosmosCode, 10)}
              repository={appState?.repository}
            />
          ) : (
            <div />
          )}
        </Flex>
      </Flex>
    </CompanyDetailProvider>
  );
};

export default CompanyDetailRouter;
