import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Body2, H3 } from '~/components/Typography';
import { getThemeData } from '~/utils/theme';
import { ChartProps } from '.';

const BackTestingHistoryTable = ({ value, nationCode }: ChartProps) => {
  const [t] = useTranslation();
  const themeData = getThemeData();

  const headerData = value.portBenchResultList.map((v) => {
    return `${v.endDate.split('-')[0]}-${v.endDate.split('-')[1]}`;
  });
  return (
    <Box
      w="100%"
      h="100%"
      p={['0px', '24px']}
      marginTop={['12px', '0px']}
      overflowX="scroll"
    >
      <H3 bold>{t('text.performanceByPeriod')}</H3>

      <Table size="md" marginTop="24px" w="100%">
        <Thead bg={themeData.colors.gray[100]}>
          <Tr>
            <Th />
            {headerData.map((data) => (
              <Th whiteSpace="nowrap" key={`header-data-${data}`}>
                <Body2 whiteSpace="nowrap" noOfLines={1}>
                  {data}
                </Body2>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          <Tr whiteSpace="nowrap">
            <Td>
              <Body2 whiteSpace="nowrap">{t('text.portfolio')}</Body2>
            </Td>
            {value.portBenchResultList.map((v) => {
              return (
                <Td key={`table-data-port${v.startDate}`}>
                  <Body2 whiteSpace="nowrap" noOfLines={1}>
                    {(v.portYield * 100).toFixed(2)}%
                  </Body2>
                </Td>
              );
            })}
          </Tr>
          <Tr>
            <Td>
              <Body2 whiteSpace="nowrap" noOfLines={1}>
                {/* @ts-ignore */}
                {t(`nation.benchmark.${nationCode}`)}
              </Body2>
            </Td>

            {value.portBenchResultList.map((v) => {
              return (
                <Td key={`table-data-bench${v.startDate}`}>
                  <Body2 whiteSpace="nowrap" noOfLines={1}>
                    {' '}
                    {(v.bmYield * 100).toFixed(2)}%
                  </Body2>
                </Td>
              );
            })}
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default BackTestingHistoryTable;
